import React from "react";
import { OutlinedTextFieldProps, TextField } from "@material-ui/core";
import classnames from "classnames";
import styles from "./styles";

export interface ITextInput extends OutlinedTextFieldProps {
  label?: string;
  name: string;
  /**
   * react hook form methods returned from useForm hook
   */
  formMethods: any;
  readOnly?: boolean;
  className?: string;
}
const TextInput: React.FC<Omit<ITextInput, "variant">> = ({
  name,
  readOnly,
  className,
  formMethods,
  ...props
}) => {
  const {
    register,
    formState: { errors },
  } = formMethods;
  const classes = styles();
  return (
    <TextField
      error={!!errors[name]}
      helperText={!!errors[name] && errors[name].message}
      id={name}
      fullWidth
      inputProps={{ ...register(name), ...props.inputProps }}
      InputProps={{ readOnly, ...props.InputProps }}
      className={classnames(classes.root, className)}
      variant="outlined"
      margin="normal"
      {...props}
    />
  );
};

export default TextInput;

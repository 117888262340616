import { Trans, useTranslation } from "react-i18next";
import { Box, Button, Typography } from "@material-ui/core";
import { useStyles } from "./styles";
import LaunchIcon from "@material-ui/icons/Launch";
import Logo from "assets/storydots-logo.svg";
import nudgePromptToDesktop from "assets/nudge-prompt-to-desktop.png";

export default function MobileInfo() {
  const classes = useStyles();
  const { t } = useTranslation("MobileInfoPage");
  return (
    <div className={classes.root}>
      <a href="https://storydots.app" className={classes.headerLogo}>
        <img src={Logo} className={classes.sdLogo} alt="storydots-logo" />
      </a>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="h3"
          color="primary"
          paragraph
          align="center"
          className={classes.text}
        >
          {t("title")}
        </Typography>
        <Typography variant="body1" paragraph align="center">
          <Trans
            t={t}
            i18nKey="description"
            components={{
              strong: <strong className={classes.text} />,
              p: <p className={classes.text} />,
            }}
            className={classes.text}
          />
        </Typography>
      </Box>
      <Box className={classes.buttonsContainer}>
        <a href="https://storydots.app" className={classes.anchor}>
          <Button
            endIcon={<LaunchIcon />}
            className={`${classes.button} ${classes.secondaryButton}`}
            tabIndex={-1}
          >
            {t("secondaryButton")}
          </Button>
        </a>
        <a
          href="https://storydots.app#MeetingsCalendar"
          className={classes.anchor}
        >
          <Button
            endIcon={<LaunchIcon />}
            className={`${classes.button} ${classes.primaryButton}`}
            tabIndex={-1}
          >
            {t("primaryButton")}
          </Button>
        </a>
      </Box>
      <img
        src={nudgePromptToDesktop}
        className={classes.mobileOnlyImg}
        alt="mobile-only-img"
      />
    </div>
  );
}

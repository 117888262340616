import { AxiosRequestConfig } from "axios";
import { v4 as uuidv4 } from "uuid";
interface ISenderData {
  name: string;
  email: string;
  api_key: string;
  preorder_receiver_name: string;
  preorder_text_message: string;
}

export const senderRequestOptions = ({
  name,
  email,
  api_key,
  preorder_receiver_name,
  preorder_text_message,
}: ISenderData): AxiosRequestConfig => ({
  method: "POST",
  url: `${process.env.REACT_APP_API_URL}/order`,
  headers: {
    "Content-Type": "application/json",
    "x-api-key": api_key,
  },
  data: {
    buyerEmail: email,
    buyerName: name,
    orderId: uuidv4(),
    from: name,
    to: preorder_receiver_name,
    textMessage: preorder_text_message,
  },
});

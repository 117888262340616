import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  img: {
    height: "6.25rem",
    marginBottom: "0.75rem",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0.75rem",
    textAlign: "center",
  },
}));

export { useStyles };

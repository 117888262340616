import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { Button, PageContainer } from "components";
import { useStyles } from "./styles";
import { useHistory } from "react-router-dom";
import { FULLDEMO } from "config/consts";
import { Trans, useTranslation } from "react-i18next";
import WavingWithBag from "assets/WavingWithBag.svg";
import { useQueryParams } from "utils/hooks";

const Surprise = () => {
  const { t } = useTranslation("SurpriseView");
  const classes = useStyles();
  const queryParams = useQueryParams();
  const history = useHistory();
  const handleNext = () => {
    history.push({
      pathname: FULLDEMO.RECEIVER,
      search: `?${queryParams}`,
    });
  };
  return (
    <PageContainer className={classes.root}>
      <>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center"
        >
          <Grid item xs>
            <img
              src={WavingWithBag}
              className={classes.wavingWithBagImg}
              alt="Waving with Bag"
            />
          </Grid>
          <Grid item xs>
            <Grid container direction="column" alignItems="flex-start">
              <Box className={classes.responsiveBox}>
                <Typography variant="h3" color="primary" gutterBottom>
                  {t("title")}
                </Typography>

                <Typography variant="body1" className={classes.description}>
                  <Trans
                    t={t}
                    i18nKey="description"
                    components={{ br: <br />, strong: <strong /> }}
                  />
                </Typography>

                <Button blink className={classes.button} onClick={handleNext}>
                  {t("button")}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </>
    </PageContainer>
  );
};

export default Surprise;

import { makeStyles } from "@material-ui/core";
import { constants } from "../../../config/theme";

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: constants.VHCalc(78),
  },
  description: {
    lineHeight: "200%",
    letterSpacing: "0.15px",
  },
  responsiveBox: {
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      marginBottom: theme.spacing(3),
    },
    maxWidth: "300px",
  },
  fixedBox: {
    position: "fixed",
    bottom: "25vh",
    left: "10%",
  },
}));

export { useStyles };

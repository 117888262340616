import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  image: {
    height: "100%",
    width: "auto",
    marginBottom: 50,
    borderRadius: "12px",
  },
  boxAndStepper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
    flexShrink: 5,
  },
  responsiveBox: {
    textAlign: "justify",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  },
  card: {
    display: "flex",
    justifyContent: "center",
  },
  button: {
    display: "flex",
    justifyContent: "left",
    paddingTop: 50,
    [theme.breakpoints.down("md")]: {
      paddingBottom: 50,
    },
  },
}));

export { useStyles };

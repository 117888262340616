const config = {
  particles: {
    number: {
      value: 10,
      density: {
        enable: true,
        value_area: 900,
      },
    },
    color: {
      value: "#7410E0",
    },
    shape: {
      type: "circle",
      stroke: {
        width: 0,
      },
    },
    opacity: {
      value: 0.2,
      random: true,
      anim: {
        enable: true,
        speed: 0.1,
        opacity_min: 0,
        sync: false,
      },
    },
    size: {
      value: 70,
      random: true,
      anim: {
        enable: true,
        speed: 2,
        size_min: 10,
        sync: false,
      },
    },
    line_linked: {
      enable: false,
      move: {
        enable: false,
        speed: 0.9,
        direction: "top",
        random: false,
        straight: false,
        out_mode: "shrink",
        bounce: false,
        attract: {
          enable: false,
        },
      },
    },
    move: {
      enable: true,
      speed: 0.5,
      direction: "top",
      random: false,
      straight: false,
      out_mode: "out",
      bounce: false,
      attract: {
        enable: false,
        rotateX: 600,
        rotateY: 1200,
      },
    },
  },
};

export default config;

import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { Button, PageContainer, QrCard } from "components";
import { useStyles } from "./styles";
import { useHistory } from "react-router-dom";
import { FULLDEMO } from "config/consts";
import { Trans, useTranslation } from "react-i18next";
import girl_scanning from "assets/girl_scanning.svg";
import { useQueryParams } from "utils/hooks";

const Receiver = () => {
  const { t } = useTranslation("ReceiverView");
  const classes = useStyles();
  const queryParams = useQueryParams();
  const history = useHistory();
  const handleNext = () => {
    history.push({
      pathname: FULLDEMO.DASHBOARD,
      search: `?${queryParams}`,
    });
  };
  const code = queryParams.get("code");
  return (
    <PageContainer>
      <>
        <Grid
          container
          direction="row"
          justify={"space-evenly"}
          alignContent={"center"}
          alignItems="center"
        >
          <Grid item xs>
            <img
              src={girl_scanning}
              alt="Girl scanning"
              className={classes.girl_scanning_img}
            />
          </Grid>
          <Grid item xs style={{ marginRight: "32px" }}>
            <QrCard code={code} size={500} />
          </Grid>
          <Grid item xs>
            <Box className={classes.responsiveBox}>
              <Typography
                variant="h3"
                align="left"
                color="primary"
                gutterBottom
              >
                {t("title")}
              </Typography>

              <Typography variant="body1" className={classes.description}>
                <Trans t={t} i18nKey="description" components={{ p: <p /> }} />
              </Typography>

              <Button onClick={handleNext}>{t("button")}</Button>
            </Box>
          </Grid>
        </Grid>
      </>
    </PageContainer>
  );
};

export default Receiver;

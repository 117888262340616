import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import locales from "locales";
import environment from "config/environment";

const DETECTION_OPTIONS = {
  order: ["navigator"],
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: DETECTION_OPTIONS,
    debug: environment.NODE_ENV === "dev",
    fallbackLng: "es",
    resources: locales,
  });

export default i18n;

import { makeStyles } from "@material-ui/core";
import { constants } from "config/theme";

export const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0 16px 16px",
    padding: "16px 16px",
    overflow: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  leftColumn: {
    [theme.breakpoints.down("md")]: {
      order: 2,
      marginTop: theme.spacing(6),
    },
  },
  keyImage: {
    marginTop: "0.375rem",
    height: "5vh",
  },
  passwordCard: {
    margin: theme.spacing(2),
    padding: theme.spacing(1),
    paddingLeft: 40,
    paddingRight: 40,
    borderRadius: constants.BORDER_RADIOUS_SMALL,
  },
  actionPadding: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  dashboardCard: {
    height: "auto",
    borderRadius: constants.BORDER_RADIOUS_SMALL,
  },
  goToDashboardCard: {
    "&:hover": {
      cursor: "pointer",
    },
    margin: theme.spacing(2),
    animation: `$blinkingEffect 0.8s`,
    "-webkit-animation": `$blinkingEffect 0.8s`,
    "-webkit-animation-iteration-count": "infinite",
    "-o-animation-iteration-count": "infinite",
    "animation-iteration-count": "infinite",
    padding: theme.spacing(1),
    paddingLeft: 40,
    paddingRight: 40,
    borderRadius: constants.BORDER_RADIOUS_SMALL,
  },
  "@keyframes blinkingEffect": {
    "0%": {
      "-moz-box-shadow":
        "0 0 0 0 rgba(216, 44, 32, 0.54) rgba(0, 0, 0, 0.12) 0 4px 8px",
      boxShadow: "0 0 0 0 #A6D5FA, rgba(0, 0, 0, 0.12) 0 4px 8px",
    },
    "70%": {
      "-moz-box-shadow":
        "0 0 0 32px rgba(216, 44, 32, 0) rgba(0, 0, 0, 0.12) 0 4px 8px",
      boxShadow:
        "0 0 0 32px rgba(216, 44, 32, 0), rgba(0, 0, 0, 0.12) 0 4px 8px",
    },
    "100%": {
      "-moz-box-shadow":
        "0 0 0 0 rgba(216, 44, 32, 0) rgba(0, 0, 0, 0.12) 0 4px 8px",
      boxShadow: "0 0 0 0 rgba(216, 44, 32, 0), rgba(0, 0, 0, 0.12) 0 4px 8px",
    },
  },
  explainText: {
    paddingBottom: theme.spacing(4),
  },
  media: {
    height: "auto",
  },
  finishBtn: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  actionTexts: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "0.78rem",
    lineHeight: "1.063rem",
  },
}));

import { makeStyles } from "@material-ui/core";
import { constants } from "config/theme";
import React from "react";
import classnames from "classnames";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "72%",
    minHeight: constants.VHCalc(72),
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}));

interface IProps {
  className?: string;
}

const PageContainer: React.FC<IProps> = ({ children, className }) => {
  const classes = useStyles();
  return (
    <div className={classnames(classes.container, className)}>{children}</div>
  );
};

export default PageContainer;

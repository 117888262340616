import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "64%",
  },
  button: {
    marginTop: "6vh",
    marginBottom: "6vh",
  },
  wavingWithBagImg: {
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(3),
    },
    height: "500px",
  },
  description: {
    [theme.breakpoints.down("md")]: {
      paddingBottom: "0.5rem",
    },
  },
  responsiveBox: {
    textAlign: "justify",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
}));

export { useStyles };

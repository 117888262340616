import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { responsiveTheme } from "./config/theme";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import { AppContextProvider } from "./context";
import "config/i18n";
import environment from "config/environment";
import TagManager from "react-gtm-module";
import "@fontsource/poppins";
import "@fontsource/roboto";

TagManager.initialize({
  gtmId: environment.GTM_ID,
});

ReactDOM.render(
  <React.StrictMode>
    <CssBaseline />
    <ThemeProvider theme={responsiveTheme}>
      <AppContextProvider>
        <App />
      </AppContextProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    width: "fit-content",
  },
  image: {
    width: "100%",
    height: "auto",
  },
  playImage: {
    cursor: "pointer",
    width: "20%",
    borderRadius: "50%",
    position: "absolute",
    top: "30%",
    left: "40%",
    animation: `$blinkingEffect 1.1s`,
    "-webkit-animation": `$blinkingEffect 1.1s`,
    "-webkit-animation-iteration-count": "infinite",
    "-o-animation-iteration-count": "infinite",
    "animation-iteration-count": "infinite",
  },
  "@keyframes blinkingEffect": {
    "0%": {
      "-moz-box-shadow":
        "0 0 0 0 rgba(216, 44, 32, 0.54) rgba(0, 0, 0, 0.12) 0 4px 8px",
      boxShadow:
        "0 0 0 0 " +
        theme.palette.primary.main +
        ", rgba(0, 0, 0, 0.12) 0 4px 8px",
    },
    "70%": {
      "-moz-box-shadow":
        "0 0 0 32px rgba(216, 44, 32, 0) rgba(0, 0, 0, 0.12) 0 4px 8px",
      boxShadow:
        "0 0 0 32px rgba(216, 44, 32, 0), rgba(0, 0, 0, 0.12) 0 4px 8px",
    },
    "100%": {
      "-moz-box-shadow":
        "0 0 0 0 rgba(216, 44, 32, 0) rgba(0, 0, 0, 0.12) 0 4px 8px",
      boxShadow: "0 0 0 0 rgba(216, 44, 32, 0), rgba(0, 0, 0, 0.12) 0 4px 8px",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export { useStyles };

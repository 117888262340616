import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
  },
  boy: { height: "350px" },
  title: {
    color: theme.palette.primary.main,
    fontWeight: 800,
  },
  subtitle: {
    marginTop: "1.5em",
  },
  description: {
    marginTop: "1.5em",
    fontWeight: 800,
  },
  textContainer: {
    marginBottom: "10em",
  },
}));

export { useStyles };

import { useTranslation } from "react-i18next";

function useWizardContentTranslate() {
  const { t } = useTranslation("onboarding");
  const wc = "wizardContent.";
  const normalW = 500;
  const boldW = 700;

  const translateWizardContent = () => {
    return {
      welcome: {
        title: t(wc + "welcome.title"),
        messages: [t(wc + "welcome.messages.msg1")],
      },
      hello: {
        title: t(wc + "hello.title"),
        messages: [
          [
            {
              text: t(wc + "hello.messages.msg1.0"),
              fontWeight: normalW,
            },
            {
              text: t(wc + "hello.messages.msg1.1"),
              fontWeight: boldW,
            },
            {
              text: t(wc + "hello.messages.msg1.2"),
              fontWeight: normalW,
            },
            {
              text: t(wc + "hello.messages.msg1.3"),
              fontWeight: boldW,
            },
          ],
        ],
      },
      howItWorks: {
        title: t(wc + "howItWorks.title"),
        messages: [t(wc + "howItWorks.messages.msg1")],
        time: "1’ 30’’",
      },
      storeIntegration: {
        title: t(wc + "storeIntegration.title"),
        messages: [t(wc + "storeIntegration.messages.msg1")],
        time: "1’ 30’’",
      },
      simulatePurchase: {
        title: t(wc + "simulatePurchase.title"),
        messages: [t(wc + "simulatePurchase.messages.msg1")],
      },
      greetingIsAddedToCart: {
        title: t(wc + "greetingIsAddedToCart.title"),
        messages: [
          t(wc + "greetingIsAddedToCart.messages.msg1"),
          t(wc + "greetingIsAddedToCart.messages.msg2"),
        ],
      },
      quickGreetingCreation: {
        title: t(wc + "quickGreetingCreation.title"),
        messages: [t(wc + "quickGreetingCreation.messages.msg1")],
      },
      orderEndedSuccessfully: {
        title: t(wc + "orderEndedSuccessfully.title"),
        messages: [t(wc + "orderEndedSuccessfully.messages.msg1")],
      },
      emailSent: {
        title: t(wc + "emailSent.title"),
        messages: [
          t(wc + "emailSent.messages.msg1"),
          t(wc + "emailSent.messages.msg2"),
        ],
      },
      SDArrivesAsQR: {
        title: t(wc + "SDArrivesAsQR.title"),
        messages: [t(wc + "SDArrivesAsQR.messages.msg1")],
        time: "1’ 30’’",
      },
      printing: {
        title: t(wc + "printing.title"),
        messages: [t(wc + "printing.messages.msg1.0")],
      },
      deployOrderMenu: {
        title: t(wc + "deployOrderMenu.title"),
        messages: [t(wc + "deployOrderMenu.messages.msg1")],
      },
      printingEnd: {
        title: t(wc + "printingEnd.title"),
        messages: [t(wc + "printingEnd.messages.msg1")],
      },
    };
  };
  return translateWizardContent;
}

export default useWizardContentTranslate;

import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { Button, DashboardCard, IUserData, PageContainer } from "components";
import { useStyles } from "./styles";
import { useHistory } from "react-router-dom";
import { FULLDEMO } from "config/consts";
import { Trans, useTranslation } from "react-i18next";
import { useQueryParams } from "utils/hooks";

const Dashboard = () => {
  const { t } = useTranslation("DashboardView");
  const classes = useStyles();
  const queryParams = useQueryParams();
  const credentials: IUserData = {
    username: queryParams.get("demoUser"),
    password: queryParams.get("demoPw"),
  };
  const history = useHistory();
  const handleNext = () => {
    history.push({
      pathname: FULLDEMO.NOT_BAD,
      search: `?${queryParams}`,
    });
  };
  return (
    <PageContainer>
      <>
        <Grid
          container
          justify="space-around"
          alignItems="center"
          alignContent="center"
        >
          <Grid item xs={12} sm={10} lg={5} className={classes.leftColumn}>
            <Typography variant="h3" color={"primary"} paragraph>
              {t("title")}
            </Typography>

            <Typography variant="body1">
              <Trans
                t={t}
                i18nKey="description"
                components={{ p: <p />, strong: <strong /> }}
              />
            </Typography>
            <Box mt={4}>
              <Button onClick={handleNext}>{t("button")}</Button>
            </Box>
          </Grid>
          <Grid item xs={12} sm={10} lg={6}>
            <DashboardCard credentials={credentials} />
          </Grid>
        </Grid>
      </>
    </PageContainer>
  );
};

export default Dashboard;

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  description: {
    paddingBottom: "1.5rem",
  },
  responsiveBox: {
    textAlign: "justify",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      paddingTop: "2.5rem",
    },
  },
  girl_scanning_img: {
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(3),
    },
    height: "500px",
  },
}));

export { useStyles };

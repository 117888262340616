import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  qrTag: {
    boxShadow: "0 0rem 3rem rgb(143 63 230 / 80%);",
    borderRadius: 14,
    animation: `$blinkingEffect 2s`,
    "-webkit-animation": `$blinkingEffect 2s`,
    "-webkit-animation-iteration-count": "infinite",
    "-o-animation-iteration-count": "infinite",
    "animation-iteration-count": "infinite",
  },
  "@keyframes blinkingEffect": {
    "0%": {
      "-moz-box-shadow":
        "0 0 0 0 rgba(143, 63, 230, 0) rgba(0, 0, 0, 0.12) 0 4px 8px",
      boxShadow: "0 0 0 0 #8F3FE6, rgba(0, 0, 0, 0.12) 0 4px 8px",
    },
    "70%": {
      "-moz-box-shadow":
        "0 0 0 32px rgba(143, 63, 230, 0) rgba(0, 0, 0, 0.12) 0 4px 8px",
      boxShadow:
        "0 0 0 32px rgba(143, 63, 230, 0), rgba(0, 0, 0, 0.12) 0 4px 8px",
    },
    "100%": {
      "-moz-box-shadow":
        "0 0 0 0 rgba(143, 63, 230, 0) rgba(0, 0, 0, 0.12) 0 4px 8px",
      boxShadow: "0 0 0 0 rgba(143, 63, 230, 0), rgba(0, 0, 0, 0.12) 0 4px 8px",
    },
  },
  customTagRoot: {
    width: "337px",
    height: "539px",
    backgroundSize: "cover",
    borderRadius: "26px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  customTagTitle: {
    fontSize: "1.375rem",
    marginTop: "30px",
  },
  customTagSubtitle: {
    fontSize: "2rem",
    marginBottom: "30px",
    marginTop: "0px",
    fontWeight: "bold",
  },
  customTagLogo: {
    maxWidth: "200px",
    maxHeight: "70px",
    margin: "10px",
    marginBottom: "25px",
  },
  customTagSeparator: {
    width: "100px",
    backgroundColor: "grey",
    margin: "0px",
  },
  customTagPoweredByWrapper: {
    height: "60px",
    marginBottom: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "180px",
  },
  poweredByText: {
    margin: "0px 5px 0px 0px",
    width: "80px",
  },
  poweredByImage: {
    width: "100px !important",
  },
  customTagPoweredByLogo: { height: "1.375rem", marginLeft: "5px" },
}));

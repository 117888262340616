import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { OnboardingContext } from "../../../context/OnboardingContext";
import { CongratulationsCard } from "../../Atoms";

function CongratulationsCards() {
  const { t } = useTranslation("onboarding");
  const { styles } = useContext(OnboardingContext);
  const onboardingStyles = styles;
  const { dFlex, justifyBetween, textPrimary } = onboardingStyles();

  return (
    <div
      className={[dFlex, justifyBetween, textPrimary].join(" ")}
      style={{ marginBottom: "1.979vw" }}
    >
      <CongratulationsCard
        style={{
          width: "16.7vw",
          height: "10.15625vw",
          padding: "0px 1.25vw",
          borderRadius: "1.67vw",
        }}
        number={"122"}
        text={t("congratulations.card1")}
      />
      <CongratulationsCard
        style={{
          width: "13.4375vw",
          height: "10.15625vw",
          padding: "0px 1.25vw",
          borderRadius: "1.67vw",
        }}
        number={"35%"}
        text={t("congratulations.card2")}
      />
      <CongratulationsCard
        style={{
          width: "16.7vw",
          height: "10.15625vw",
          padding: "0px 1.25vw",
          borderRadius: "1.67vw",
        }}
        number={"43"}
        text={t("congratulations.card3")}
      />
    </div>
  );
}

export default CongratulationsCards;

import { useContext } from "react";
import { OnboardingContext } from "../../../context/OnboardingContext";

interface Props {
  BGImage: string;
  content: JSX.Element;
}

function SlideBG({ BGImage, content }: Props) {
  const { styles } = useContext(OnboardingContext);
  const onboardingStyles = styles;
  const {
    primaryFont,
    noRepeatBG,
    BGw100,
    pRelative,
    w100,
    h100,
    dFlex,
    alignCenter,
    justifyCenter,
    mAuto,
  } = onboardingStyles();

  return (
    <div
      className={[
        primaryFont,
        noRepeatBG,
        BGw100,
        pRelative,
        w100,
        h100,
        mAuto,
        dFlex,
        alignCenter,
        justifyCenter,
      ].join(" ")}
      style={{ height: "49.48vw", backgroundImage: `url(${BGImage})` }}
    >
      {content}
    </div>
  );
}

export default SlideBG;

import React from "react";
import { Card } from "@material-ui/core";
import { Button } from "components";
import { useStyles } from "./styles";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { checkoutValidationSchema as schema } from "./helpers";
import { TextInput } from "components";
import { useTranslation } from "react-i18next";
import { useQueryParams } from "utils/hooks";

export interface IFormValues {
  name: string;
  email: string;
  api_key: string;
}
export interface ICheckoutFormProps {
  onSubmit(values: IFormValues): any;
}

const CheckoutForm: React.FC<ICheckoutFormProps> = ({ onSubmit }) => {
  const { t } = useTranslation("CheckoutView");
  const classes = useStyles();
  const api_key = useQueryParams().get("api_key") || "";
  const name = useQueryParams().get("name") || "";
  const methods = useForm({
    defaultValues: {
      name: name,
      email: "",
      api_key: api_key,
    },
    mode: "all",
    shouldUnregister: false,
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    formState: { isValid, errors },
  } = methods;

  return (
    <Card raised className={classes.card}>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        <TextInput
          helperText={!!errors.name ? t(`${errors.name.message}`) : ""}
          name="name"
          label={t("CheckoutForm.name")}
          formMethods={methods}
        />
        <TextInput
          helperText={!!errors.email ? t(`${errors.email.message}`) : ""}
          name="email"
          label={t("CheckoutForm.email")}
          formMethods={methods}
        />
        {!api_key && (
          <TextInput
            helperText={!!errors.api_key ? t(`${errors.api_key.message}`) : ""}
            name="api_key"
            label={t("CheckoutForm.apiKey")}
            formMethods={methods}
            readOnly={!!api_key}
          />
        )}
        <Button
          blink
          type="submit"
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={!isValid}
        >
          {t("CheckoutForm.button")}
        </Button>
      </form>
    </Card>
  );
};

export default CheckoutForm;

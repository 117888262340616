import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  description: {
    paddingBottom: "1.5rem",
  },
  responsiveBox: {
    textAlign: "justify",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      paddingTop: "2.5rem",
    },
  },
  wavingWithBagImg: {
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(3),
    },
    height: "500px",
  },
  primaryText: {
    color: theme.palette.primary.main,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  title: {
    marginBottom: "1.5em",
  },
  caption: {
    marginTop: "1.5em",
  },
}));

export { useStyles };

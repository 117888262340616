import { useContext } from "react";
import { OnboardingContext } from "../../../context/OnboardingContext";
import { WizardContentTimePill } from "../../Atoms";

interface IMessage {
  text?: string;
  fontWeight?: number;
}

interface IWizardContent {
  title: string;
  messages: string[] | IMessage[] | IMessage[][];
  time?: string;
}
interface Props {
  wizardContent: IWizardContent;
}

function WizardContent({ wizardContent }: Props) {
  const { styles } = useContext(OnboardingContext);
  const onboardingStyles = styles;
  const { mt0, textCenter, font20 } = onboardingStyles();

  return (
    <>
      <WizardContentTimePill wizardContentTime={wizardContent.time} />
      <h2
        className={[mt0, textCenter].join(" ")}
        style={{ fontSize: "1.25vw" }}
      >
        {wizardContent.title}
      </h2>
      {wizardContent.messages.map((msg: string | IMessage | IMessage[]) => {
        return (
          <p
            className={[mt0, font20].join(" ")}
            style={{ fontWeight: 500, lineHeight: "1.5625vw" }}
          >
            {Array.isArray(msg)
              ? msg.map((msgPart: IMessage) => {
                  return (
                    <strong
                      className={[mt0, font20].join(" ")}
                      style={{
                        textDecoration: "none",
                        fontWeight: msgPart.fontWeight,
                      }}
                    >
                      {msgPart.text}
                    </strong>
                  );
                })
              : msg}
          </p>
        );
      })}
    </>
  );
}

export default WizardContent;

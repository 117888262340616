const TRANSLATION_ES = {
  ActorStepper: {
    Buyer: "Comprador",
    Receiver: "Receptor",
    Store: "Tienda",
    Finished: "Demo finalizada!",
  },
  WelcomeView: {
    welcome: "¡Te damos la bienvenida!",
    mainParagraph:
      "Integrándose al carrito de tiendas online, StoryDots permite a sus clientes agregar un saludo sorpresa cuando compran para regalo. El objetivo de esta demo es mostrarte cómo funciona la herramienta.",
    gearUp: "Importante",
    secondaryParagraph:
      "<p>1) Asegurate de <strong>abrir esta demo en tu computadora.</strong></p>" +
      "<p>2) <strong>Tené a mano tu celular:</strong> lo vas a necesitar para recrear la experiencia de los usuarios.</p>",
    button: "¡Empecemos!",
    disclaimer:
      "Nota: Los datos recopilados durante esta experiencia de demo no se utilizarán.",
  },
  ActorsView: {
    title: "Introducción",
    subtitle:
      "La experiencia involucra tres partes. En esta demo vas a posicionarte en el lugar de cada una. Por eso a continuación te explicamos con detalle cada una de ellas: ",
    buyer: {
      title: "Comprador",
      description:
        "Es quien hace la compra para regalo y agrega el saludo virtual.",
    },
    receiver: {
      title: "Receptor",
      description:
        "Es quien recibe el regalo con un QR. <br/>" +
        "Al escanearlo reproducirá  el saludo virtual que podrá responder.",
    },
    store: {
      title: "Tienda",
      description:
        "Es quien ofrece StoryDots para mejorar la experiencia de sus compras para regalo, y conocer la trazabilidad de los datos.",
    },
    button: "Empezar demo",
  },
  CartView: {
    title: "Ahora sos el comprador",
    description:
      "<p>Estás buscando un regalo de cumpleaños.</p>" +
      "<p>¿Qué mejor para un fan del café que una cafetera Expresso?</p>" +
      "<p><strong>La tienda ofrece la posibilidad de incluir un saludo sorpresa gratis…</strong></p>",
    CartCard: {
      title: "Tu Carrito",
      productTitle: "Maquina de cafe Expresso",
      price: "US$ 189",
      summary: "Subtotal (sin envio): ",
    },
    CartSnippet: {
      text: "¿Estás haciendo un regalo?",
      textAffirm: "¡Estás haciendo un regalo!",
      from: "De",
      to: "Para",
      email: "Email",
      message: "Escribí aca tu mensaje",
      infoText: "Podés completar tu saludo después de la compra.",
      charsLeft: "caracteres restantes.",
      checkoutButton: "Comprar",
    },
    validations: {
      required: "Requerido",
      maxChars: "Debe ser de hasta 240 characters",
      email: "Email invalido",
    },
    short: {
      title:
        "Esta demo te permitirá conocer en detalle la funcionalidad de Storydots",
      subtitle:
        "Un cliente quiere comprar una máquina de café para regalar y está en su carrito powered by Storydots.",
      description: "Seleccioná la opción para regalo y continuá al checkout.",
    },
  },
  GreetingAddedView: {
    greetingAdded: "Saludo agregado con éxito!",
    info: "Podrás grabarlo luego de finalizar la compra.",
    checkoutButton: "Checkout",
  },
  CheckoutView: {
    title: "Checkout simplificado",
    description:
      "<p>Como en todo ecommerce, al momento de comenzar la compra, se deben completar algunos datos. Sólo te pedimos los necesarios para la experiencia, por eso te pedimos que <strong>completes con tus datos reales.</strong></p>",
    CheckoutForm: {
      name: "Nombre",
      email: "Correo electrónico",
      apiKey: "Clave API",
      button: "Comprar",
    },
    Validations: {
      name: "Nombre es un campo requerido",
      email: {
        required: "Email es un campo requerido",
        format: "Formato de email inválido",
      },
      apiKey: {
        required: "Clave API es un campo requerido",
        format: "Formato de clave API inválido",
      },
    },
  },
  ThankYouPageView: {
    title: "¡Compra realizada!",
    description:
      "Una vez que el comprador complete su pago, llegará a esta Thank You page en donde puede completar o mejorar su saludo. De no hacer nada, lo recibirá por mail.",
    Card: {
      paragraph1:
        "Es hora de crear su saludo, así que toma tu smartphone. Tenés dos opciones:",
      paragraph2:
        "Crea ahora con la cámara de tu smartphone escaneando este código QR. ¿No tienes una aplicación de escáner? Abre storydots.app/scan en su navegador.",
      paragraph3:
        "Revisa tu correo electrónico, encontrarás un enlace para crear tu saludo.",
      paragraph4:
        "Una vez que haya creado su saludo, continúe con el siguiente paso.",
      or: "o",
      button: "Continuar",
    },
  },
  SwitchToReceiverView: {
    title: "Mientras tanto...",
    description:
      "<p>La tienda recibe la orden con el saludo virtual.</p>" +
      "<p>Imprime el tag QR autogenerado (que contiene el saludo) y lo adjunta al regalo.</p>" +
      "<p>De esta forma termina tu experiencia como comprador.</p>",
    button: "Ir a la experiencia del receptor",
    Stepper: {
      step1: "Pago",
      step2: "Envio",
      step3: "Entregado",
    },
    short: {
      title: "El paquete ya fue enviado",
      description:
        "La tienda recibió tu pedido y preparó una experiencia de regalo... ¡que te va a emocionar!",
    },
  },
  SurpriseView: {
    title: "Cambio de rol: ahora sos el receptor",
    description:
      "El correo te entrega un paquete. Al revisarlo, te das cuenta de que es un regalo y que viene con una sorpresa en una etiqueta con un QR...",
    button: "Continuar",
  },
  ReceiverView: {
    title: "Cambio de rol: ahora sos el receptor",
    description:
      "<p>Como te avisamos en un principio, vas a necesitar tu celular. Para descubrir la sorpresa, <strong>escaneá el código QR con tu celular.</strong> </p>" +
      "<p>Después de enviar tu respuesta, continuá acá con el último paso.</p>",
    button: "Continuar",
    short: {
      title:
        "Recibiste tu hermoso paquete de regalo y tiene un tag QR... ¡Escanealo y sorprendete!",
      caption:
        "¡Esperamos que te haya encantado la experiencia!<br>Si tenés más preguntas,",
      link: " <strong>ponete en contacto con nosotros.</strong>",
    },
  },
  DashboardView: {
    title: "El detrás de escena: ahora sos la Tienda",
    description:
      "<p>No solo somos un servicio de saludos. <strong>También recopilamos datos inteligentes.</strong></p>" +
      "<p>Te proporcionamos las <strong>credenciales para entrar a nuestro dashboard</strong> donde se muestran los datos de tu saludo, como lo ven nuestros clientes.</p>",
    button: "Finalizar",
    goToDashboardButton: "Ir al Dashboard",
    user: "Usuario",
    password: "Contraseña",
  },
  NotBadView: {
    title: "¡Ya casi estamos!",
    description: "Esperamos que hayas disfrutado de nuestra demo.",
    button: "Último paso",
  },
  FAQView: {
    title: "Te estarás preguntando...",
    Accordion: {
      item1: {
        summary:
          "¿Cómo reciben e imprimen las tiendas las etiquetas StoryDots?",
        description:
          " Las tiendas acceden al panel de administración donde pueden comprobar si alguna compra incluye StoryDots y podrán imprimir desde allí.",
      },
      item2: {
        summary: "¿Qué pasa si un receptor escanea una etiqueta sin un saludo?",
        description:
          "En StoryDots no dejamos experiencias inconclusas. Si quien compró el regalo no graba su saludo, el receptor va a ver un video default como el siguiente.",
      },
      item3: {
        summary: "¿Cómo funciona la marca blanca?",
        description:
          " Tanto la aplicación como la etiqueta QR son 100% personalizables para las marcas.",
      },
    },
    FooterCard: {
      title: "Hablemos",
      paragraph:
        "Le gustaria saber sobre nuestro smart packaging? Necesita hablar para despejar dudas?",
      button: "Agendar llamada",
    },
  },
  MobileInfoPage: {
    title: "¡Bienvenido!",
    description:
      "<p>Nos alegra que estés aquí.</p>" +
      "<p><strong>Esta demo está optimizada para verse en computadoras de escritorio.</strong></p>" +
      "<p>No te preocupes, necesitarás tu teléfono más adelante.</p>",
    secondaryButton: "Ir al sitio",
    primaryButton: "Agendar Demo",
  },
  onboarding: {
    language: "ES",
    wizardContent: {
      nextBtn: "Siguiente",
      beginBtn: "Empezar",
      loadingBtn: "Cargando...",
      goBackBtn: "Atrás",
      welcome: {
        title: "¡Bienvenido/a!",
        messages: {
          msg1: "Queremos conocerte un poco más para poder ayudarte.",
        },
      },
      hello: {
        title: "¡Hola! Soy Vicky",
        messages: {
          msg1: {
            0: "Te voy a acompañar en este tutorial de ",
            1: "5 minutos ",
            2: "para comprender StoryDots. ",
            3: "Tené tu celular a mano.",
          },
        },
      },
      howItWorks: {
        title: "Cómo funciona",
        messages: {
          msg1: "Es muy importante conocer la experiencia que vive un cliente al utilizar nuestra aplicación.",
        },
      },
      QRScan: {
        title: "Escaneá el QR con tu celular",
        messages: {
          msg1: {
            0: "Vamos a crear tu primer saludo virtual donde vas a ponerte en el lugar del ",
            1: "regalador ",
            2: "como así también del ",
            3: "receptor",
          },
          msg2: "¡Listo! Veamos ahora cómo es la integración a una tienda.",
        },
      },
      storeIntegration: {
        title: "Integración",
        messages: {
          msg1: "Nos integramos al carrito de las tiendas, permitiendo a quien compra indicar allí cuando se trata de un regalo.",
        },
      },
      simulatePurchase: {
        title: "¡Simulemos una compra de regalo!",
        messages: {
          msg1: "Ya elegiste tu producto, veamos qué sucede cuando se indica con StoryDots que se trata de una compra para regalo.",
        },
      },
      greetingIsAddedToCart: {
        title: "El Saludo se agrega al carrito",
        messages: {
          msg1: "Podrás verlo como “Saludo Virtual”.",
          msg2: "Además, ofrecerlo gratis o asignarle un valor.",
        },
      },
      quickGreetingCreation: {
        title: "Creación rápida",
        messages: {
          msg1: "Quien regala puede completar aquí mismo un saludo de texto rápido, que luego podrá mejorar.",
        },
      },
      orderEndedSuccessfully: {
        title: "La orden es pagada con éxito",
        messages: {
          msg1: "Veamos el mail que recibe para crear el saludo virtual.",
        },
      },
      emailSent: {
        title: "Enviamos un mail al cliente",
        messages: {
          msg1: "Accediendo podrá crear un saludo virtual en video, foto, voz o texto como el que ya hiciste.",
          msg2: "¿Seguís ahí? ¡Ya solo queda un paso!",
        },
      },
      SDArrivesAsQR: {
        title: "StoryDots llega en un QR",
        messages: {
          msg1: "La tienda imprime las etiquetas QR para las órdenes que fueron identificadas como regalo.",
        },
      },
      printing: {
        title: "Impresión",
        messages: {
          msg1: {
            0: "Las órdenes para regalo son identificadas, como en este ejemplo con una nota adjunta.",
          },
        },
      },
      printingEnd: {
        title: "Esta etiqueta QR contiene el saludo",
        messages: {
          msg1: "Deberá ser adjunta en una zona visible del regalo para sorprender al receptor.",
        },
      },
    },
    congratulations: {
      title: "¡Terminamos!",
      subtitle: "Nuestras tiendas obtienen estos rendimientos ¿Te sumás?",
      cardTitle: {
        start: "Simulación de ",
        color: "rendimiento extra.",
      },
      card1: "Cantidad de ventas",
      card2: "Extra por regalos",
      card3: "Ventas extra con StoryDots",
      button: "Finalizar",
    },
    stepper: {
      0: {
        title: "Introducción",
        subtitle: "Cómo funciona",
        tooltip: "Ir a introducción",
      },
      1: {
        title: "Integración",
        subtitle: "Al carrito de compras",
        tooltip: "Ir a integración",
      },
      2: {
        title: "Impresión",
        subtitle: "De los tags QR",
        tooltip: "Ir a impresión",
      },
    },
    skip: {
      tooltip: "Salir del tutorial",
      question: "¿Querés salir del tutorial?",
      cancel: "Cancelar",
      confirm: "Confirmar",
    },
  },
};

export default TRANSLATION_ES;

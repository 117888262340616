import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  backdrop: {
    // zIndex: theme.zIndex.drawer + 1,
    zIndex: 99,
    color: "#fff",
  },
  paper: {
    padding: "16px 24px",
    borderRadius: 20,
    maxWidth: "300px",
    backgroundColor: "rgb(253, 236, 234)",
  },
  icon: {
    color: theme.palette.error.main,
    fontSize: 32,
    marginTop: 3,
    marginRight: 8,
  },
}));

import { useEffect, useState } from "react";
import environment from "config/environment";
import axios from "axios";

interface IBrandData {
  logo_url: string;
}

const useGetBrand = (apiKey: string): IBrandData | undefined => {
  const [brand, setBrand] = useState<IBrandData>();
  useEffect(() => {
    const getBrandData = async () => {
      try {
        const response = await axios.get<IBrandData>(
          `${environment.REACT_APP_API_URL}/getBrand`,
          {
            headers: { "x-api-key": apiKey },
          },
        );
        const { data } = response;
        setBrand(data);
      } catch (error) {}
    };
    apiKey && getBrandData();
  }, [apiKey]);

  return brand;
};

export default useGetBrand;

import { useAsync } from "react-use";
import importAll from "../../helpers/importAll";
import imgOnLoad from "../../helpers/imgOnLoad";
import getQrCardImage from "../../helpers/getQrCardImage";
import getBrandData from "utils/helpers/getBrandData";

const imgImports = importAll(
  require.context("../../../assets", true, /\.(png|jpe?g|svg)$/),
);

const useImagePreloader = (tag?: string | null, apiKey?: string | null) => {
  const { loading } = useAsync(async () => {
    //caching images to prevent popping
    const promises = [];

    const { logo_url } = apiKey && (await getBrandData(apiKey));
    // promises.push(imgOnLoad("imgSrc"));
    logo_url && promises.push(imgOnLoad(logo_url));
    tag && promises.push(imgOnLoad(getQrCardImage(tag)));
    promises.push(
      ...Object.values(imgImports).map((img: any) => imgOnLoad(img.default)),
    );

    promises.length > 0 && (await Promise.all(promises));

    return promises;
  }, []);
  return { loading };
};

export default useImagePreloader;

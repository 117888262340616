import { makeStyles } from "@material-ui/core";
import { constants } from "config/theme";

export const useStyles = makeStyles((theme) => ({
  dashboardCard: {
    height: "auto",
    borderRadius: constants.BORDER_RADIOUS_SMALL,
  },
  actionPadding: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

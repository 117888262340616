import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { OnboardingContext } from "../../../context/OnboardingContext";

function CongratulationsTitle() {
  const { t } = useTranslation("onboarding");
  const { styles } = useContext(OnboardingContext);
  const onboardingStyles = styles;
  const { m0, mb1 } = onboardingStyles();

  return (
    <>
      <h1
        className={[mb1].join(" ")}
        style={{
          marginTop: "4.89583vw",
          fontWeight: 600,
          fontSize: "1.67vw",
        }}
      >
        {t("congratulations.title")}
      </h1>
      <h2
        className={[m0].join(" ")}
        style={{
          marginBottom: "2.864583vw",
          fontWeight: 400,
          fontSize: "1.25vw",
        }}
      >
        {t("congratulations.subtitle")}
      </h2>
    </>
  );
}

export default CongratulationsTitle;

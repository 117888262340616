import React from "react";
import { Card, CardActions, CardMedia, Divider } from "@material-ui/core";
import { useStyles } from "./styles";
import { DashboardActions, IUserData } from "components";
// import  from "../../Molecules/DashboardActions";
// import { IUserData } from "../../Molecules/DashboardActions/DashboardActions";
import DashboardImage from "assets/dashboard.svg";

interface IProps {
  credentials: IUserData;
}

const DashboardCard: React.FC<IProps> = ({ credentials }) => {
  const classes = useStyles();

  return (
    <Card raised className={classes.dashboardCard}>
      <CardMedia>
        <img src={DashboardImage} alt="dashboard" height="auto" width="100%" />
      </CardMedia>
      <Divider />
      <CardActions className={classes.actionPadding}>
        <DashboardActions userData={credentials} />
      </CardActions>
    </Card>
  );
};

export default DashboardCard;

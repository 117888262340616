import { useTranslation } from "react-i18next";
import {
  BGsES,
  BGsPT,
  BGsEN,
} from "../../../../assets/onboardingAssets/backgrounds/index"; // BG stands for "BackGround"
import emailMobileES from "../../../../assets/onboardingAssets/mobile.png";
import emailMobilePT from "../../../../assets/onboardingAssets/mobile-PT.png";
import emailMobileEN from "../../../../assets/onboardingAssets/mobile-EN.png";
import vickyWizard from "../../../../assets/onboardingAssets/vicky-wizard.png";
import SDNewLogo from "../../../../assets/onboardingAssets/SDNewLogo.png";
import developmentQR from "../../../../assets/onboardingAssets/qr-bridge-dev.png";
import stagingQR from "../../../../assets/onboardingAssets/qr-bridge-stg.png";
import productionQR from "../../../../assets/onboardingAssets/qr-bridge-prod.png";
import him from "../../../../assets/onboardingAssets/el.png";
import her from "../../../../assets/onboardingAssets/ella.png";

function useImagesPreloading() {
  const { t } = useTranslation("onboarding");
  const lang = t("language");
  const BGs = lang === "ES" ? BGsES : lang === "EN" ? BGsEN : BGsPT;
  const emailMobile =
    lang === "ES"
      ? emailMobileES
      : lang === "EN"
      ? emailMobileEN
      : emailMobilePT;
  let imageForQR = productionQR;
  if (process.env.REACT_APP_NODE_ENV === "development")
    imageForQR = developmentQR;
  if (process.env.REACT_APP_NODE_ENV === "staging") imageForQR = stagingQR;

  return {
    vickyWizard: (new Image().src = vickyWizard),
    SDNewLogo: (new Image().src = SDNewLogo),
    cloudsAndArrowBG: (new Image().src = BGs.cloudsAndArrowBG),
    dashboardBG: (new Image().src = BGs.dashboardBG),
    cartBG: (new Image().src = BGs.cartBG),
    cartSnippetBG: (new Image().src = BGs.cartSnippetBG),
    cartProductBG: (new Image().src = BGs.cartProductBG),
    cartGreetingBG: (new Image().src = BGs.cartGreetingBG),
    cartSuccessBG: (new Image().src = BGs.cartSuccessBG),
    gmailBG: (new Image().src = BGs.gmailBG),
    tiendanubeSalesBG: (new Image().src = BGs.tiendanubeSalesBG),
    tiendanubeOrderBG: (new Image().src = BGs.tiendanubeOrderBG),
    tiendanubePrintSDBG: (new Image().src = BGs.tiendanubePrintSDBG),
    dashboardPrintQRBG: (new Image().src = BGs.dashboardPrintQRBG),
    emailMobile: (new Image().src = emailMobile),
    imageForQR: (new Image().src = imageForQR),
    him: (new Image().src = him),
    her: (new Image().src = her),
  };
}

export default useImagesPreloading;

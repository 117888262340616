import React from "react";
import { useStyles } from "./styles";
import { Card, Typography } from "@material-ui/core";
import getQrCardImage from "../../../utils/helpers/getQrCardImage";
import getQrCode from "../../../utils/helpers/getQrCode";
import tagBackgroundSrc from "../../../assets/img/tag-background.png";
import logoStorydotsBlack from "../../../assets/logo-black.svg";
import _ from "lodash";
export interface IQrCard {
  /**
   * Tag Code will be called at /qr
   */
  code: string | null;
  /**
   * Image height (in px)
   */
  size: number;
  /**
   * Configs for custom tag
   */
  language?: string;
  config?: {
    color?: string;
    customLogoSrc?: string;
    title?: string;
    subtitle?: string;
  };
}

const defaultPurple = "#8F3FE6";

const QrCard: React.FC<IQrCard> = ({ code, size, config, language = "es" }) => {
  const classes = useStyles();

  if (code) {
    if (config && !_.isEmpty(config)) {
      const { color, customLogoSrc, title, subtitle } = config;
      return (
        <Card
          className={classes.customTagRoot}
          elevation={10}
          style={{
            background: `url("${tagBackgroundSrc}")`,
            backgroundSize: "100% 100%",
          }}
        >
          <h1
            className={classes.customTagTitle}
            style={{ color: color || defaultPurple }}
          >
            {title}
          </h1>
          <h2
            className={classes.customTagSubtitle}
            style={{ color: color || defaultPurple }}
          >
            {subtitle}
          </h2>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexGrow: 1,
            }}
          >
            <Card
              elevation={3}
              style={{ borderRadius: "16px", width: "200px", height: "200px" }}
            >
              <img
                src={getQrCode(code)}
                style={{ width: "100%", height: "100%", padding: "5px" }}
                alt={"QR"}
              />
            </Card>
          </div>
          {customLogoSrc ? (
            <img
              src={customLogoSrc}
              alt="custom logo"
              style={{
                maxWidth: "200px",
                maxHeight: "70px",
                margin: "10px",
                marginBottom: "25px",
              }}
            />
          ) : (
            <h1
              style={{
                fontSize: "1.375rem",
                color: color || defaultPurple,
              }}
            >
              YOUR LOGO HERE
            </h1>
          )}
          <hr className={classes.customTagSeparator} />
          <div className={classes.customTagPoweredByWrapper}>
            <p className={classes.poweredByText}>Powered by</p>
            <img
              src={logoStorydotsBlack}
              alt="Logo Storydots black and white"
              className={classes.poweredByImage}
            />
          </div>
        </Card>
      );
    } else
      return (
        <>
          <img
            src={getQrCardImage(code, language)}
            className={classes.qrTag}
            height={size}
            alt={"QR"}
          />
        </>
      );
  } else {
    return <Typography>Cannot load QR Code</Typography>;
  }
};

export default QrCard;

import React from "react";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { useStyles } from "./styles";

export interface ILoadingOverlay {
  isLoading: boolean;
  spinnerColor?: "primary" | "secondary" | "inherit";
  spinnerSize?: number;
}

export default function LoadingOverlay({
  isLoading,
  spinnerColor,
  spinnerSize,
  ...props
}: ILoadingOverlay) {
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={isLoading}>
      <CircularProgress
        size={spinnerSize || 60}
        color={spinnerColor || "primary"}
      />
    </Backdrop>
  );
}

import React from "react";
import { Typography, Grid, Box } from "@material-ui/core";
import { CartCheckoutCard, CartProductCard, PageContainer } from "components";
import { useStyles } from "./styles";
import { FULLDEMO } from "config/consts";
import { useTranslation, Trans } from "react-i18next";
import { IFormValues } from "../../Organisms/CheckoutForm";
import { useQueryParams } from "../../../utils/hooks";
import { useHistory } from "react-router-dom";

const Cart = () => {
  const { t } = useTranslation("CartView");
  const classes = useStyles();
  const queryParams = useQueryParams();
  const history = useHistory();
  const handleNext = () => {
    history.push({
      pathname: FULLDEMO.CHECKOUT,
      search: `?${queryParams}`,
    });
  };

  const handleSubmit = (data: IFormValues) => {
    for (const [key, value] of Object.entries(data)) {
      queryParams.set(key, value);
    }
    queryParams.delete("code");
    handleNext();
  };

  return (
    <PageContainer className={classes.container}>
      <>
        <Grid item sm={12} md={6} lg={7} className={classes.fixedBox}>
          <Box px={5}>
            <Box className={classes.responsiveBox}>
              <Typography variant="h3" color="primary" paragraph>
                {t("title")}
              </Typography>
              <Typography
                variant="body1"
                className={classes.description}
                align={"center"}
              >
                <Box className={classes.responsiveBox}>
                  <Trans
                    t={t}
                    i18nKey="description"
                    components={{ p: <p />, strong: <strong /> }}
                  />
                </Box>
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid
          container
          direction="row"
          justify="flex-end"
          spacing={5}
          alignItems="center"
        >
          <Grid item sm={12} md={8}>
            <Box px={5}>
              {/* Coffee Maker Card */}
              <CartProductCard />
              {/* Greeting Card */}
              <CartCheckoutCard
                nextPath={FULLDEMO.CHECKOUT}
                onSubmit={handleSubmit}
              />
            </Box>
          </Grid>
        </Grid>
      </>
    </PageContainer>
  );
};

export default Cart;

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0 16px 16px",
    padding: "24px 24px",
    overflow: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  leftParagraph: {
    textAlign: "justify",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      marginBottom: theme.spacing(3),
    },
  },
  checkCircle: {
    paddingRight: "10px",
  },
}));

export { useStyles };

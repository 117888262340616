import React, { useEffect, useState } from "react";
import { ActorStep, Separator } from "components";
import { useLocation } from "react-router-dom";
import {
  mapPageToActorView,
  pageNumber,
  ActorView,
} from "utils/helpers/getCurrentActor";
import { useTranslation } from "react-i18next";
import useStyles from "./styles";
import { Typography } from "@material-ui/core";

export interface IActorStepper {
  /**
   * Optional actorView enum param. It will override the mapped Actor View calculated with page number.
   */
  actorView?: ActorView;
  className?: string;
  stepSpacing?: number;
}
/**
 * This component reads the app's page number from pathname (`url:port/:page_number`) and resolves
 * to the corresponding actors view step, using `mapPageToActorView` helper. Optionally, we can pass an `actorView`
 * param with the enum corresponding to the actor's view we want to show in the stepper.
 */
const ActorsStepper: React.FC<IActorStepper> = ({
  actorView,
  stepSpacing = 23,
  className,
  ...props
}) => {
  const location = useLocation();
  const classes = useStyles();
  const { t } = useTranslation("ActorStepper");

  const [step, setStep] = useState(ActorView.Default);
  useEffect(() => {
    setStep(actorView || mapPageToActorView(pageNumber(location.pathname)));
  }, [location, actorView]);

  return (
    <div>
      <div className={classes.container}>
        <ActorStep {...getStepParams(step, "buyer")} />
        <Separator minWidth={stepSpacing} />
        <ActorStep {...getStepParams(step, "receiver")} />
        <Separator minWidth={stepSpacing} />
        <ActorStep {...getStepParams(step, "store")} />
      </div>
      {step === ActorView.Finished && (
        <Typography className={classes.finishedLabel} color="secondary">
          {t("Finished")}
        </Typography>
      )}
    </div>
  );
};

export default ActorsStepper;

type stepParams = {
  actor: "buyer" | "receiver" | "store";
  state: "active" | "inactive" | "completed";
};
function getStepParams(step: ActorView, actor: string): stepParams {
  let selectedActor = 0;
  switch (actor) {
    case "buyer":
      selectedActor = 0;
      break;
    case "receiver":
      selectedActor = 1;
      break;
    case "store":
      selectedActor = 2;
      break;
    default:
      selectedActor = 0;
      break;
  }
  const stepsParams: { [key: string]: stepParams[] } = {
    [ActorView.Default]: [
      { actor: "buyer", state: "active" },
      { actor: "receiver", state: "active" },
      { actor: "store", state: "active" },
    ],
    [ActorView.Buyer]: [
      { actor: "buyer", state: "active" },
      { actor: "receiver", state: "inactive" },
      { actor: "store", state: "inactive" },
    ],
    [ActorView.Receiver]: [
      { actor: "buyer", state: "completed" },
      { actor: "receiver", state: "active" },
      { actor: "store", state: "inactive" },
    ],
    [ActorView.Store]: [
      { actor: "buyer", state: "completed" },
      { actor: "receiver", state: "completed" },
      { actor: "store", state: "active" },
    ],
    [ActorView.Finished]: [
      { actor: "buyer", state: "completed" },
      { actor: "receiver", state: "completed" },
      { actor: "store", state: "completed" },
    ],
  };
  return stepsParams[step][selectedActor];
}

import React from "react";
import { Box, Card, Divider, Grid, Typography } from "@material-ui/core";
import { useStyles } from "./styles";
import record from "assets/record.svg";
import email from "assets/email.svg";
import noQr from "assets/no_qr.png";
import { Button } from "components";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useQueryParams } from "../../../utils/hooks";

export interface IThankYouCard {
  /**
   * Base64 QR image data
   */
  qrImage: string;
  /**
   * Path to go in Continue button
   */
  nextPath: string;
}
const ThankYouCard: React.FC<IThankYouCard> = ({ qrImage, nextPath }) => {
  const classes = useStyles();
  const { t } = useTranslation("ThankYouPageView");
  const queryParams = useQueryParams();
  const history = useHistory();
  const handleNext = () => {
    history.push({
      pathname: nextPath,
      search: `?${queryParams}`,
    });
  };

  function getQrImage() {
    if (qrImage) {
      return `data:image/png;base64,${qrImage}`;
    } else {
      return noQr;
    }
  }

  return (
    <Card raised className={classes.card}>
      <Grid container justify="center">
        <Grid item xs={6} md={12}>
          <Box component="div" mb={1} mx={5}>
            <Typography variant="body1" align="center">
              <Trans
                t={t}
                i18nKey="Card.paragraph1"
                components={{ br: <br /> }}
              />
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid item xs style={{ margin: "12px 0" }}>
        <Divider />
      </Grid>

      <Grid
        container
        alignItems="center"
        justify="flex-start"
        className={classes.row}
        spacing={2}
      >
        <img
          src={record}
          alt="record"
          height={77}
          className={classes.alignedImage}
        />
        <Grid item xs={6}>
          <Typography align="justify">{t("Card.paragraph2")}</Typography>
        </Grid>
        {/* <Grid item xs={3} > */}
        <img
          src={getQrImage()}
          alt="qr-code"
          height={120}
          className={classes.qrImage}
        />
        {/* </Grid> */}
      </Grid>

      <Grid container alignItems="center" spacing={3}>
        <Grid item xs>
          <Divider />
        </Grid>
        <Grid item>
          <Typography>{t("Card.or")}</Typography>
        </Grid>
        <Grid item xs>
          <Divider />
        </Grid>
      </Grid>
      <Grid
        container
        justify="flex-start"
        alignItems="center"
        className={classes.row}
        spacing={2}
      >
        <img
          src={email}
          alt="email"
          height={55}
          className={classes.alignedImage}
        />
        <Grid item xs={7}>
          <Box component="div" my={-2}>
            <Typography align="left">{t("Card.paragraph3")}</Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid item xs style={{ margin: "16px 0" }}>
        <Divider />
      </Grid>
      <Grid item className={classes.row}>
        <Typography align={"center"}>{t("Card.paragraph4")}</Typography>
      </Grid>
      <Grid item className={classes.row}>
        <Box
          component="div"
          mb={-2}
          display="flex"
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Button onClick={handleNext}>{t("Card.button")}</Button>
        </Box>
      </Grid>
    </Card>
  );
};

export default ThankYouCard;

import { useContext, useRef } from "react";
import { OnboardingContext } from "../../../context/OnboardingContext";

function WizardAvatar() {
  const { slideN, styles, preload } = useContext(OnboardingContext);
  const [slideToShow] = slideN;
  const onboardingStyles = styles;
  const { pAbsolute } = onboardingStyles();
  const preloadedImages = preload.current;
  const wizardAvatar = useRef(
    slideToShow !== 0 ? preloadedImages.vickyWizard : preloadedImages.SDNewLogo,
  );

  return (
    <img
      src={wizardAvatar.current}
      alt="Vicky smiling"
      className={[pAbsolute].join(" ")}
      style={{
        width: "7.6vw",
        top: "-5.21vw",
        left: "calc(50% - 3.8vw)",
      }}
    />
  );
}

export default WizardAvatar;

import { FULLDEMO } from "config/consts";

export enum ActorView {
  Default = "default",
  Buyer = "buyer",
  Receiver = "receiver",
  Store = "store",
  Finished = "finished",
}
/**
 * Receives a page number and retrieves the corresponding _ActorView_ for that page.
 * Each view is mapped to a corresponding _ActorView_ inside
 * the function `map` object.
 * @param currentPage the number of the current page
 * @returns ActorView, a string corresponding to the current actor's view name.
 */
export function mapPageToActorView(currentPage: number | string): ActorView {
  try {
    const map: { [key: string]: ActorView } = {
      [pageNumber(FULLDEMO.WELCOME)]: ActorView.Default,
      [pageNumber(FULLDEMO.ACTORS)]: ActorView.Default,
      [pageNumber(FULLDEMO.CART)]: ActorView.Buyer,
      [pageNumber(FULLDEMO.CHECKOUT)]: ActorView.Buyer,
      [pageNumber(FULLDEMO.THANK_YOU_PAGE)]: ActorView.Buyer,
      [pageNumber(FULLDEMO.SWITCH_TO_RECEIVER)]: ActorView.Buyer,
      [pageNumber(FULLDEMO.SURPRISE)]: ActorView.Receiver,
      [pageNumber(FULLDEMO.RECEIVER)]: ActorView.Receiver,
      [pageNumber(FULLDEMO.DASHBOARD)]: ActorView.Store,
      [pageNumber(FULLDEMO.NOT_BAD)]: ActorView.Store,
      [pageNumber(FULLDEMO.FAQ)]: ActorView.Finished,
    };
    return map[String(currentPage)];
  } catch (error) {
    return ActorView.Default;
  }
}

export function pageNumber(path: string): string {
  return path.split("/")[1] || "1";
}

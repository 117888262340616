import { makeStyles } from "@material-ui/core";
import { constants } from "config/theme";

const useStyles = makeStyles((theme) => ({
  root: {
    [`& fieldset`]: {
      borderRadius: constants.BORDER_RADIOUS_SMALL,
    },
  },
}));

export default useStyles;

import React from "react";
import { Modal, Backdrop } from "@material-ui/core";
import play from "assets/play-icon-round.svg";
import video from "assets/video-background.png";
import { useStyles } from "./styles";

export interface IVideoPlayerProps {
  /**
   * URL of video to show on modal
   */
  videoUrl: string;
}

/**
 * Video placeholder component. When clicked on play button, opens a modal
 * to play the video given in the videoUrl param.
 */

const VideoPlayer: React.FC<IVideoPlayerProps> = ({ videoUrl, ...props }) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div className={classes.container}>
        <img src={video} alt="order" className={classes.image} />
        <img
          src={play}
          alt=""
          className={classes.playImage}
          onClick={handleOpen}
        />
      </div>
      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
        // closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <iframe
          width="560"
          height="315"
          src={videoUrl}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          style={{ borderRadius: 16 }}
        ></iframe>
      </Modal>
    </div>
  );
};

export default VideoPlayer;

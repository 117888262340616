import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    inset: "0px",
    width: "100%",
    height: "100%",
    minHeight: "100vh !important",
    fontFamily: "'Manrope', sans-serif !important",
    background: "white",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  headerLogo: {
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    padding: "30px 0px !important",
  },
  sdLogo: {
    width: "240px",
    height: "48px",
  },
  header: {
    fontFamily: "'Manrope', sans-serif !important",
  },
  text: {
    margin: "15px auto",
    fontFamily: "'Manrope', sans-serif !important",
    maxWidth: "90%",
  },
  buttonsContainer: {
    width: "100%",
    margin: "20px auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "30px",
    "@media (max-width: 500px)": {
      flexDirection: "column",
    },
  },
  anchor: {
    textDecoration: "none",
  },
  button: {
    fontFamily: "'Manrope', sans-serif !important",
    width: "200px",
    height: "56px",
    padding: "8px 0px",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "28.32px",
    letterSpacing: "0.46px",
    borderRadius: "16px",
    textTransform: "none",
    "& svg": {
      fontSize: "26px !important",
    },
  },
  primaryButton: {
    color: theme.palette.primary.contrastText,
    background: theme.palette.primary.main,
    boxShadow:
      "0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2)",
    "&:hover, &:focus, &:active ": {
      color: theme.palette.primary.dark,
      background: theme.palette.secondary.main,
    },
  },
  secondaryButton: {
    color: theme.palette.primary.main,
    background: theme.palette.primary.contrastText,
    border: `1px solid ${theme.palette.primary.main}`,
    "&:hover, &:focus, &:active ": {
      color: theme.palette.primary.contrastText,
      background: theme.palette.primary.main,
    },
  },
  mobileOnlyImg: {
    width: "750px",
    maxWidth: "100%",
    margin: "auto auto 0px auto",
    objectFit: "contain",
  },
}));

import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Collapse,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";
import { useStyles } from "./styles";
import { Button, TextInput } from "components";
import GiftIcon from "../../../assets/giftBox.svg";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { IFormValues } from "../CheckoutForm";

interface ICartCheckoutCard {
  /**
   * Path to go in Checkout button
   */
  nextPath?: string;
  onSubmit(values: IFormValues): any;
}

const CartCheckoutCard: React.FC<ICartCheckoutCard> = ({
  nextPath,
  onSubmit,
}) => {
  const classes = useStyles();
  const [addedGreeting, setAddedGreeting] = useState(false);
  const [message, setMessage] = useState(240);
  const { t } = useTranslation("CartView");

  const methods = useForm({
    mode: "all",
    shouldUnregister: false,
    resolver: yupResolver(
      Yup.object().shape({
        name: Yup.string().required("validations.required"),
        to: Yup.string().required("validations.required"),
        message: Yup.string().max(240, "validations.maxChars"),
      }),
    ),
  });

  const {
    handleSubmit,
    formState: { isValid, errors },
  } = methods;

  return (
    <Card raised className={classes.pageCard}>
      <CardHeader
        style={{ padding: 0 }}
        title={
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <Checkbox
                checked={addedGreeting}
                onChange={() => setAddedGreeting(!addedGreeting)}
                className={classes.checkbox}
              />
            </Grid>

            <Grid item>
              <Typography variant="h6" className={classes.title}>
                <strong>
                  {addedGreeting
                    ? t("CartSnippet.textAffirm")
                    : t("CartSnippet.text")}
                </strong>
              </Typography>
            </Grid>

            <Grid item>
              <img src={GiftIcon} alt="bag" className={classes.giftIcon} />
            </Grid>
          </Grid>
        }
      />
      <Divider />
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        {" "}
        {/*handleSubmit(onSubmit)*/}
        <Collapse in={addedGreeting} timeout="auto" unmountOnExit>
          <>
            <Box className={classes.inputWrapper}>
              <TextInput
                name="name"
                label={t("CartSnippet.from")}
                helperText={!!errors.name ? t(`${errors.name.message}`) : ""}
                formMethods={methods}
                className={classes.inputComponent}
              />
              <TextInput
                name="to"
                label={t("CartSnippet.to")}
                helperText={!!errors.to ? t(`${errors.to.message}`) : ""}
                formMethods={methods}
              />
            </Box>
            <Box className={classes.messageContainer}>
              <TextInput
                name="message"
                label={t("CartSnippet.message")}
                helperText={
                  !!errors.message
                    ? t(`${errors.message.message}`)
                    : `${message} ${t("CartSnippet.charsLeft")}`
                }
                onChange={(event) => {
                  const value = event.target.value;
                  setMessage(240 - value.length);
                }}
                formMethods={methods}
                multiline
                rows={4}
                rowsMax={4}
              />
              <Typography
                align={"left"}
                variant={"h6"}
                className={classes.infoText}
              >
                <strong>{t("CartSnippet.infoText")}</strong>
              </Typography>
            </Box>

            <Divider />
          </>
        </Collapse>
        <CardContent>
          <Button
            blink
            disabled={!addedGreeting || !isValid}
            type="submit"
            color="primary"
            className={classes.button}
          >
            {t("CartSnippet.checkoutButton")}
          </Button>
        </CardContent>
      </form>
    </Card>
  );
};

export default CartCheckoutCard;

import * as Yup from "yup";

const checkoutValidationSchema = Yup.object().shape({
  name: Yup.string().required("Validations.name"),
  email: Yup.string()
    .required("Validations.email.required")
    .email("Validations.email.format"),
  api_key: Yup.string()
    .required("Validations.apiKey.required")
    .min(30, "Validations.apiKey.format")
    .max(128, "Invalid format"),
});

export { checkoutValidationSchema };

import { createMuiTheme } from "@material-ui/core";
export const theme = createMuiTheme({
  palette: {
    background: {
      default: "white",
    },
    illustration: {
      light: "#8F3FE6",
      main: "#8F3FE6",
      shade: "#8F3FE6",
      shade_dark: "#8F3FE6",
      qr: "#8F3FE6",
      shine: "#ffd13e",
      clouds: "#fff",
      shadow: "#EBEEF3",
    },
    primary: {
      main: "rgba(98, 66, 232, 1)",
      dark: "rgba(64, 43, 154, 1)",
      light: "rgba(148, 122, 255, 1)",
      background: "#510B9C",
      border: "#510B9C",
      contrastText: "rgba(255, 255, 255, 1)",
    },
    secondary: {
      main: "rgba(0, 252, 195, 1)",
      light: "rgba(106, 255, 221, 1)",
      dark: "rgba(0, 192, 149, 1)",
      background: "rgba(37, 199, 150, 0.08)",
      border: "rgba(37, 199, 150, 0.5)",
      contrastText: "rgba(64, 43, 154, 1)",
    },
    info: {
      main: "#0B79D0",
      dark: "#0B79D0",
      light: "#64B6F7",
      contrastText: "#FFFFFF",
    },
    error: {
      main: "#8F3FE6",
      dark: "#510B9C",
      light: "#DF564C",
      contrastText: "#FFFFFF",
    },
    warning: {
      main: "#FF9800",
      dark: "#C77700",
      light: "#FFB547",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    success: {
      main: "#25C796",
      dark: "#198B69",
      light: "#50D2AB",
      contrastText: "#000000",
    },
    action: {
      hover: "rgba(0, 0, 0, 0.04)",
      selected: "rgba(0, 0, 0, 0.08)",
      disabledBackground: "rgba(0, 0, 0, 0.12)",
      disabled: "rgba(0, 0, 0, 0.26)",
    },
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(" "),
    h1: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontSize: "5rem",
      fontWeight: "bold",
      lineHeight: "140%",
      letterSpacing: "-0.09rem",
    },
    h2: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "3.5rem",
      lineHeight: "120%",
      letterSpacing: "-0.03rem",
    },
    h3: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "2.625rem",
      lineHeight: "116%",
    },
    h4: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "2rem",
      lineHeight: "123%",
      letterSpacing: "0.01rem",
    },
    h5: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "1.37rem",
      lineHeight: "133.4%",
    },
    h6: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "1.25rem",
      lineHeight: "160%",
      letterSpacing: "0.01rem",
    },
    body1: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "1rem",
      lineHeight: "150%",
      letterSpacing: "0.01",
    },
    body2: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "0.87rem",
      lineHeight: "143%",
      letterSpacing: "0.01",
    },
    subtitle1: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "1rem",
      lineHeight: "175 %",
      letterSpacing: "0.01rem",
    },
    subtitle2: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "0.87rem",
      lineHeight: "157%",
      letterSpacing: "0.006rem",
    },
    caption: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "0.75rem",
      lineHeight: "166%",
      letterSpacing: "0.025rem",
    },
    overline: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "0.75rem",
      lineHeight: "266%",
      letterSpacing: "0.63rem",
      textTransform: "uppercase",
    },
    button: {
      fontFamily: "Poppins, Roboto, sans-serif",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "1.625rem",
      fontSize: "1rem",
    },
  },
});

let VHUnit = "--vh";

export const constants = {
  VHCalc: (num: number) => `calc(var(${VHUnit}, 1vh) * ${num})`,
  BORDER_RADIOUS_SMALL: "12px",
  BORDER_RADIOUS_BIG: "24px",
  BUTTON_HEIGHT: 56,
  MAX_WIDTH: 320,
  FORCED_SQUARE_BLOCK_STYLES: {
    width: 320,
    height: 320,
    minHeight: 320,
    "& video": {
      outline: "none",
    },
  },
  FORCED_SQUARE_BLOCK_THREAD: {
    width: 295,
    height: 295,
    minHeight: 295,
    "& video": {
      outline: "none",
    },
  },
  TITLE_DYNAMIC_MARGIN_TOP:
    window.innerHeight >= 730
      ? 0
      : window.innerHeight > 700
      ? 80
      : window.innerHeight > 650
      ? 120
      : window.innerHeight > 500
      ? 200
      : 200,
};

export const responsiveTheme = theme;

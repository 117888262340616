import { CSSProperties, useContext } from "react";
import { OnboardingContext } from "../../../context/OnboardingContext";

interface Props {
  style?: CSSProperties;
  number: string;
  text: string;
}

function CongratulationsCard({ style, number, text }: Props) {
  const { styles } = useContext(OnboardingContext);
  const onboardingStyles = styles;
  const { whiteBG, dFlex, column, alignCenter, justifyCenter, paperShadow } =
    onboardingStyles();

  return (
    <div
      className={[
        whiteBG,
        dFlex,
        column,
        alignCenter,
        justifyCenter,
        paperShadow,
      ].join(" ")}
      style={style}
    >
      <p
        style={{
          margin: "0",
          fontSize: "2.91vw",
          fontWeight: 700,
          lineHeight: "4.375vw",
        }}
      >
        {number}
      </p>
      <p style={{ margin: "0", fontSize: "1.25vw", fontWeight: 500 }}>{text}</p>
    </div>
  );
}

export default CongratulationsCard;

import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { OnboardingContext } from "../../../context/OnboardingContext";
import Button from "@material-ui/core/Button";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

function SkipOnboardingModal() {
  const { t } = useTranslation("onboarding");
  const { palette, styles, modal } = useContext(OnboardingContext);
  const [openModal, setOpenModal] = modal;
  const onboardingStyles = styles;
  const {
    primaryFont,
    pAbsolute,
    pRelative,
    primaryBG,
    whiteBG,
    w100,
    mr6,
    ml6,
    mAuto,
    dFlex,
    column,
    alignCenter,
    justifyCenter,
    noBorder,
    radiusSm,
    radiusMd,
    textWhite,
    textPrimary,
    font18,
    btnShadow,
    noUppercase,
    pointer,
    btnHover,
    backdropOpacity,
    exitModal,
  } = onboardingStyles();
  const { primary } = palette;

  const handleClose = () => setOpenModal("none");

  const handleConfirm = () => (window.location.href = "https://storydots.app");

  return (
    <div
      className={[pAbsolute, dFlex, mAuto, backdropOpacity].join(" ")}
      style={{
        zIndex: 8,
        width: "100vw",
        height: "100vh",
        minHeight: "49.48vw",
        display: `${openModal}`,
        position: "absolute",
        top: "0",
        left: "0",
      }}
      onClick={handleClose}
    >
      <div
        onClick={handleClose}
        className={[w100, dFlex, pRelative, mAuto].join(" ")}
        style={{ height: "49.48vw", minHeight: "49.48vw" }}
      >
        <div
          className={[
            primaryFont,
            pAbsolute,
            whiteBG,
            mAuto,
            dFlex,
            column,
            alignCenter,
            justifyCenter,
            radiusMd,
            exitModal,
          ].join(" ")}
          style={{
            top: "29vw",
            right: "4.53125vw",
            padding: "0.78125vw 3.125vw 1.5625vw 3.125vw",
          }}
        >
          <ExitToAppIcon
            style={{ fontSize: "3.2vw", color: `${primary.main}` }}
          />
          <p
            style={{
              margin: "0.52vw 0 1.5625vw 0",
              fontSize: "1.25vw",
              fontWeight: 700,
            }}
          >
            {t("skip.question")}
          </p>
          <div className={[dFlex].join(" ")}>
            <Button
              onClick={handleClose}
              className={[
                primaryFont,
                mr6,
                dFlex,
                alignCenter,
                justifyCenter,
                whiteBG,
                font18,
                noBorder,
                textPrimary,
                noUppercase,
                pointer,
              ].join(" ")}
              style={{
                width: "7vw",
                height: "2.1875vw",
                padding: "0.4167vw 1.1458vw",
                fontWeight: 600,
                letterSpacing: "0.46px",
              }}
            >
              {t("skip.cancel")}
            </Button>
            <Button
              onClick={handleConfirm}
              className={[
                primaryFont,
                ml6,
                dFlex,
                alignCenter,
                justifyCenter,
                primaryBG,
                noBorder,
                radiusSm,
                textWhite,
                font18,
                btnShadow,
                noUppercase,
                pointer,
                btnHover,
              ].join(" ")}
              style={{
                width: "7vw",
                height: "2.1875vw",
                padding: "0.4167vw 1.1458vw",
                fontWeight: 600,
                letterSpacing: "0.46px",
              }}
            >
              {t("skip.confirm")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SkipOnboardingModal;

import React, { useContext } from "react";
import { TextField } from "@material-ui/core";
import { OnboardingContext } from "../../../context/OnboardingContext";
import useWizardContentTranslate from "../../../helpers/useWizardContentTranslate";
import validateEmail from "../../../helpers/validateEmail";
import axios from "axios";

function Welcome() {
  const { slideN, styles, email, saveEmail, loadingButtonC } =
    useContext(OnboardingContext);
  const [slideToShow, setSlideToShow] = slideN;
  const [validEmail, setValidEmail] = email;
  const [emailSaved, setEmailSaved] = saveEmail;
  const [loadingButton, setLoadingButton] = loadingButtonC;
  const onboardingStyles = styles;
  const { mt0, mb0, textCenter, font18, emailInput } = onboardingStyles();
  const translateWizardContent = useWizardContentTranslate();
  const wc = translateWizardContent();

  const handleEmail = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    if (validateEmail(e.target.value)) {
      setEmailSaved(e.target.value);
      setValidEmail(true);
      return;
    }
    setValidEmail(false);
  };

  const handleEnter = async (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key !== "Enter") return;

    if (validEmail) {
      try {
        setLoadingButton(true);
        await axios.post(`${process.env.REACT_APP_API_URL}/saveEmail`, {
          email: emailSaved,
        });
      } catch (err) {
        setLoadingButton(false);
        console.log(err);
      }
      setLoadingButton(false);
      setSlideToShow(1);
    }
  };

  return (
    <>
      <h2
        className={[mt0, textCenter].join(" ")}
        style={{ fontSize: "1.25vw" }}
      >
        {wc.welcome.title}
      </h2>
      <p
        className={[mt0, mb0, font18, textCenter].join(" ")}
        style={{ fontWeight: 500, lineHeight: "1.5625vw" }}
      >
        {wc.welcome.messages[0]}
      </p>
      <TextField
        variant="outlined"
        type="email"
        label="Email"
        className={[emailInput].join(" ")}
        onChange={handleEmail}
        onKeyDown={handleEnter}
      />
    </>
  );
}

export default Welcome;

import { useContext } from "react";
import { useTransition, animated /*, TODO: easings */ } from "react-spring";
import { OnboardingContext } from "../../../context/OnboardingContext";
import { WizardAvatar, WizardImage } from "../../Atoms";
import { WizardNavigation } from "../../Molecules";
import { WizardContent } from "../../Organisms";

interface IMessage {
  text?: string;
  fontWeight?: number;
}

interface IWizardContent {
  title: string;
  messages: string | string[] | IMessage | IMessage[] | IMessage[][];
  time?: string;
}

interface Props {
  wizardContent: JSX.Element | IWizardContent;
  wizardImage?: string;
  moveWizard?: any[];
}

function Wizard({ wizardContent, wizardImage, moveWizard }: Props) {
  const { slideN, styles } = useContext(OnboardingContext);
  const [slideToShow, setSlideToShow] = slideN;
  const onboardingStyles = styles;
  const {
    whiteBG,
    pAbsolute,
    pRelative,
    paddingLg,
    dFlex,
    column,
    alignCenter,
    justifyCenter,
    radiusMd,
    textJustify,
    paperShadow,
  } = onboardingStyles();

  const transitions = useTransition(slideToShow, {
    from: { opacity: 0, transform: "translateY(35px)" },
    enter: { opacity: 1, transform: "translateY(0)" },
    config: { duration: 600 /* TODO: , easing: easings.easeOutCirc*/ },
  });

  return (
    <div
      className={[pRelative, dFlex, alignCenter, justifyCenter]
        .concat(moveWizard)
        .join(" ")}
      style={{ width: "35.21vw" }}
    >
      {transitions((props, item) => {
        return (
          <animated.div
            className={[
              pAbsolute,
              dFlex,
              column,
              whiteBG,
              radiusMd,
              paperShadow,
              paddingLg,
              textJustify,
            ].join(" ")}
            style={{
              ...props,
              width: "35.21vw",
              paddingTop: "2.92vw",
              paddingBottom: "1.67vw",
            }}
          >
            <WizardAvatar />
            <WizardImage wizardImage={wizardImage} />
            {(wizardContent as IWizardContent).title &&
            (wizardContent as IWizardContent).messages ? (
              <WizardContent
                wizardContent={
                  wizardContent as {
                    title: string;
                    messages: string[] | IMessage[] | IMessage[][];
                    time?: string;
                  }
                }
              />
            ) : (
              wizardContent
            )}
            <WizardNavigation />
          </animated.div>
        );
      })}
    </div>
  );
}

export default Wizard;

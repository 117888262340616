import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { CheckoutForm, PageContainer } from "components";
import { useStyles } from "./styles";
import { useHistory } from "react-router-dom";
import { FULLDEMO } from "config/consts";
import { Trans, useTranslation } from "react-i18next";
import { IFormValues } from "../../Organisms/CheckoutForm";
import { useQueryParams } from "utils/hooks";

const Checkout = () => {
  const { t } = useTranslation("CheckoutView");
  const classes = useStyles();
  const queryParams = useQueryParams();
  const history = useHistory();
  const handleNext = () => {
    history.push({
      pathname: FULLDEMO.THANK_YOU_PAGE,
      search: `?${queryParams}`,
    });
  };
  const handleSubmit = (data: IFormValues) => {
    for (const [key, value] of Object.entries(data)) {
      queryParams.set(key, value);
    }
    queryParams.delete("code");
    handleNext();
  };

  return (
    <PageContainer>
      <>
        <Grid
          container
          direction="row"
          justify="center"
          spacing={5}
          alignItems="center"
        >
          <Grid item sm={12} md={5} lg={5}>
            <Box px={5}>
              <Box className={classes.responsiveBox}>
                <Typography variant="h3" color="primary" paragraph>
                  {t("title")}
                </Typography>
                <Typography variant="body1" align={"center"}>
                  <Box className={classes.responsiveBox}>
                    <Trans
                      t={t}
                      i18nKey="description"
                      components={{ p: <p />, strong: <strong /> }}
                    />
                  </Box>
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item sm={12} md={6}>
            <Box px={5}>
              <CheckoutForm onSubmit={handleSubmit} />
            </Box>
          </Grid>
        </Grid>
      </>
    </PageContainer>
  );
};

export default Checkout;

import React from "react";
import { Grid, Box, Typography } from "@material-ui/core";
import {
  TourCartCheckoutCard,
  CartProductCard,
  PageContainer,
  IFormValues,
} from "components";
import { useStyles } from "./styles";
import { SHORT_TOUR } from "config/consts";
import { useTranslation } from "react-i18next";
import Boy from "../../../../assets/boy/standing.svg";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { useQueryParams } from "utils/hooks";
import { useHistory } from "react-router-dom";

const Cart = () => {
  const { t } = useTranslation("CartView");
  const classes = useStyles();
  const queryParams = useQueryParams();
  const history = useHistory();
  const handleNext = () => {
    history.push({
      pathname: SHORT_TOUR.THANK_YOU_PAGE,
      search: `?${queryParams}`,
    });
  };
  const isLg = useMediaQuery("(max-width:1280px)");

  const handleSubmit = (data: IFormValues) => {
    for (const [key, value] of Object.entries(data)) {
      queryParams.set(key, value);
    }
    queryParams.delete("code");
    handleNext();
  };

  return (
    <PageContainer className={classes.container}>
      <Grid
        container
        direction="row"
        justify="center"
        spacing={5}
        alignItems="center"
        style={{ height: "100%" }}
      >
        {!isLg && (
          <Grid item xs={12} lg={3}>
            <img src={Boy} alt="" className={classes.boy} />
          </Grid>
        )}

        <Grid item xs={12} lg={6} xl={4}>
          {/* Coffee Maker Card */}
          <CartProductCard />

          {/* Greeting Card */}
          <TourCartCheckoutCard
            nextPath={SHORT_TOUR.THANK_YOU_PAGE}
            onSubmit={handleSubmit}
          />
        </Grid>
        <Grid item xs={12} lg={6} xl={5}>
          <Box className={classes.textContainer}>
            <Typography className={classes.title} variant="h3">
              {t("short.title")}
            </Typography>
            <Typography className={classes.subtitle}>
              {t("short.subtitle")}
            </Typography>
            <Typography className={classes.description}>
              {t("short.description")}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default Cart;

import { makeStyles } from "@material-ui/core";
import { constants } from "config/theme";

export const useStyles = makeStyles((theme) => ({
  pageCard: {
    paddingTop: theme.spacing(1),
    paddingLeft: 0,
    paddingRight: 0,
    marginLeft: 0,
    marginRight: 0,
    borderRadius: "12px",
  },
  button: {
    borderRadius: constants.BORDER_RADIOUS_SMALL,
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  giftIcon: {
    height: "7vh",
    padding: theme.spacing(1),
  },
  title: {
    color: "#434343",
  },
  checkbox: {
    transform: "scale(1.3)",
  },
  inputWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  inputComponent: {
    marginRight: 20,
  },
  messageContainer: {
    width: "100%",
    boxSizing: "border-box",
    paddingBottom: theme.spacing(2),
  },
  form: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  infoText: {
    color: "#25c796",
    fontSize: "14px",
    fontWeight: 600,
    textAlign: "left",
  },
}));

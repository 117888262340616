import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { FAQAccordion, FAQFooterCard, PageContainer } from "components";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";

const FAQ = () => {
  const { t } = useTranslation("FAQView");
  const classes = useStyles();

  return (
    <PageContainer className={classes.root}>
      <>
        <Grid item xs={12} className={classes.item}>
          <Typography variant="h3" gutterBottom color="primary">
            {t("title")}
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.item}>
          <FAQAccordion />
        </Grid>

        <Grid container>
          <FAQFooterCard />
        </Grid>
      </>
    </PageContainer>
  );
};

export default FAQ;

import React, { useContext } from "react";
import { AppContext } from "context";
import { AppBar, IconButton, Toolbar, Tooltip } from "@material-ui/core";
import { Link, useLocation, useHistory } from "react-router-dom";
import { FULLDEMO } from "config/consts";
import { FlexGrowSeparator, ActorsStepper } from "components";
import HomeIcon from "@material-ui/icons/Home";
import Logo from "assets/logo.svg";
import styles from "./styles";

const NavBar: React.FC = () => {
  const classes = styles();
  const queryParams = useLocation().search;
  const history = useHistory();
  const goHome = () =>
    history.push({
      pathname: FULLDEMO.WELCOME,
      search: `${queryParams}`,
    });
  const {
    client: { brand },
  } = useContext(AppContext);
  return (
    <AppBar position="sticky" className={classes.root} elevation={0}>
      <Toolbar>
        <img
          onClick={goHome}
          src={Logo}
          alt="sd-logo"
          className={classes.sdLogo}
        />
        {brand?.logo_url && (
          <img
            src={brand?.logo_url}
            className={classes.whiteLabelLogo}
            alt="startup-logo"
          />
        )}
        <Tooltip title="Home" placement="bottom">
          <IconButton
            className={classes.menuButton}
            color="primary"
            aria-label="home"
            edge={false}
            component={Link}
            to={`${FULLDEMO.WELCOME}${queryParams}`}
          >
            <HomeIcon className={classes.menuButtonIcon} />
          </IconButton>
        </Tooltip>
        <FlexGrowSeparator />
        <ActorsStepper className={classes.stepper} stepSpacing={32} />
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;

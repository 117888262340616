import React from "react";
import { Backdrop, Paper, Typography } from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { useStyles } from "./styles";

export interface IErrorOverlay {
  openOn: boolean;
  title?: string;
  description?: string;
}

export default function ErrorOverlay({
  openOn,
  title,
  description,
  ...props
}: IErrorOverlay) {
  const classes = useStyles();

  return (
    <div>
      <Backdrop className={classes.backdrop} open={openOn}>
        <Paper elevation={3} className={classes.paper}>
          <Typography variant="h4" gutterBottom>
            <ErrorOutlineIcon className={classes.icon} />
            {title || "Ups!"}
          </Typography>
          <Typography variant="body1" align="center">
            {description || "Lo sentimos, algo no salió como esperábamos"}
          </Typography>
        </Paper>
      </Backdrop>
    </div>
  );
}

import { makeStyles } from "@material-ui/core";
import { constants } from "config/theme";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0 16px 16px",
    padding: "24px 24px",
    maxWidth: "1000px",
    overflow: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  accordion: {
    borderRadius: constants.BORDER_RADIOUS_SMALL,
    "& .MuiAccordionSummary-content": {
      flexGrow: "unset",
    },
    "& .MuiAccordion-root.Mui-expanded": {
      margin: 0,
    },
  },
  accordionSummary: {
    "&:hover": {
      backgroundColor: "rgba(116, 16, 224, 0.08);",
    },
    backgroundColor: "#f7f7f7",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
  },
}));

export { useStyles };

import React from "react";
import { useStyles } from "./styles";
import { Card, Grid, Typography } from "@material-ui/core";
import Clip from "assets/clip.svg";
import Key from "assets/key.svg";
import { useTranslation } from "react-i18next";
export interface IUserData {
  username: string | null;
  password: string | null;
}

interface IDashboardActions {
  /**
   * Credentials that will be shown on the first card to the demo user logins on SD dashboard
   */
  userData: IUserData;
}

const DashboardActions: React.FC<IDashboardActions> = ({ userData }) => {
  const { t } = useTranslation("DashboardView");
  const classes = useStyles();
  return (
    <Grid container justify="space-evenly">
      {/* Credentials Card */}
      {userData.username && userData.password && (
        <Grid item>
          <Card raised className={classes.passwordCard}>
            <Grid
              container
              justify="space-between"
              spacing={1}
              alignItems="center"
              wrap="nowrap"
            >
              <Grid item>
                <img src={Key} className={classes.keyImage} alt="" />
              </Grid>
              <Grid item>
                <Typography
                  variant="body2"
                  align="left"
                  className={classes.actionTexts}
                >
                  {t("user")}: {userData.username || "demo"}
                </Typography>
                <Typography
                  variant="body2"
                  align="left"
                  className={classes.actionTexts}
                >
                  {t("password")}: {userData.password || "demo"}
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      )}

      {/* Go to Dashboard Button */}
      <Grid item>
        <Card
          raised
          className={classes.goToDashboardCard}
          onClick={() => window.open("https://my.storydots.app/", "_blank")}
        >
          <Grid
            container
            justify="space-between"
            spacing={1}
            alignItems="center"
            wrap="nowrap"
          >
            <Grid item>
              <img src={Clip} className={classes.keyImage} alt="" />
            </Grid>
            <Grid item>
              <Typography
                variant="body2"
                align="left"
                className={classes.actionTexts}
              >
                {t("goToDashboardButton")}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

export default DashboardActions;

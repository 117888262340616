import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    height: "70px",
  },
  finishedLabel: {
    height: "12px",
    marginTop: "-8px",
    textAlign: "center",
    fontFamily: "Poppins",
    fontSize: "12px",
    fontWeight: "bold",
  },
}));

export default useStyles;

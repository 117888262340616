import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0 16px 16px",
    padding: "24px 24px",
    maxWidth: "1000px",
    overflow: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  item: {
    paddingBottom: 32,
  },
}));

export { useStyles };

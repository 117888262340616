import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-end",
    position: "relative",
    marginBottom: "19px",
    top: 0,
    left: 0,
  },
  completed: {
    marginBottom: "12px",
  },
  label: {
    position: "absolute",
    bottom: "-20px",
    marginTop: "3px",
    fontWeight: "bold",
    fontSize: "12px",
    fontFamily: "Poppins",
  },
}));

export default useStyles;

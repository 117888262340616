import React, { useState } from "react";
import { useGetBrand } from "utils/hooks";

interface IAppContext {
  client: IClientData;
  setClient: Function;
}

interface IBrandData {
  logo_url?: string;
}

interface IClientData {
  username: string;
  password: string;
  api_key: string;
  brand?: IBrandData;
}

const URL_PARAMS = {
  USER: "user",
  API_KEY: "api_key",
  PASSWORD: "password",
};

const empty_client = {
  username: "",
  password: "",
  api_key: "",
};

export const AppContext = React.createContext<IAppContext>({
  client: empty_client,
  setClient: () => {},
});

const urlParams = new URLSearchParams(window.location.search);
const init_client: IClientData = {
  username: urlParams.get(URL_PARAMS.USER) || "",
  password: urlParams.get(URL_PARAMS.PASSWORD) || "",
  api_key: urlParams.get(URL_PARAMS.API_KEY) || "",
};
export const AppContextProvider: React.FC = ({ children }) => {
  init_client.brand = useGetBrand(init_client.api_key);

  const [client, setClient] = useState(init_client);
  return (
    <AppContext.Provider
      value={{
        client,
        setClient,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

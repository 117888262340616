import React from "react";
import { Accordion, AccordionItem } from "components";
import { VideoPlayer } from "components";
import { Typography, Grid } from "@material-ui/core";
import OrdersPanel from "assets/Review-orders.jpg";
import Iphone from "assets/iphoneMarcaBlanca.png";
import Tag from "assets/tag-startupchile.png";
import { FULLDEMO } from "config/consts";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";

const OrderItem = () => {
  const { t } = useTranslation("FAQView");
  const classes = useStyles();
  return (
    <Grid container direction="row" alignItems="center" justify="center">
      <img src={OrdersPanel} alt="order" className={classes.accordionImage} />
      <Typography variant="body1">
        {t("Accordion.item1.description")}
      </Typography>
    </Grid>
  );
};

const VideoItem = () => {
  const { t } = useTranslation("FAQView");
  return (
    <Grid container direction="column" alignItems="center" justify="center">
      <VideoPlayer videoUrl={FULLDEMO.DEFAULT_GREETING_URL} />
      <Typography variant="body1">
        {t("Accordion.item2.description")}
      </Typography>
    </Grid>
  );
};

const WhiteLebelItem = () => {
  const { t } = useTranslation("FAQView");
  const classes = useStyles();
  return (
    <Grid container direction="column" alignItems="center" justify="center">
      <Grid item>
        {[Iphone, Tag].map((image) => (
          <img
            src={image}
            alt="white-label"
            className={classes.accordionImage}
          />
        ))}
      </Grid>
      <Typography variant="body1">
        {t("Accordion.item3.description")}
      </Typography>
    </Grid>
  );
};

const FAQAccordion = () => {
  const { t } = useTranslation("FAQView");
  const accordionItems: AccordionItem[] = [
    {
      summary: t("Accordion.item1.summary"),
      content: <OrderItem />,
    },
    {
      summary: t("Accordion.item2.summary"),
      content: <VideoItem />,
    },
    {
      summary: t("Accordion.item3.summary"),
      content: <WhiteLebelItem />,
    },
  ];
  return <Accordion items={accordionItems} />;
};

export default FAQAccordion;

import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { OnboardingContext } from "../../../context/OnboardingContext";
import { Tooltip } from "../../Atoms";
import IconButton from "@material-ui/core/IconButton";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

function SkipOnboardingButton() {
  const { t } = useTranslation("onboarding");
  const { slideN, styles, palette, modal } = useContext(OnboardingContext);
  const [slideToShow, setSlideToShow] = slideN; // eslint-disable-line @typescript-eslint/no-unused-vars
  const onboardingStyles = styles;
  const {
    pAbsolute,
    w100,
    h100,
    dFlex,
    alignCenter,
    justifyCenter,
    mtAuto,
    mbAuto,
    textWhite,
  } = onboardingStyles();
  const { primary } = palette;
  const [openModal, setOpenModal] = modal; // eslint-disable-line @typescript-eslint/no-unused-vars
  const [tooltipDisplay, setTooltipDisplay] = useState("none");
  let color = "white";
  if ([13].includes(slideToShow)) color = primary.main; // FIXED SLIDE NUMBER

  const handleClick = () => {
    setOpenModal("flex");
    setTooltipDisplay("none");
  };

  return (
    <div
      className={[
        pAbsolute,
        dFlex,
        alignCenter,
        justifyCenter,
        mtAuto,
        mbAuto,
        textWhite,
      ].join(" ")}
      style={{
        zIndex: 7,
        width: "2.60417vw",
        height: "2.60417vw",
        top: "42.8125vw",
        right: "4.53125vw",
        bottom: "4.0625vw",
        color,
        border: `0.104vw solid ${color}`,
        borderRadius: "50%",
      }}
    >
      <Tooltip
        text={t("skip.tooltip")}
        top={"-105%"}
        display={tooltipDisplay}
      />
      <IconButton
        onClick={handleClick}
        onMouseEnter={() => setTooltipDisplay("flex")}
        onMouseLeave={() => setTooltipDisplay("none")}
        className={[w100, h100].join(" ")}
        style={{ color }}
      >
        <ExitToAppIcon style={{ fontSize: "1.5625vw" }} />
      </IconButton>
    </div>
  );
}

export default SkipOnboardingButton;

import ReactGA from "react-ga";
import { environment } from "config";
import { useEffect } from "react";

const { GA_ID, NODE_ENV } = environment;

const initGA = () => {
  ReactGA.initialize(GA_ID, {
    debug: NODE_ENV === "dev",
  });
};

const logPageView = (pageName: string) => {
  ReactGA.set({ page: pageName });
  ReactGA.pageview(pageName);
};

/**
 * Optionally takes the page name to be logged in the GA pageview
 */
const useGoogleAnalytics = (
  pageName = window.location.pathname + window.location.search,
) => {
  return useEffect(() => {
    if (!window.GA_INITIALIZED) {
      initGA();
      window.GA_INITIALIZED = true;
    }

    NODE_ENV === "production" && logPageView(pageName);
  }, []);
};

export default useGoogleAnalytics;

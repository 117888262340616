import React from "react";
import { Typography, Grid } from "@material-ui/core";
import { Button } from "components";
import { useStyles } from "./styles";
import { useHistory } from "react-router-dom";
import { FULLDEMO } from "config/consts";
import Boy from "assets/boy/waving.svg";
import { useTranslation, Trans } from "react-i18next";
import { useQueryParams } from "utils/hooks";
import { PageContainer } from "components";

const Welcome = () => {
  const { t } = useTranslation("WelcomeView");
  const classes = useStyles();
  const queryParams = useQueryParams();
  const history = useHistory();
  const handleNext = () => {
    history.push({
      pathname: FULLDEMO.ACTORS,
      search: `?${queryParams}`,
    });
  };
  return (
    <PageContainer>
      <>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={3}
        >
          <Grid container sm={3} justify="center">
            <img src={Boy} className={classes.boy} alt="waving-boy"></img>
          </Grid>
          <Grid item xs={12} sm={8} lg={8} className={classes.descriptiveText}>
            <Typography color="primary" variant="h2" gutterBottom>
              {t("welcome")}
            </Typography>
            <br />
            <Typography align="justify" variant="body1">
              {t("mainParagraph")}
            </Typography>
            <br />
            <Typography variant="h5">{t("gearUp")}</Typography>
            <Typography variant="body1" align="justify">
              <Trans
                t={t}
                i18nKey="secondaryParagraph"
                components={{ bold: <strong />, p: <p /> }}
              />
            </Typography>
            <Button className={classes.button} onClick={handleNext} blink>
              {t("button")}
            </Button>
            <Typography variant="body1" color="textSecondary">
              {t("disclaimer")}
            </Typography>
          </Grid>
        </Grid>
      </>
    </PageContainer>
  );
};

export default Welcome;

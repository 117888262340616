import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: "2rem",
    marginRight: "2rem",
  },
  container: {
    display: "flex",
    alignItems: "flex-start",
  },
}));

export { useStyles };

import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { FULLDEMO } from "config/consts";
import Particles from "react-tsparticles";
import particlesConfig from "config/particles";

import {
  Actors,
  Cart,
  Checkout,
  FAQ,
  NotBad,
  Receiver,
  Surprise,
  SwitchToReceiver,
  Welcome,
  NavBar,
  Dashboard,
  ThankYouPage,
} from "components";

const FullDemo: React.FC = () => {
  const queryParams = new URLSearchParams(window.location.search);

  return (
    <BrowserRouter>
      <div>
        <NavBar />
        <div className="App">
          <Switch>
            <Route exact path="/">
              {<Redirect to={`${FULLDEMO.WELCOME}?${queryParams}`} />}
            </Route>
            <Route exact path={FULLDEMO.WELCOME} component={Welcome} />
            <Route path={FULLDEMO.ACTORS} component={Actors} />
            <Route path={FULLDEMO.CART} component={Cart} />
            <Route path={FULLDEMO.CHECKOUT} component={Checkout} />
            <Route path={FULLDEMO.THANK_YOU_PAGE} component={ThankYouPage} />
            <Route
              path={FULLDEMO.SWITCH_TO_RECEIVER}
              component={SwitchToReceiver}
            />
            <Route path={FULLDEMO.SURPRISE} component={Surprise} />
            <Route path={FULLDEMO.RECEIVER} component={Receiver} />
            <Route path={FULLDEMO.DASHBOARD} component={Dashboard} />
            <Route path={FULLDEMO.NOT_BAD} component={NotBad} />
            <Route path={FULLDEMO.FAQ} component={FAQ} />
          </Switch>
        </div>
        {/* @ts-ignore */}
        <Particles id="cont-canvas" params={particlesConfig} />
      </div>
    </BrowserRouter>
  );
};

export default FullDemo;

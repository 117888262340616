import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "70%",
  },
  boy: {
    height: "50vh",
  },
  button: {
    marginTop: "4vh",
    marginBottom: "6vh",
  },
  responsiveBox: {
    textAlign: "justify",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      paddingTop: "1rem",
    },
  },
}));

export { useStyles };

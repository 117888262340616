import React from "react";
import "./App.css";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  useImagePreloader,
  useQueryParams,
  useGoogleAnalytics,
} from "utils/hooks";
import { FullDemo, MobileInfo, ShortTour, Onboarding } from "pages";
import { LoadingOverlay } from "./components";

function App() {
  const isMobile = !useMediaQuery("(min-width:960px)");
  const queryParams = useQueryParams();
  const pathname = window.location.pathname;
  useGoogleAnalytics();
  const { loading } = useImagePreloader(
    queryParams.get("code"),
    queryParams.get("api_key"),
  );

  if (loading) return <LoadingOverlay isLoading={loading} />;
  if (isMobile) return <MobileInfo />;
  if (pathname.includes("onboarding")) return <Onboarding />;
  return pathname.includes("tour") ? <ShortTour /> : <FullDemo />;
}

export default App;

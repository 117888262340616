import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0 16px",
    maxHeight: "90vh",
  },
  imgEmoji: {
    marginTop: 20,
    marginLeft: 20,
  },
  cards: {
    marginTop: "3vw",
  },
  icon: {
    marginBottom: "12px",
    height: "100px",
  },
  button: {
    margin: "4vw 0",
    alignSelf: "center",
    width: "fit-content",
  },
}));

export { useStyles };

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  responsiveBox: {
    textAlign: "justify",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      marginBottom: theme.spacing(3),
    },
  },
}));

export { useStyles };

import React from "react";
import { Typography, Grid } from "@material-ui/core";
import { ActorCard, Button, PageContainer } from "components";
import { useStyles } from "./styles";
import { useHistory } from "react-router-dom";
import { FULLDEMO } from "config/consts";
import { useTranslation, Trans } from "react-i18next";
import emoji from "assets/emoji.svg";
import Buyer from "assets/actors/buyer.svg";
import Receiver from "assets/actors/receiver.svg";
import Store from "assets/actors/store.svg";
import { useQueryParams } from "utils/hooks";

const Actors = () => {
  // Hooks
  const queryParams = useQueryParams();
  const { t } = useTranslation("ActorsView");
  const classes = useStyles();
  const history = useHistory();

  const handleNext = () => {
    history.push({
      pathname: FULLDEMO.CART,
      search: `?${queryParams}`,
    });
  };
  return (
    <PageContainer>
      <>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          spacing={3}
        >
          <Typography variant="h2" color="primary" paragraph>
            <strong>{t("title")}</strong>
            <img
              src={emoji}
              alt="emoji"
              height={48}
              className={classes.imgEmoji}
            />
          </Typography>
          <Typography variant="body1" align={"center"}>
            <Trans t={t} i18nKey="subtitle" components={{ br: <br /> }} />
          </Typography>
          <Grid
            container
            justify="center"
            spacing={3}
            className={classes.cards}
          >
            <Grid item xs={12} sm={4}>
              <ActorCard
                iconSrc={Buyer}
                title={t("buyer.title")}
                description={
                  <Trans
                    t={t}
                    i18nKey="buyer.description"
                    components={{ br: <br />, p: <p /> }}
                  />
                }
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <ActorCard
                iconSrc={Receiver}
                title={t("receiver.title")}
                description={
                  <Trans
                    t={t}
                    i18nKey="receiver.description"
                    components={{ br: <br />, p: <p /> }}
                  />
                }
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <ActorCard
                iconSrc={Store}
                title={t("store.title")}
                description={
                  <Trans
                    t={t}
                    i18nKey="store.description"
                    components={{ br: <br />, p: <p /> }}
                  />
                }
              />
            </Grid>
          </Grid>
          <Button blink onClick={handleNext} className={classes.button}>
            {t("button")}
          </Button>
        </Grid>
      </>
    </PageContainer>
  );
};

export default Actors;

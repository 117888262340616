import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  accordionImage: {
    margin: "16px 16px",
    maxWidth: "100%",
    maxHeight: "300px",
    alignSelf: "center",
  },
}));

export { useStyles };

import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { Button, PageContainer, Stepper } from "components";
import { useStyles } from "./styles";
import { useHistory } from "react-router-dom";
import { SHORT_TOUR } from "config/consts";
import { useTranslation } from "react-i18next";
import BoxImage from "assets/box-with-tag-min.svg";
import { useQueryParams } from "utils/hooks";
import WaivingWithFlag from "../../../../assets/WavingWithBag.svg";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const SwitchToReceiver = () => {
  const { t } = useTranslation("SwitchToReceiverView");
  const classes = useStyles();
  const queryParams = useQueryParams();
  const history = useHistory();
  const handleNext = () => {
    history.push({
      pathname: SHORT_TOUR.RECEIVER,
      search: `?${queryParams}`,
    });
  };
  const isXL = !useMediaQuery("(max-width:1280px)");

  return (
    <PageContainer>
      <>
        <Grid
          container
          direction="row"
          justify="center"
          spacing={10}
          alignItems="center"
        >
          <Grid item sm={12} md={12} lg={6} className={classes.card}>
            {isXL && <img src={WaivingWithFlag} alt="" />}

            <Box px={5}>
              <Grid
                container
                item
                md={5}
                xs={12}
                className={classes.boxAndStepper}
              >
                <img src={BoxImage} alt="box" className={classes.image} />
                <Stepper
                  activeStep={2}
                  labels={[
                    t("Stepper.step1"),
                    t("Stepper.step2"),
                    t("Stepper.step3"),
                  ]}
                />
              </Grid>
            </Box>
          </Grid>
          <Grid item sm={12} md={12} lg={6}>
            <Box px={0} marginLeft="10em" marginBottom="12em">
              <Typography
                variant="h3"
                color="primary"
                style={{ marginBottom: "1em" }}
              >
                {t("short.title")}
              </Typography>
              <Typography variant="body1">{t("short.description")}</Typography>
              <Box className={classes.button}>
                <Button blink onClick={handleNext}>
                  {t("button")}
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </>
    </PageContainer>
  );
};

export default SwitchToReceiver;

import React from "react";
import { Box, Card, Grid, Typography } from "@material-ui/core";
import { Button, PageContainer, Stepper } from "components";
import { useStyles } from "./styles";
import { useHistory } from "react-router-dom";
import { FULLDEMO } from "config/consts";
import { Trans, useTranslation } from "react-i18next";
import BoxImage from "assets/box-with-tag-min.svg";
import { useQueryParams } from "../../../utils/hooks";

const SwitchToReceiver = () => {
  const { t } = useTranslation("SwitchToReceiverView");
  const classes = useStyles();
  const queryParams = useQueryParams();
  const history = useHistory();
  const handleNext = () => {
    history.push({
      pathname: FULLDEMO.SURPRISE,
      search: `?${queryParams}`,
    });
  };
  return (
    <PageContainer>
      <>
        <Grid
          container
          direction="row"
          justify="center"
          spacing={10}
          alignItems="center"
        >
          <Grid item sm={12} md={12} lg={6}>
            <Box px={5} className={classes.responsiveBox}>
              <Typography variant="h3" gutterBottom color="primary">
                {t("title")}
              </Typography>
              <Typography variant="body1">
                <Trans t={t} i18nKey="description" components={{ p: <p /> }} />
              </Typography>
            </Box>
          </Grid>
          <Grid item sm={12} md={12} lg={5} className={classes.card}>
            <Box px={5}>
              <Grid
                container
                item
                md={5}
                xs={12}
                className={classes.boxAndStepper}
              >
                <Card className={classes.image} elevation={15}>
                  <img src={BoxImage} alt="box" className={classes.image} />
                </Card>
                <Stepper
                  activeStep={2}
                  labels={[
                    t("Stepper.step1"),
                    t("Stepper.step2"),
                    t("Stepper.step3"),
                  ]}
                />
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Box className={classes.button}>
          <Button blink onClick={handleNext}>
            {t("button")}
          </Button>
        </Box>
      </>
    </PageContainer>
  );
};

export default SwitchToReceiver;

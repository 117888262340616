import { makeStyles } from "@material-ui/core";
import { constants } from "config/theme";

interface IStyleProps {
  color: "primary" | "secondary";
}

export const useStyles = (props: IStyleProps) =>
  makeStyles((theme) => ({
    root: {
      borderRadius: constants.BORDER_RADIOUS_SMALL,
      "& > *": {
        textTransform: "none",
        paddingLeft: "1.25rem",
        paddingRight: "1.25rem",
      },
    },
    shadowed: {
      boxShadow: `0 0rem 2rem ${theme.palette[props.color].main}`,
    },
    blinkingButton: {
      animation: `$blinkingEffect 1.1s`,
      "-webkit-animation": `$blinkingEffect 1.1s`,
      "-webkit-animation-iteration-count": "infinite",
      "-o-animation-iteration-count": "infinite",
      "animation-iteration-count": "infinite",
      "&:disabled": {
        animation: "none",
      },
      "&:focus": {
        animation: "none",
      },
    },
    "@keyframes blinkingEffect": {
      "0%": {
        "-moz-box-shadow":
          "0 0 0 0 rgba(216, 44, 32, 0.54) rgba(0, 0, 0, 0.12) 0 4px 8px",
        boxShadow: `0 0 0 0 ${
          theme.palette[props.color].main
        }, rgba(0, 0, 0, 0.12) 0 4px 8px`,
      },
      "70%": {
        "-moz-box-shadow":
          "0 0 0 32px rgba(216, 44, 32, 0) rgba(0, 0, 0, 0.12) 0 4px 8px",
        boxShadow:
          "0 0 0 32px rgba(216, 44, 32, 0), rgba(0, 0, 0, 0.12) 0 4px 8px",
      },
      "100%": {
        "-moz-box-shadow":
          "0 0 0 0 rgba(216, 44, 32, 0) rgba(0, 0, 0, 0.12) 0 4px 8px",
        boxShadow:
          "0 0 0 0 rgba(216, 44, 32, 0), rgba(0, 0, 0, 0.12) 0 4px 8px",
      },
    },
  }));

import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  // Containers
  pageCard: {
    marginBottom: theme.spacing(2),
    borderRadius: "12px",
  },
  // Images
  coffeeMakerImg: {
    height: "13vh",
    padding: theme.spacing(1),
  },
  // Product Quantity Layout
  productButtonsContainer: {
    paddingBottom: theme.spacing(2),
  },
  productChangeButtons: {
    border: "1px solid #333333",
    padding: theme.spacing(1),
    paddingTop: 0,
    paddingBottom: 0,
  },
  productQuantity: {
    border: "1px solid #333333",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

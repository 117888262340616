import React, { useEffect, useState } from "react";
import { Box, Fade, Grid, Theme, Typography } from "@material-ui/core";
import { ErrorOverlay, PageContainer, ThankYouCard } from "components";
import { useStyles } from "./styles";
import { FULLDEMO } from "config/consts";
import { Trans, useTranslation } from "react-i18next";
import checkCircle from "assets/green-check.svg";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { senderRequestOptions } from "utils/helpers/senderRequestOptions";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { useQueryParams } from "utils/hooks";
import { LoadingOverlay } from "components";
import imgOnLoad from "../../../utils/helpers/imgOnLoad";
import getQrCardImage from "../../../utils/helpers/getQrCardImage";

const ThankYou: React.FC = () => {
  // Hooks
  const queryParams = useQueryParams();
  const { t } = useTranslation("ThankYouPageView");
  const history = useHistory();
  const classes = useStyles();

  // States
  const [currentCode, setCurrentCode] = useState(queryParams.get("code") || "");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [qrImage, setQrImage] = useState<string>("");

  const isMobile = !useMediaQuery(
    (theme: Theme) => theme.breakpoints.up("lg"), // only in this case is lg
  );

  const getQrImage = async () => {
    try {
      if (!currentCode) {
        const email = queryParams.get("email") || "";
        const name = queryParams.get("name") || "";
        const api_key = queryParams.get("api_key") || "";
        const preorder_receiver_name = queryParams.get("to") || "";
        const preorder_text_message = queryParams.get("message") || "";
        const requestOptions = senderRequestOptions({
          name,
          email,
          api_key,
          preorder_receiver_name,
          preorder_text_message,
        });
        const getCode = await axios.request(requestOptions).catch((e) => {
          throw e;
        });
        const { code } = getCode.data;
        queryParams.set("code", code);
        history.replace({
          search: `?${queryParams}`,
        });
        setCurrentCode(code);
      }
      const code = queryParams.get("code");
      const qrImage = await axios
        .get(`${process.env.REACT_APP_API_SITE_URL}/qrImage/${code}?sender=1`)
        .catch((e) => {
          throw e;
        });
      setQrImage(qrImage.data);
      // QR Card image preload to display on Receiver view
      code && (await imgOnLoad(getQrCardImage(code)));
    } catch (e) {
      setError(true);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getQrImage();
  }, []);

  return (
    <PageContainer>
      <>
        <LoadingOverlay isLoading={isLoading} />
        <ErrorOverlay openOn={error} />
        <Fade in={!isLoading} timeout={1500}>
          <Grid container alignItems="center" justify="space-around">
            <Grid item sm={12} lg={5}>
              <Grid container justify={isMobile ? "center" : "flex-start"}>
                <Box className={classes.leftParagraph}>
                  <Typography variant="h3" color="primary" paragraph>
                    <img
                      src={checkCircle}
                      alt={"Check"}
                      height={32}
                      className={classes.checkCircle}
                    />
                    {t("title")}
                  </Typography>
                  <Box maxWidth={400}>
                    <Typography variant="body1">
                      <Trans
                        t={t}
                        i18nKey="description"
                        components={{ p: <p /> }}
                      />
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={12} md={8} lg={6}>
              <Grid>
                <ThankYouCard
                  qrImage={qrImage}
                  nextPath={FULLDEMO.SWITCH_TO_RECEIVER}
                />
              </Grid>
            </Grid>
          </Grid>
        </Fade>
      </>
    </PageContainer>
  );
};

export default ThankYou;

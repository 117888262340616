import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { Button, PageContainer } from "components";
import { useStyles } from "./styles";
import { useHistory } from "react-router-dom";
import { FULLDEMO } from "config/consts";
import { Trans, useTranslation } from "react-i18next";
import Standing from "assets/Standing.svg";
import { useQueryParams } from "utils/hooks";

const NotBad = () => {
  const { t } = useTranslation("NotBadView");
  const queryParams = useQueryParams();
  const classes = useStyles();
  const history = useHistory();
  const handleNext = () => {
    history.push({
      pathname: FULLDEMO.FAQ,
      search: `?${queryParams}`,
    });
  };
  return (
    <PageContainer className={classes.root}>
      <>
        <Grid container alignItems="center" justify="center" spacing={3}>
          <Grid item xs={12} sm={6}>
            <div align={"center"}>
              <img src={Standing} alt="" className={classes.boy} />
            </div>
          </Grid>
          <Grid
            container
            direction="column"
            xs={12}
            sm={6}
            alignItems="flex-start"
          >
            <Box className={classes.responsiveBox}>
              <Typography variant="h3" gutterBottom color="primary">
                {t("title")}
              </Typography>
              <Typography variant="body1">
                <Trans t={t} i18nKey="description" components={{ p: <p /> }} />
              </Typography>
              <Button blink className={classes.button} onClick={handleNext}>
                {t("button")}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </>
    </PageContainer>
  );
};

export default NotBad;

import React from "react";
import useStyles from "./styles";
import { theme } from "../../../../config/theme";
import classnames from "classnames";

const {
  palette: { illustration },
} = theme;

interface IProps {
  className?: string;
}

const Ready: React.FC<IProps> = ({ className }) => {
  const classes = useStyles();
  return (
    <svg
      viewBox="0 0 285.4 286.2"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={classnames(classes.root, className)}
    >
      <radialGradient
        id="ready_shine-gradient"
        cx="142.6956"
        cy="-781.3141"
        r="151.7567"
        gradientTransform="matrix(1 0 0 -1 0 -637.7872)"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset="0"
          style={{ stopColor: illustration.shine, stopOpacity: 1 }}
        />

        <stop
          offset="0.8725"
          style={{ stopColor: illustration.shine, stopOpacity: 0 }}
        />
      </radialGradient>
      <g className="ready_shine-container">
        <path
          className="ready_shine"
          d="M299.9,158.9v-31.6l-4.3-23.1L285,74.4l-134.8,65c-0.2-0.3-0.4-0.8-0.6-1.1L273.3,54L253,29.8l-18-15L207.6-1l-61.5,136.5c-0.3-0.2-0.8-0.3-1.2-0.4L185.5-9.1l-31.1-5.5l-23.5,0.1L99.8-9.1L140.4,135c-0.4,0.1-0.8,0.3-1.2,0.4L77.7-1L50.3,14.8L32.4,29.9L12.1,54.1l123.7,84.3c-0.3,0.3-0.4,0.7-0.6,1.1L0.3,74.4l-10.8,29.8l-4.2,23.1v31.6l148.9-15c0,0.4,0.1,0.9,0.3,1.2l-145,36.9l10.8,29.8l11.8,20.3l20.3,24.2l104.4-107.2c0.3,0.3,0.6,0.6,0.9,0.8L50.3,271.3l27.4,15.8l22.1,8l31.1,5.5l11.2-149.3c0.2,0,0.4,0,0.6,0c0.2,0,0.4,0,0.6,0l11.1,149.2l31.1-5.5l22.1-7.9l27.4-15.8l-87.3-121.4c0.3-0.3,0.7-0.5,0.9-0.8L253,256.3l20.3-24.2l11.7-20.4l10.8-29.8l-145-36.9c0.1-0.4,0.2-0.8,0.3-1.2L299.9,158.9z"
        />
      </g>
      <g className="ready_bag-container">
        <g className="ready_bag">
          <g>
            <path
              className="illustration_shade"
              d="M183.5,132.9h-81.4l-8.8-14.7l13.6-13c0.7-0.6,1.6-1,2.5-0.9h66.7c0.9,0,1.8,0.3,2.5,0.9l13.6,13L183.5,132.9z"
            />
            <path
              className="illustration_shade-dark"
              d="M176.9,118.2h-3.8c0-28.2-10.2-42.5-30.3-42.5c-16.7,0-26.6,9.9-29.4,29.5c-0.6,3.9-4.3,3.5-3.8-0.5c3.9-26.8,19.6-32.8,33.2-32.8C158.3,71.9,176.9,79.9,176.9,118.2z"
            />
            <path
              className="illustration_light"
              d="M197.1,214.2H88.5c-1.4,0-2.5-1.1-2.5-2.5c0-0.1,0-0.1,0-0.1l3.9-29.9l3.6-63.5h98.8l3.6,63.5l3.9,29.9c0.1,1.4-1,2.6-2.4,2.7C197.2,214.2,197.2,214.2,197.1,214.2z"
            />
          </g>
          <g className="ready_tag">
            <path
              className="illustration_shade"
              d="M183,148.4h-33.3c-1.2,0-2.3,1-2.3,2.3l0,0l-3.1,46.2c0,1.2,1,2.3,2.3,2.3h33.3c1.2,0,2.3-1,2.3-2.3l0,0l3.1-46.2C185.2,149.4,184.2,148.4,183,148.4z M175.6,162.2h-18.7c-2.1,0-3.8-1.7-3.8-3.8s1.7-3.8,3.8-3.8h18.7c2.1,0,3.8,1.7,3.8,3.8S177.7,162.2,175.6,162.2L175.6,162.2z"
            />
            <path
              className="illustration_paper"
              d="M185.1,148.4h-33.3c-1.2,0-2.3,1-2.3,2.3v46.2c0,1.2,1,2.3,2.3,2.3l0,0h33.3c1.2,0,2.3-1,2.3-2.3l0,0v-46.2C187.4,149.4,186.4,148.4,185.1,148.4L185.1,148.4z M177.8,162.2h-18.7c-2.1,0-3.8-1.7-3.8-3.8s1.7-3.8,3.8-3.8l0,0h18.7c2.1,0,3.8,1.7,3.8,3.8C181.6,160.5,179.9,162.2,177.8,162.2z"
            />
            <path
              className="illustration_shade"
              d="M166.1,167.3h-9.5c-0.7,0-1.2,0.5-1.2,1.2v9.5c0,0.7,0.5,1.2,1.2,1.2l0,0h9.5c0.7,0,1.2-0.5,1.2-1.2v-9.5C167.3,167.9,166.7,167.3,166.1,167.3L166.1,167.3z M164.9,176.9h-7.2v-7.2h7.2V176.9z"
            />
            <circle
              className="illustration_shade"
              cx="161.3"
              cy="173.3"
              r="1.2"
            />
            <path
              className="illustration_shade"
              d="M170.8,179.3h9.5c0.7,0,1.2-0.5,1.2-1.2l0,0v-9.5c0-0.7-0.5-1.2-1.2-1.2h-9.5c-0.7,0-1.2,0.5-1.2,1.2l0,0v9.5C169.6,178.7,170.2,179.3,170.8,179.3z M172,169.7h7.2v7.2H172V169.7z"
            />
            <path
              className="illustration_shade"
              d="M166.1,181.7h-9.5c-0.7,0-1.2,0.5-1.2,1.2v9.5c0,0.7,0.5,1.2,1.2,1.2l0,0h9.5c0.7,0,1.2-0.5,1.2-1.2v-9.5C167.2,182.2,166.7,181.7,166.1,181.7z M164.9,191.2h-7.2V184h7.2V191.2z"
            />
            <path
              className="illustration_shade"
              d="M170.8,184h8.4v2c0,0.7,0.5,1.2,1.2,1.2s1.2-0.5,1.2-1.2v-3.2c0-0.7-0.5-1.2-1.2-1.2h-9.5c-0.7,0-1.2,0.5-1.2,1.2C169.6,183.5,170.2,184,170.8,184L170.8,184z"
            />
            <circle
              className="illustration_shade"
              cx="161.3"
              cy="187.6"
              r="1.2"
            />
            <path
              className="illustration_shade"
              d="M175.6,186.4h-4.8c-0.7,0-1.2,0.5-1.2,1.2v4.8c0,0.7,0.5,1.2,1.2,1.2h4.8c0.7,0,1.2-0.5,1.2-1.2v-4.8C176.8,187,176.3,186.4,175.6,186.4L175.6,186.4z M174.4,191.2H172v-2.4h2.4V191.2z"
            />
            <circle
              className="illustration_shade"
              cx="175.6"
              cy="173.3"
              r="1.2"
            />
            <path
              className="illustration_shade"
              d="M180.4,193.6c0.7,0,1.2-0.5,1.2-1.2l0,0v-1.6c0-0.7-0.5-1.2-1.2-1.2s-1.2,0.5-1.2,1.2l0,0v1.6C179.2,193,179.7,193.6,180.4,193.6z"
            />
            <rect
              x="166.5"
              y="118.2"
              className="illustration_shade-dark"
              width="3.8"
              height="36.9"
            />
          </g>
          <g className="ready_roll">
            <path
              className="illustration_shade-dark"
              d="M83.6,176.8l-22.2-5.3l13.5-56.1l0.2-0.6c6.1-17.2,26-26.1,40.7-23.5c13.2,2.3,21.5,12.6,21.5,26.9h-22.8c0-3.9-1.2-4.1-2.5-4.4c-4.9-0.8-12.9,2.8-15.1,8.2L83.6,176.8z"
            />
            <path
              className="illustration_main"
              d="M78.1,175.5L67,172.8l3.3-13.9l11.1,2.7L78.1,175.5z M83.3,153.8l-11.1-2.7l3.3-13.9l11.1,2.7L83.3,153.8zM88.5,132.1l-11.1-2.7l3.1-12.7c0.3-0.8,0.6-1.6,0.9-2.3l10.3,4.9c-0.2,0.3-0.3,0.7-0.4,1L88.5,132.1z M131.6,118.2h-11.4c0-0.5,0-1-0.1-1.5l11.4-0.9C131.6,116.6,131.6,117.4,131.6,118.2z M95.2,114.5l-8.1-8.1c4-3.9,9.2-7,14.7-8.6l3.1,11C101.3,109.9,97.9,111.9,95.2,114.5z M118.7,111.8c-1.8-2.9-5.1-3.6-7.5-3.7l0.5-11.4c7.4,0.3,13.3,3.6,16.8,9.2L118.7,111.8z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Ready;

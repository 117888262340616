import cloudsAndArrowBG from "./clouds/clouds.jpg";
import dashboardBG from "./dashboard/sd-dashboard.jpg";
import cartBG from "./cart/cart-01.jpg";
import cartBGPT from "./cart/cart-01-PT.jpg";
import cartBGEN from "./cart/cart-01-EN.jpg";
import cartSnippetBG from "./cart/cart-02.jpg";
import cartSnippetBGPT from "./cart/cart-02-PT.jpg";
import cartSnippetBGEN from "./cart/cart-02-EN.jpg";
import cartProductBG from "./cart/cart-03.jpg";
import cartProductBGPT from "./cart/cart-03-PT.jpg";
import cartProductBGEN from "./cart/cart-03-EN.jpg";
import cartGreetingBG from "./cart/cart-04.jpg";
import cartGreetingBGPT from "./cart/cart-04-PT.jpg";
import cartGreetingBGEN from "./cart/cart-04-EN.jpg";
import cartSuccessBG from "./cart/cart-05.jpg";
import cartSuccessBGPT from "./cart/cart-05-PT.jpg";
import cartSuccessBGEN from "./cart/cart-05-EN.jpg";
import gmailBG from "./gmail/gmail-01.jpg";
import tiendanubeSalesBG from "./tiendanube-ventas/tn-ventas-01.jpg";
import tiendanubeSalesBGPT from "./tiendanube-ventas/tn-ventas-01-PT.jpg";
import tiendanubeSalesBGEN from "./tiendanube-ventas/tn-ventas-01-EN.jpg";
import tiendanubeOrderBG from "./tiendanube-ventas/tn-ventas-02.jpg";
import tiendanubeOrderBGPT from "./tiendanube-ventas/tn-ventas-02-PT.jpg";
import tiendanubeOrderBGEN from "./tiendanube-ventas/tn-ventas-02-EN.jpg";
import tiendanubePrintSDBG from "./tiendanube-ventas/tn-ventas-03.jpg";
import tiendanubePrintSDBGPT from "./tiendanube-ventas/tn-ventas-03-PT.jpg";
import tiendanubePrintSDBGEN from "./tiendanube-ventas/tn-ventas-03-EN.jpg";
import dashboardPrintQRBG from "./dashboard/sd-print.jpg";
import dashboardPrintQRBGPT from "./dashboard/sd-print-PT.jpg";
import dashboardPrintQRBGEN from "./dashboard/sd-print-EN.jpg";

export const BGsES = {
  cloudsAndArrowBG,
  dashboardBG,
  cartBG,
  cartSnippetBG,
  cartProductBG,
  cartGreetingBG,
  cartSuccessBG,
  gmailBG,
  tiendanubeSalesBG,
  tiendanubeOrderBG,
  tiendanubePrintSDBG,
  dashboardPrintQRBG,
};

export const BGsPT = {
  cloudsAndArrowBG,
  dashboardBG,
  cartBG: cartBGPT,
  cartSnippetBG: cartSnippetBGPT,
  cartProductBG: cartProductBGPT,
  cartGreetingBG: cartGreetingBGPT,
  cartSuccessBG: cartSuccessBGPT,
  gmailBG,
  tiendanubeSalesBG: tiendanubeSalesBGPT,
  tiendanubeOrderBG: tiendanubeOrderBGPT,
  tiendanubePrintSDBG: tiendanubePrintSDBGPT,
  dashboardPrintQRBG: dashboardPrintQRBGPT,
};

export const BGsEN = {
  cloudsAndArrowBG,
  dashboardBG,
  cartBG: cartBGEN,
  cartSnippetBG: cartSnippetBGEN,
  cartProductBG: cartProductBGEN,
  cartGreetingBG: cartGreetingBGEN,
  cartSuccessBG: cartSuccessBGEN,
  gmailBG,
  tiendanubeSalesBG: tiendanubeSalesBGEN,
  tiendanubeOrderBG: tiendanubeOrderBGEN,
  tiendanubePrintSDBG: tiendanubePrintSDBGEN,
  dashboardPrintQRBG: dashboardPrintQRBGEN,
};

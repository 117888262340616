import React from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";
import { useStyles } from "./styles";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import CoffeeMaker from "assets/coffee_maker.svg";
import { useTranslation } from "react-i18next";

const CartProductCard: React.FC = ({ ...props }) => {
  const { t } = useTranslation("CartView");
  const classes = useStyles();
  return (
    <div>
      <Card raised className={classes.pageCard}>
        <CardHeader
          disableTypography
          title={
            <Grid container justify="space-between">
              <Box ml={1}>
                <Typography variant="subtitle1" align="left" color="primary">
                  {t("CartCard.title")}
                </Typography>
              </Box>
              <Box mr={1}>
                <ShoppingCartIcon color="primary" />
              </Box>
            </Grid>
          }
        />
        <Divider />
        <CardContent>
          <Grid
            container
            direction="row"
            justify="flex-start"
            spacing={5}
            wrap="nowrap"
          >
            <Grid item>
              <img
                src={CoffeeMaker}
                className={classes.coffeeMakerImg}
                alt={"Product"}
              />
            </Grid>
            <Grid item>
              <Typography variant="body1" align="left" gutterBottom>
                {t("CartCard.productTitle")}
              </Typography>
              <Typography variant="subtitle1" align="left" gutterBottom>
                <strong>{t("CartCard.price")}</strong>
              </Typography>
              {/* Add or remove product */}
              <Grid
                container
                justify="flex-start"
                className={classes.productButtonsContainer}
                wrap={"nowrap"}
              >
                <Grid item className={classes.productChangeButtons}>
                  <Typography>-</Typography>
                </Grid>
                <Grid item className={classes.productQuantity}>
                  <Typography>1</Typography>
                </Grid>
                <Grid item className={classes.productChangeButtons}>
                  <Typography>+</Typography>
                </Grid>
              </Grid>
              <Typography align="left" variant="caption">
                {t("CartCard.summary")} <strong>US$ 189</strong>
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export default CartProductCard;

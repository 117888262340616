import { useContext } from "react";
import { OnboardingContext } from "../../../context/OnboardingContext";
import storydotsLogo from "assets/storydots-white-logo.svg";
import him from "assets/onboardingAssets/el.png";
import her from "assets/onboardingAssets/ella.png";
import {
  CongratulationsInnerContainer,
  CongratulationsTitle,
} from "../../Molecules";
// TODO: import useSendGTMEvent from "Utils/hooks/useSendGTMEvent";

function Congratulations() {
  const { styles } = useContext(OnboardingContext);
  const onboardingStyles = styles;
  const {
    primaryFont,
    pAbsolute,
    pRelative,
    w100,
    h100,
    mh100vh,
    mbAuto,
    dFlex,
    column,
    textWhite,
    textCenter,
  } = onboardingStyles();

  // TODO: useSendGTMEvent("onboarding finish");

  return (
    <div
      className={[
        primaryFont,
        pRelative,
        w100,
        h100,
        mh100vh,
        dFlex,
        column,
        textWhite,
        textCenter,
        mbAuto,
      ].join(" ")}
      style={{ height: "49.48vw" }}
    >
      <img
        src={storydotsLogo}
        alt="Storydots logo"
        className={[pAbsolute].join(" ")}
        style={{ width: "11vw", top: "3vw", left: "4vw" }}
      />
      <img
        src={him}
        alt="a man"
        className={[pAbsolute, mbAuto].join(" ")}
        style={{
          zIndex: 6,
          height: "33.854vw",
          top: "11.09375vw",
          left: "14.5833vw",
        }}
      />
      <div
        className={[h100, dFlex, column].join(" ")}
        style={{ marginRight: "21.614853vw", marginLeft: "24.53125vw" }}
      >
        <CongratulationsTitle />
        <CongratulationsInnerContainer />
      </div>
      <img
        src={her}
        alt="a woman"
        className={[pAbsolute, mbAuto].join(" ")}
        style={{ height: "31.4583vw", top: "10.78125vw", right: "12.0833vw" }}
      />
    </div>
  );
}

export default Congratulations;

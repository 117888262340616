import { OnboardingPalette } from "types/theme";

const onboardingPalette: OnboardingPalette = {
  primary: {
    main: "rgba(98, 66, 232, 1)",
    light: "rgba(148, 122, 255, 1)",
    dark: "rgba(64, 43, 154, 1)",
    contrastText: "rgba(255, 255, 255, 1)",
  },
  secondary: {
    main: "rgba(0, 252, 195, 1)",
    light: "rgba(106, 255, 221, 1)",
    dark: "rgba(0, 192, 149, 1)",
    contrastText: "rgba(64, 43, 154, 1)",
  },
};

export default onboardingPalette;

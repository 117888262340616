import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    width: 600,
    "& .MuiStepIcon-root": {
      color: "#4CAF50",
    },
    "& div:nth-child(3) > span > span.MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel > svg":
      {
        color: "#00000061",
      },
    backgroundColor: "unset",
    "& .MuiStepConnector-line": {
      position: "relative",
      margin: "0 20px",
      top: "10px",
    },
    "& .MuiStepIcon-root.MuiStepIcon-completed, .MuiStepIcon-root.MuiStepIcon-active":
      {
        color: "#4CAF50",
      },
  },
  step: {
    margin: 0,
  },
  activeStep: {
    color: "#4CAF50",
  },
  boldStep: {
    fontWeight: 600,
  },
  inactiveStep: {
    color: "lightgray",
  },
}));

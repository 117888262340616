import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0 16px",
    padding: "24px 24px",
    maxHeight: "100%",
  },
  title: {
    margin: "24px 0 12px",
  },
  boy: {
    height: "29.125rem",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  button: {
    marginTop: "5.5rem",
    marginBottom: "6vh",
  },
  descriptiveText: {
    maxWidth: "666px",
    textAlign: "left",
  },
}));

export { useStyles };

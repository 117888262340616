export const FULLDEMO = {
  WELCOME: "/1",
  ACTORS: "/2",
  CART: "/3",
  CHECKOUT: "/4",
  THANK_YOU_PAGE: "/5",
  SWITCH_TO_RECEIVER: "/6",
  SURPRISE: "/7",
  RECEIVER: "/8",
  DASHBOARD: "/9",
  NOT_BAD: "/10",
  FAQ: "/11",
  DEFAULT_GREETING_URL: "https://www.youtube.com/embed/pT8gy-DT3jM",
};

export const SHORT_TOUR = {
  WELCOME: "/tour/welcome",
  ACTORS: "/tour/actors",
  CART: "/tour/cart",
  THANK_YOU_PAGE: "/tour/thankyou",
  SWITCH_TO_RECEIVER: "/tour/switch",
  RECEIVER: "/tour/receiver",
};

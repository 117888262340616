import { createContext, useState, useRef } from "react";
import propTypes from "prop-types";
import onboardingPalette from "./onboardingPalette";
import onboardingStyles from "./onboardingStyles";
import useImagesPreloading from "./useImagesPreloading";

interface props {
  children: JSX.Element | JSX.Element[];
}
interface onboardingProv {
  slideN: any[];
  email: any[];
  saveEmail: any[];
  loadingButtonC: any[];
  palette: any;
  styles: any;
  stepper: any;
  modal: any;
  preload: any;
}

export const OnboardingContext = createContext({} as onboardingProv);

export const OnboardingContextProvider = ({ children }: props) => {
  const [slideToShow, setSlideToShow] = useState(0);
  const [validEmail, setValidEmail] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);

  const palette = onboardingPalette;

  const styles = onboardingStyles;

  // Stepper variables
  const [activeStep, setActiveStep] = useState(0);
  const [introCompleted, setIntroCompleted] = useState(false);
  const [integrationCompleted, setIntegrationCompleted] = useState(false);
  const [printCompleted, setPrintCompleted] = useState(false);
  const [emailSaved, setEmailSaved] = useState("");

  // Skip onboarding modal
  const [openModal, setOpenModal] = useState("none");

  // Preload images
  const imagesPreloaded = useRef(useImagesPreloading());

  return (
    <OnboardingContext.Provider
      value={{
        slideN: [slideToShow, setSlideToShow],
        email: [validEmail, setValidEmail],
        saveEmail: [emailSaved, setEmailSaved],
        loadingButtonC: [loadingButton, setLoadingButton],
        palette,
        styles,
        stepper: {
          active: [activeStep, setActiveStep],
          intro: [introCompleted, setIntroCompleted],
          integ: [integrationCompleted, setIntegrationCompleted],
          print: [printCompleted, setPrintCompleted],
        },
        modal: [openModal, setOpenModal],
        preload: imagesPreloaded,
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
};

OnboardingContextProvider.propTypes = {
  children: propTypes.any,
};

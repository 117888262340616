import React from "react";
import { Typography, Grid, Link, Box } from "@material-ui/core";
import Button from "components/Atoms/Button";
import { useStyles } from "./styles";
import boyGirl from "assets/boy-and-girl.svg";
import { useTranslation } from "react-i18next";

const FAQFooterCard: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation("FAQView");
  return (
    <Grid container className={classes.bottomContainer}>
      <Grid item xs={12} sm={6} className={classes.iconsCard}>
        <img src={boyGirl} alt="jumping" className={classes.icons} />
      </Grid>
      <Grid item xs={12} sm={6} className={classes.rightCard}>
        <Box className={classes.responsiveBox}>
          <Typography color="primary" variant="h4">
            {t("FooterCard.title")}
          </Typography>
          <Typography>{t("FooterCard.paragraph")}</Typography>
          <Box className={classes.button}>
            <Link target="_blank" href="https://calendly.com/leogierberg/">
              <Button>
                <strong>{t("FooterCard.button")}</strong>
              </Button>
            </Link>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default FAQFooterCard;

import { useContext } from "react";
import { OnboardingContext } from "../context/OnboardingContext";
import useWizardContentTranslate from "./useWizardContentTranslate";
import SlideBG from "../components/Atoms/SlideBG/SlideBG";
import {
  Congratulations,
  QRScan,
  Welcome,
  Wizard,
} from "../components/Organisms";
import wizardIcons from "../../../../assets/onboardingAssets/wizardIcons/index";

const useGetOnboardingSlides = () => {
  const { styles, preload } = useContext(OnboardingContext);
  const onboardingStyles = styles;
  const { dFlex, alignCenter, justifyCenter, printEndMoveWizard } =
    onboardingStyles();
  const preloadedImages = preload.current;
  const { thumbsUp, cart, present, gmail, printer, success } = wizardIcons;
  const translateWizardContent = useWizardContentTranslate();
  const wc = translateWizardContent();

  // When adding extra slides take in consideration that slideToShow is set to a fixed number in some places and may need (or not) an update. In that case search for FIXED SLIDE NUMBER and make updates that are necessary.
  const onboardingSlides: JSX.Element[] = [
    <SlideBG
      BGImage={preloadedImages.dashboardBG}
      content={<Wizard wizardContent={<Welcome />} />}
    />,
    <SlideBG
      BGImage={preloadedImages.dashboardBG}
      content={<Wizard wizardContent={wc.hello} />}
    />,
    <SlideBG
      BGImage={preloadedImages.dashboardBG}
      content={<Wizard wizardContent={wc.howItWorks} />}
    />,
    <SlideBG
      BGImage={preloadedImages.dashboardBG}
      content={<Wizard wizardContent={<QRScan />} wizardImage={thumbsUp} />}
    />,
    <SlideBG
      BGImage={preloadedImages.cartBG}
      content={
        <Wizard wizardContent={wc.storeIntegration} wizardImage={cart} />
      }
    />,
    <SlideBG
      BGImage={preloadedImages.cartSnippetBG}
      content={
        <Wizard wizardContent={wc.simulatePurchase} wizardImage={present} />
      }
    />,
    <SlideBG
      BGImage={preloadedImages.cartProductBG}
      content={
        <Wizard
          wizardContent={wc.greetingIsAddedToCart}
          wizardImage={present}
        />
      }
    />,
    <SlideBG
      BGImage={preloadedImages.cartGreetingBG}
      content={
        <Wizard
          wizardContent={wc.quickGreetingCreation}
          wizardImage={present}
        />
      }
    />,
    <SlideBG
      BGImage={preloadedImages.cartSuccessBG}
      content={
        <Wizard
          wizardContent={wc.orderEndedSuccessfully}
          wizardImage={success}
        />
      }
    />,
    <SlideBG
      BGImage={preloadedImages.gmailBG}
      content={
        <div className={[dFlex, alignCenter, justifyCenter].join(" ")}>
          <Wizard wizardContent={wc.emailSent} wizardImage={gmail} />
          <img
            src={preloadedImages.emailMobile}
            alt="email shown at mobile"
            style={{
              width: "19.3229vw",
              height: "35.104vw",
              margin: "auto auto auto 3.64583vw",
              transform: "translateY(-2.2917vw)",
            }}
          />
        </div>
      }
    />,
    <SlideBG
      BGImage={preloadedImages.tiendanubeSalesBG}
      content={<Wizard wizardContent={wc.SDArrivesAsQR} />}
    />,
    <SlideBG
      BGImage={preloadedImages.tiendanubeOrderBG}
      content={<Wizard wizardContent={wc.printing} wizardImage={printer} />}
    />,
    <SlideBG
      BGImage={preloadedImages.dashboardPrintQRBG}
      content={
        <Wizard
          wizardContent={wc.printingEnd}
          wizardImage={printer}
          moveWizard={[printEndMoveWizard]}
        />
      }
    />,
    <SlideBG
      BGImage={preloadedImages.cloudsAndArrowBG}
      content={<Congratulations />}
    />,
  ];

  return onboardingSlides;
};

export default useGetOnboardingSlides;

import { useContext, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { OnboardingContext } from "../../../context/OnboardingContext";
import Button from "@material-ui/core/Button";
import { CircularProgress } from "@material-ui/core";
import useNavigationControllers from "./useNavigationControllers";

function WizardNavigation() {
  const { t } = useTranslation("onboarding");
  const { slideN, email, styles, loadingButtonC } =
    useContext(OnboardingContext);
  const [slideToShow, setSlideToShow] = slideN;
  const [validEmail] = email;
  const [loadingButton] = loadingButtonC;
  const onboardingStyles = styles;
  const {
    primaryFont,
    primaryBG,
    whiteBG,
    w100,
    mt4,
    dFlex,
    alignCenter,
    justifyCenter,
    justifyBetween,
    justifyEnd,
    noBorder,
    radiusSm,
    textWhite,
    textPrimary,
    font18,
    btnShadow,
    noUppercase,
    pointer,
    btnHover,
    beginBtnDisabled,
  } = onboardingStyles();
  const nextBtnText = useRef(t("wizardContent.nextBtn"));
  const nextBtnWidth = useRef("6.98vw");
  const nextBtnHeight = useRef("2.1875vw");
  const initialAlign = slideToShow === 1 ? justifyEnd : justifyBetween;
  const nextBtnAlign = useRef([initialAlign]);
  const [firstSlideBtnStyles, setFirstSlideBtnStyles] = useState([pointer]);
  const { handleNext, handleGoBack } = useNavigationControllers();

  useEffect(() => {
    if (slideToShow !== 0) return; // FIXED SLIDE NUMBER

    nextBtnWidth.current = "10.83vw";
    nextBtnHeight.current = "2.8125vw";
    nextBtnAlign.current = [justifyCenter];

    if (validEmail) {
      nextBtnText.current = `${t("wizardContent.beginBtn")} 💪`;
      setFirstSlideBtnStyles([pointer]);
      return;
    }

    nextBtnText.current = `${t("wizardContent.beginBtn")}`;
    setFirstSlideBtnStyles([beginBtnDisabled]);
  }, [validEmail, beginBtnDisabled, justifyCenter, pointer, slideToShow, t]);

  if (loadingButton)
    return (
      <div
        className={[w100, dFlex, alignCenter, mt4]
          .concat(nextBtnAlign.current)
          .join(" ")}
      >
        <Button
          disabled
          className={[
            primaryFont,
            dFlex,
            alignCenter,
            justifyCenter,
            primaryBG,
            noBorder,
            radiusSm,
            font18,
            btnShadow,
            noUppercase,
            pointer,
            btnHover,
          ].join(" ")}
          style={{
            width: `${nextBtnWidth.current}`,
            height: "2.8125vw",
            color: "white",
            fontWeight: 600,
            letterSpacing: "0.46px",
          }}
        >
          <CircularProgress
            size={"16px"}
            color="secondary"
            style={{ marginRight: "10px" }}
          />
          {t("wizardContent.loadingBtn")}
        </Button>
      </div>
    );

  return (
    <div
      className={[w100, dFlex, alignCenter, mt4]
        .concat(nextBtnAlign.current)
        .join(" ")}
    >
      {slideToShow > 1 && (
        <Button
          onClick={handleGoBack}
          className={[
            primaryFont,
            dFlex,
            whiteBG,
            noBorder,
            textPrimary,
            noUppercase,
            pointer,
          ].join(" ")}
          style={{
            width: `${nextBtnWidth.current}`,
            height: "2.1875vw",
            fontSize: "0.8vw",
            fontWeight: 600,
            letterSpacing: "0.46px",
          }}
        >
          {t("wizardContent.goBackBtn")}
        </Button>
      )}
      <Button
        onClick={handleNext}
        className={[
          primaryFont,
          dFlex,
          alignCenter,
          justifyCenter,
          primaryBG,
          noBorder,
          radiusSm,
          textWhite,
          font18,
          btnShadow,
          noUppercase,
          pointer,
          btnHover,
        ]
          .concat(firstSlideBtnStyles)
          .join(" ")}
        style={{
          width: `${nextBtnWidth.current}`,
          height: `${nextBtnHeight.current}`,
          padding: "0.4167vw 1.1458vw",
          fontWeight: 600,
          letterSpacing: "0.46px",
        }}
      >
        {nextBtnText.current}
      </Button>
    </div>
  );
}

export default WizardNavigation;

import { makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

const FlexGrowSeparator: React.FC = () => {
  const classes = useStyles();
  return <div className={classes.root} />;
};

export default FlexGrowSeparator;

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0 16px 16px",
    padding: "16px 16px",
    overflow: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  leftColumn: {
    textAlign: "justify",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      order: 2,
      marginTop: theme.spacing(6),
    },
  },
}));

export { useStyles };

import React, { useContext } from "react";
import { OnboardingContext } from "../../../context/OnboardingContext";
import axios from "axios";

function useNavigationControllers() {
  const { slideN, stepper, saveEmail, loadingButtonC } =
    useContext(OnboardingContext);
  const [slideToShow, setSlideToShow] = slideN;
  const [emailSaved] = saveEmail;
  const [loadingButton, setLoadingButton] = loadingButtonC;
  const [activeStep, setActiveStep] = stepper.active; // eslint-disable-line @typescript-eslint/no-unused-vars
  const [introCompleted, setIntroCompleted] = stepper.intro; // eslint-disable-line @typescript-eslint/no-unused-vars
  const [integrationCompleted, setIntegrationCompleted] = stepper.integ; // eslint-disable-line @typescript-eslint/no-unused-vars
  const [printCompleted, setPrintCompleted] = stepper.print; // eslint-disable-line @typescript-eslint/no-unused-vars

  const handleNext = async () => {
    if (slideToShow === 0) {
      try {
        setLoadingButton(true);
        await axios.post(`${process.env.REACT_APP_API_URL}/saveEmail`, {
          email: emailSaved,
        });
        setLoadingButton(false);
      } catch (err) {
        console.log(err);
        setLoadingButton(false);
      }
    }

    setSlideToShow((prev: number) => prev + 1);
    if (slideToShow === 3) {
      // FIXED SLIDE NUMBER
      setIntroCompleted(true);
      setActiveStep(1);
    }
    if (slideToShow === 9) {
      // FIXED SLIDE NUMBER
      setIntegrationCompleted(true);
      setActiveStep(2);
    }
    if (slideToShow === 12) {
      // FIXED SLIDE NUMBER
      setPrintCompleted(true);
    }
  };

  const handleGoBack = () => {
    setSlideToShow((prev: number) => prev - 1);
    if (slideToShow === 4) setActiveStep(0); // FIXED SLIDE NUMBER
    if (slideToShow === 10) setActiveStep(1); // FIXED SLIDE NUMBER
  };

  return {
    handleNext,
    handleGoBack,
  };
}

export default useNavigationControllers;

const TRANSLATION_PT = {
  ActorStepper: {
    Buyer: "Comprador",
    Receiver: "Receptor",
    Store: "Loja",
    Finished: "Demo concluída!",
  },
  WelcomeView: {
    welcome: "Seja bem-vindo!",
    mainParagraph:
      "Ao se integrar ao carrinho de compras on-line, StoryDots permite que seus clientes adicionem uma saudação surpresa quando compram um presente. O objetivo desta demonstração é mostrar a você como a ferramenta funciona.",
    gearUp: "Importante",
    secondaryParagraph:
      "<p>1) Não deixe de <strong>abrir esta demonstração em seu computador.</strong></p>" +
      "<p>2) <strong>Mantenha seu telefone celular à mão:</strong> você precisará dele para recriar a experiência do usuário.</p>",
    button: "Vamos começar!",
    disclaimer:
      "Nota: Os dados coletados durante esta experiência de demonstração não serão utilizados.",
  },
  ActorsView: {
    title: "Introdução",
    subtitle:
      "A experiência envolve três partes. Nesta demonstração, você vai se posicionar no lugar de cada um deles. É por isso que explicamos cada um deles em detalhes abaixo: ",
    buyer: {
      title: "Comprador",
      description:
        "Esta é a pessoa que compra o presente e adiciona a saudação virtual. ",
    },
    receiver: {
      title: "Receptor",
      description:
        "Esta é a pessoa que recebe o presente com um QR. <br/>" +
        "Quando escaneado, ele jogará a saudação virtual à qual você pode responder.",
    },
    store: {
      title: "Loja",
      description:
        "É a StoryDots que oferece StoryDots para melhorar a experiência de suas compras de presentes, e para conhecer a rastreabilidade dos dados.",
    },
    button: "Iniciar demonstração",
  },
  CartView: {
    title: "Agora você é o comprador",
    description:
      "<p>Você está à procura de um presente de aniversário.</p>" +
      "<p>Que melhor para um fanático por café do que uma máquina de café expresso?</p>" +
      "<p><strong>A loja oferece a possibilidade de incluir uma saudação surpresa gratuita...</strong></p>",
    CartCard: {
      title: "Seu carrinho",
      productTitle: "Máquina de café expresso",
      price: "US$ 189",
      summary: "Subtotal (sem envio): ",
    },
    CartSnippet: {
      text: "Você está fazendo um presente?",
      textAffirm: "Você está fazendo um presente!",
      from: "De",
      to: "Para",
      email: "Email",
      message: "Escreva aqui sua mensagem",
      infoText: "Você pode completar sua saudação após a compra.",
      charsLeft: "caracteres restantes.",
      checkoutButton: "Compre",
    },
    validations: {
      required: "Obrigatório",
      maxChars: "Deve ter até 240 caracteres",
      email: "E-mail inválido",
    },
    short: {
      title:
        "Esta demonstração permitirá que você descubra a funcionalidade do Storydots",
      subtitle:
        "Um cliente está comprando uma máquina de café como um presente e você está vendo seu carrinho movido a histórias.",
      description:
        "Favor selecionar a opção de presente e continuar o checkout",
    },
  },
  GreetingAddedView: {
    greetingAdded: "Saudações acrescentadas com sucesso!",
    info: "Você poderá gravá-lo após o checkout.",
    checkoutButton: "Checkout",
  },
  CheckoutView: {
    title: "Checkout simplificado",
    description:
      "<p>Como em todo comércio eletrônico, no momento de iniciar a compra, alguns dados devem ser preenchidos. Solicitamos apenas as informações necessárias para a experiência, portanto, pedimos que <strong>você preencha seus dados reais.</strong></p>",
    CheckoutForm: {
      name: "Nome",
      email: "Endereço de e-mail",
      apiKey: "Chave API",
      button: "Compre",
    },
    Validations: {
      name: "O nome é um campo obrigatório",
      email: {
        required: "O e-mail é um campo obrigatório",
        format: "Formato de e-mail inválido",
      },
      apiKey: {
        required: "A chave API é um campo obrigatório",
        format: "Formato de chave API inválido",
      },
    },
  },
  ThankYouPageView: {
    title: "Compra feita!",
    description:
      "Uma vez que o comprador complete seu pagamento, eles serão levados a esta página de Agradecimento, onde poderão completar ou melhorar sua saudação. Se não fizer nada, ele/ela receberá por e-mail.",
    Card: {
      paragraph1:
        "É hora de criar sua saudação, então pegue seu smartphone. Você tem duas opções:",
      paragraph2:
        "Crie agora com sua câmera smartphone escaneando este código QR. Não tem um aplicativo escaneador? Abra o storydots.app/scan em seu navegador.",
      paragraph3:
        "Verifique seu e-mail, você encontrará um link para criar sua saudação.",
      paragraph4: "Uma vez criada sua saudação, prossiga para o próximo passo.",
      or: "o",
      button: "Continuar",
    },
  },
  SwitchToReceiverView: {
    title: "Enquanto isso...",
    description:
      "<p>A loja recebe o pedido com a saudação virtual.</p>" +
      "<p>Imprima a etiqueta QR auto-gerada (contendo a saudação) e prenda-a ao presente.</p>" +
      "<p>Este é o fim de sua experiência de compra.</p>",
    button: "Ir para a experiência do destinatário",
    Stepper: {
      step1: "Pagamento",
      step2: "Envio",
      step3: "Entregue",
    },
    short: {
      title: "O pacote já foi enviado",
      description:
        "A loja recebeu seu pedido e preparou e surpreendeu a experiência de presente!",
    },
  },
  SurpriseView: {
    title: "Mudança de função: agora você é o receptor",
    description:
      "O mensageiro entrega um pacote. Ao verificá-lo, você percebe que é um presente e que vem com uma surpresa em um adesivo de QR...",
    button: "Continuar",
  },
  ReceiverView: {
    title: "Mudança de função: agora você é o receptor",
    description:
      "<p>Como avisamos no início, você vai precisar de seu telefone celular. Para descobrir a surpresa, <strong>digitalize o código QR com seu telefone celular.</strong> </p>" +
      "<p>Após enviar sua resposta, continue aqui para a última etapa.</p>",
    button: "Continuar",
    short: {
      title:
        "Você recebeu seu precioso pacote de presentes e ele tem uma etiqueta de QR, digitalize-o e fique maravilhado!",
      caption:
        "Esperamos que você tenha gostado da experiência - se você ainda tiver alguma dúvida, <strong>por favor, entre em contato conosco</strong>.",
    },
  },
  DashboardView: {
    title: "Bastidores: Agora você é a loja",
    description:
      "<p>Nós não somos apenas um serviço de saudação. <strong>Também coletamos dados inteligentes.</strong></p>" +
      "<p>Fornecemos a você as <strong>credenciais para entrar em nosso painel de controle</strong> onde seus dados de saudação são exibidos, como visto por nossos clientes.</p>",
    button: "Fim",
    goToDashboardButton: "Ir para Dashboard",
    user: "Usuário",
    password: "Senha",
  },
  NotBadView: {
    title: "Quase lá!",
    description: "Esperamos que tenham gostado de nossa demonstração.",
    button: "Último passo",
  },
  FAQView: {
    title: "Você pode estar se perguntando...",
    Accordion: {
      item1: {
        summary: "Como as lojas recebem e imprimem etiquetas StoryDots?",
        description:
          " As lojas acessam o painel de administração onde podem verificar se uma compra inclui o StoryDots e podem imprimir de lá.",
      },
      item2: {
        summary:
          "O que acontece se um receptor escaneia uma etiqueta sem uma saudação?",
        description:
          "Na StoryDots não deixamos as experiências inacabadas. Se a pessoa que comprou o presente não gravar sua saudação, o destinatário verá um vídeo padrão como o que está abaixo.",
      },
      item3: {
        summary: "Como funciona o branding privado?",
        description:
          " Tanto a aplicação como a etiqueta QR são 100% personalizáveis para marcas.",
      },
    },
    FooterCard: {
      title: "Vamos conversar",
      paragraph:
        "Você gostaria de saber sobre nossas embalagens inteligentes? Você precisa falar conosco para esclarecer suas dúvidas?",
      button: "Agende uma chamada",
    },
  },
  MobileInfoPage: {
    title: "Seja bem-vindo!",
    description:
      "<p>Estamos felizes por você estar aqui.</p>" +
      "<p><strong>Esta demonstração é otimizada para a visualização da área de trabalho.</strong></p>" +
      "<p>Não se preocupe, você vai precisar de seu telefone mais tarde.</p>",
    secondaryButton: "Ir ao site",
    primaryButton: "Agendar Demo",
  },
  onboarding: {
    language: "PT",
    wizardContent: {
      nextBtn: "Próximo",
      beginBtn: "Iniciar",
      loadingBtn: "Carregando...",
      goBackBtn: "Voltar",
      welcome: {
        title: "Bem-vindo/a!",
        messages: {
          msg1: "Queremos conhecê-lo melhor para que o possamos ajudar.",
        },
      },
      hello: {
        title: "Olá! Eu sou Vicky",
        messages: {
          msg1: {
            0: "Vou acompanhá-lo neste tutorial de ",
            1: "5 minutos ",
            2: "para compreender o StoryDots. ",
            3: "Mantenha o seu telefone à mão.",
          },
        },
      },
      howItWorks: {
        title: "Como funciona",
        messages: {
          msg1: "É muito importante conhecer a experiência que um cliente tem ao utilizar nossa aplicação.",
        },
      },
      QRScan: {
        title: "Escaneie o QR code com seu telefone",
        messages: {
          msg1: {
            0: "Vamos criar o seu primeiro cumprimento virtual. A continuação, e como exemplo, você será quem  ",
            1: "faz o presente ",
            2: "e também quem ",
            3: "recebe",
          },
          msg2: "É isso aí! Agora vamos ver como é a integração em uma loja.",
        },
      },
      storeIntegration: {
        title: "Integração",
        messages: {
          msg1: "Estamos integrados ao carrinho de compras das lojas, permitindo que o comprador indique lá quando é um presente.",
        },
      },
      simulatePurchase: {
        title: "Vamos simular uma compra de presente!",
        messages: {
          msg1: "Você escolheu seu produto, vamos ver o que acontece quando você indica com StoryDots que se trata de uma compra para presente.",
        },
      },
      greetingIsAddedToCart: {
        title: "O cumprimento é adicionado ao seu carrinho",
        messages: {
          msg1: 'Pode vê-lo como "cumprimento virtual".',
          msg2: "Também se pode oferecer de maneira gratuita ou colocar um valor.",
        },
      },
      quickGreetingCreation: {
        title: "Criação rápida",
        messages: {
          msg1: "Quem faz o presente pode completar aquí um cumprimento de texto que depois pode ser melhorado.",
        },
      },
      orderEndedSuccessfully: {
        title: "O pedido é pago com sucesso",
        messages: {
          msg1: "Vamos ver o e-mail que recebe para criar o cumprimento virtual.",
        },
      },
      emailSent: {
        title: "Enviamos um e-mail para o cliente",
        messages: {
          msg1: "Ao iniciar sessão poderá criar um cumprimento virtual em vídeo, fotografia, voz ou texto como a que já fez.",
          msg2: "Você ainda está aí? Só falta um passo!",
        },
      },
      SDArrivesAsQR: {
        title: "StoryDots chega em um QR",
        messages: {
          msg1: "A loja imprime etiquetas QR para pedidos que foram identificados como um presente.",
        },
      },
      printing: {
        title: "Imprimir",
        messages: {
          msg1: {
            0: "Os pedidos de presentes são identificados, como neste exemplo, com uma nota de acompanhamento.",
          },
        },
      },
      printingEnd: {
        title: "Esta etiqueta QR contém o cumprimento",
        messages: {
          msg1: "Ele deve ser fixado em uma área visível do presente para surpreender ao destinatário.",
        },
      },
    },
    congratulations: {
      title: "Terminamos!",
      subtitle: "Nossas lojas ganham esses retornos. Você vai se juntar a nós?",
      cardTitle: {
        start: "Simulação de ",
        color: "desempenho extra.",
      },
      card1: "Número de vendas",
      card2: "Extra para presentes",
      card3: "Vendas extra com StoryDots",
      button: "Final",
    },
    stepper: {
      0: {
        title: "Introdução",
        subtitle: "Como funciona",
        tooltip: "Ir à introdução",
      },
      1: {
        title: "Integração",
        subtitle: "Ao carrinho de compras",
        tooltip: "Ir à integração",
      },
      2: {
        title: "Imprimir",
        subtitle: "Das etiquetas QR",
        tooltip: "Ir à imprimir",
      },
    },
    skip: {
      tooltip: "Sair do tutorial",
      question: "Quer sair do tutorial?",
      cancel: "Cancelar",
      confirm: "Confirmar",
    },
  },
};

export default TRANSLATION_PT;

import React from "react";
import {
  Step,
  StepLabel,
  Stepper,
  StepProps,
  Typography,
} from "@material-ui/core";
import { useStyles } from "./styles";

interface IProps {
  activeStep: number;
  labels: string[];
}

interface DotStepProps extends StepProps {
  label: string;
  bold?: boolean;
}

const DotStep: React.FC<DotStepProps> = ({ label, bold, ...props }) => {
  const classes = useStyles();
  return (
    <Step {...props} className={classes.step}>
      <StepLabel>
        <Typography
          variant="body1"
          className={bold ? classes.boldStep : undefined}
        >
          {label}
        </Typography>
      </StepLabel>
    </Step>
  );
};

const DotsStepper: React.FC<IProps> = ({ activeStep, labels }) => {
  const classes = useStyles();
  return (
    <Stepper
      activeStep={activeStep - 1}
      className={classes.root}
      alternativeLabel
    >
      {labels.map((label, index) => {
        if (index <= activeStep - 1) {
          return <DotStep key={label} label={label} bold />;
        } else {
          return <DotStep key={label} label={label} />;
        }
      })}
    </Stepper>
  );
};

export default DotsStepper;

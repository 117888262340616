import { makeStyles } from "@material-ui/core";
import { constants } from "config/theme";

const useStyles = makeStyles((theme) => ({
  bottomContainer: {
    boxShadow:
      "0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12)",
    borderRadius: constants.BORDER_RADIOUS_BIG,
    height: "195px",
    maxWidth: "1015px",
    [theme.breakpoints.down("sm")]: {
      height: "unset",
    },
  },
  rightCard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    padding: "19px 38px",
    textAlign: "left",
    width: "472px",
    [theme.breakpoints.down("sm")]: {
      padding: "15px",
    },
  },
  iconsCard: {
    position: "relative",
    backgroundColor: "rgba(116, 16, 224, 0.08);",
    borderRadius: "24px 0 0 24px",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
    maxHeight: "100%",
  },
  icons: {
    position: "absolute",
    width: "100%",
    maxWidth: "420px",
    left: "8%",
  },
  responsiveBox: {
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  },
  button: {
    paddingTop: "1.2rem",
    [theme.breakpoints.down("md")]: {
      paddingTop: "0.5rem",
    },
  },
}));

export { useStyles };

import { makeStyles } from "@material-ui/core";
import { constants } from "config/theme";

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: "rgb(255 255 255 / 80%)",
    padding: 20,
    borderRadius: constants.BORDER_RADIOUS_SMALL,
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  submit: {
    margin: theme.spacing(4, 0, 2),
  },
}));

export { useStyles };

import React, { useState } from "react";
import { Button as RootButton, ButtonProps } from "@material-ui/core";
import classnames from "classnames";
import { useStyles } from "./styles";

export interface IButtonProps extends ButtonProps {
  /**
   * Add shadowed blinking effect
   */
  blink?: boolean;
  /**
   * Add box shadow
   */
  shadow?: boolean;
}

/**
 * Primary UI Button component
 */

const Button: React.FC<IButtonProps> = ({
  className,
  blink,
  shadow,
  color = "primary",
  variant = "contained",
  ...props
}: any) => {
  const classes = useStyles({ color, ...props })();
  const [pressed, setPressed] = useState<boolean>(false);
  const shadowStyles = shadow ? classes.shadowed : "";
  const blinkingStyles =
    !props.disabled && blink && !pressed && classes.blinkingButton;
  const handleClick = () => {
    setPressed(true);
    if (props.onClick) props.onClick();
  };
  return (
    <RootButton
      color={color}
      variant={variant}
      size="large"
      onClick={handleClick}
      className={classnames(
        classes.root,
        blinkingStyles,
        shadowStyles,
        className,
      )}
      {...props}
    />
  );
};

export default Button;

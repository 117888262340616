import { useContext } from "react";
import { OnboardingContext } from "../../../context/OnboardingContext";
import TimerIcon from "@material-ui/icons/Timer";

interface Props {
  wizardContentTime?: string;
}

function WizardContentTimePill({ wizardContentTime }: Props) {
  const { styles } = useContext(OnboardingContext);
  const onboardingStyles = styles;
  const { roboto, pAbsolute, primaryBG, dFlex, alignCenter, textWhite } =
    onboardingStyles();

  if (!wizardContentTime) return <></>;

  return (
    <div
      className={[
        roboto,
        pAbsolute,
        dFlex,
        alignCenter,
        primaryBG,
        textWhite,
      ].join(" ")}
      style={{
        width: "6.51vw",
        height: "1.71875vw",
        fontSize: "0.78125vw",
        top: "0.78125vw",
        left: "0.78125vw",
        borderRadius: "2.8646vw",
      }}
    >
      <TimerIcon style={{ margin: "0 0.25vw 0 auto", fontSize: "1.041vw" }} />
      <p style={{ margin: "0 auto 0 0.3125vw" }}>{wizardContentTime}</p>
    </div>
  );
}

export default WizardContentTimePill;

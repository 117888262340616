import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { OnboardingContext } from "../../../context/OnboardingContext";
import Button from "@material-ui/core/Button";
import { CongratulationsCards } from "../../Molecules";

function CongratulationsInnerContainer() {
  const { t } = useTranslation("onboarding");
  const { styles, palette } = useContext(OnboardingContext);
  const onboardingStyles = styles;
  const {
    greenGradientBG,
    whiteBG,
    pRelative,
    m0,
    m4,
    paddingLg,
    mrAuto,
    mbAuto,
    mlAuto,
    dFlex,
    column,
    justifyCenter,
    radiusSm,
    radiusMd,
    textWhite,
    hiddenOverflow,
    noBorder,
    uppercase,
    paperShadow,
    pointer,
  } = onboardingStyles();
  const { primary } = palette;

  const handleFinish = () =>
    (window.location.href = "https://storydots.app/#MeetingsCalendar");

  return (
    <div
      className={[
        pRelative,
        whiteBG,
        paddingLg,
        dFlex,
        column,
        justifyCenter,
        radiusMd,
        hiddenOverflow,
        paperShadow,
      ].join(" ")}
      style={{ width: "53.85417vw", height: "25.26vw" }}
    >
      <h2
        className={[m0].join(" ")}
        style={{
          marginBottom: "1.67vw",
          color: "black",
          fontSize: "1.4583vw",
          fontWeight: 600,
        }}
      >
        {t("congratulations.cardTitle.start")}
        <span style={{ color: `${primary.main}` }}>
          {t("congratulations.cardTitle.color")}
        </span>
      </h2>
      <CongratulationsCards />
      <div
        className={[greenGradientBG, m4, mrAuto, mbAuto, mlAuto, radiusSm].join(
          " ",
        )}
        style={{ boxShadow: `0px 0px 2.5vw -0.52vw ${primary.light}` }}
      >
        <Button
          onClick={handleFinish}
          className={[
            greenGradientBG,
            m0,
            radiusSm,
            noBorder,
            textWhite,
            uppercase,
            paperShadow,
            pointer,
          ].join(" ")}
          style={{
            width: "19.74vw",
            height: "3.125vw",
            fontSize: "1.15vw",
            fontWeight: 600,
            letterSpacing: "0.46px",
          }}
        >
          {t("congratulations.button")}
        </Button>
      </div>
    </div>
  );
}

export default CongratulationsInnerContainer;

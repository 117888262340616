import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  Typography,
} from "@material-ui/core";
import { useStyles } from "./styles";

export interface AccordionItem {
  /*
   * Accordion item summary
   */

  summary: string;
  /*
   * Accordion item summary
   */

  content: React.ReactNode;
}

export interface IAccordionProps {
  /*
   * Items to be displayed
   */

  items: AccordionItem[];
}

/*
 * This component is a generic Accordion that takes as input an array of items with their summary (title) and
 * the content to be displayed when selected.
 */
const GenericAccordion: React.FC<IAccordionProps> = ({ items, ...props }) => {
  //TODO refactor to process children instead of items
  const classes = useStyles();
  const [expanded, setExpanded] = useState<boolean | string>(false);
  const handleChange =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  return (
    <Card raised className={classes.accordion}>
      {items.map((item, idx) => {
        return (
          <Accordion
            expanded={expanded === `panel${idx}`}
            onChange={handleChange(`panel${idx}`)}
          >
            <AccordionSummary className={classes.accordionSummary}>
              <Typography variant="subtitle1">{item.summary}</Typography>
            </AccordionSummary>
            <AccordionDetails>{item.content}</AccordionDetails>
          </Accordion>
        );
      })}
    </Card>
  );
};

export default GenericAccordion;

import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  "@global": {
    "@-moz-keyframes ready_shine-spin": {
      "100%": {
        "-moz-transform": "rotate(360deg)",
      },
    },
    "@-webkit-keyframes ready_shine-spin": {
      "100%": {
        "-webkit-transform": "rotate(360deg)",
      },
    },
    "@keyframes ready_shine-spin": {
      "100%": {
        "-webkit-transform": "rotate(360deg)",
        transform: "rotate(360deg)",
      },
    },
    "@-moz-keyframes ready_bag-bounce": {
      "0%, 20%, 53%, to": {
        "-moz-animation-timing-function": "cubic-bezier(0.215, 0.61, 0.355, 1)",
        "-moz-transform": "none",
      },
      "40%, 43%": {
        "-moz-animation-timing-function":
          "cubic-bezier(0.755, 0.05, 0.855, 0.06)",
        "-moz-transform": "translate3d(0, -30px, 0) scaleY(1.1)",
      },
      "70%": {
        "-moz-animation-timing-function":
          "cubic-bezier(0.755, 0.05, 0.855, 0.06)",
        "-moz-transform": "translate3d(0, -15px, 0) scaleY(1.05)",
      },
      "80%": {
        "-moz-transition-timing-function":
          "cubic-bezier(0.215, 0.61, 0.355, 1)",
        "-moz-transform": "translateZ(0) scaleY(0.95)",
      },
      "90%": {
        "-moz-transform": "translate3d(0, -4px, 0) scaleY(1.02)",
      },
    },

    "@-webkit-keyframes ready_bag-bounce": {
      "0%, 20%, 53%, to": {
        "-webkit-animation-timing-function":
          "cubic-bezier(0.215, 0.61, 0.355, 1)",
        "animation-timing-function": "cubic-bezier(0.215, 0.61, 0.355, 1)",
        "-webkit-transform": "none",
        transform: "none",
      },
      "40%, 43%": {
        "-webkit-animation-timing-function":
          "cubic-bezier(0.755, 0.05, 0.855, 0.06)",
        "animation-timing-function": "cubic-bezier(0.755, 0.05, 0.855, 0.06)",
        "-webkit-transform": "translate3d(0, -30px, 0) scaleY(1.1)",
        transform: "translate3d(0, -30px, 0) scaleY(1.1)",
      },
      "70%": {
        "-webkit-animation-timing-function":
          "cubic-bezier(0.755, 0.05, 0.855, 0.06)",
        "animation-timing-function": "cubic-bezier(0.755, 0.05, 0.855, 0.06)",
        "-webkit-transform": "translate3d(0, -15px, 0) scaleY(1.05)",
        transform: "translate3d(0, -15px, 0) scaleY(1.05)",
      },
      "80%": {
        "-webkit-transition-timing-function":
          "cubic-bezier(0.215, 0.61, 0.355, 1)",
        "transition-timing-function": "cubic-bezier(0.215, 0.61, 0.355, 1)",
        "-webkit-transform": "translateZ(0) scaleY(0.95)",
        transform: "translateZ(0) scaleY(0.95)",
      },
      "90%": {
        "-webkit-transform": "translate3d(0, -4px, 0) scaleY(1.02)",
        transform: "translate3d(0, -4px, 0) scaleY(1.02)",
      },
    },

    "@keyframes ready_bag-bounce": {
      "0%, 20%, 53%, to": {
        "animation-timing-function": "cubic-bezier(0.215, 0.61, 0.355, 1)",
        transform: "none",
      },
      "40%, 43%": {
        "animation-timing-function": "cubic-bezier(0.755, 0.05, 0.855, 0.06)",
        transform: "translate3d(0, -30px, 0) scaleY(1.1)",
      },
      "70%": {
        "animation-timing-function": "cubic-bezier(0.755, 0.05, 0.855, 0.06)",
        transform: "translate3d(0, -15px, 0) scaleY(1.05)",
      },
      "80%": {
        "transition-timing-function": "cubic-bezier(0.215, 0.61, 0.355, 1)",
        transform: "translateZ(0) scaleY(0.95)",
      },
      "90%": {
        transform: "translate3d(0, -4px, 0) scaleY(1.02)",
      },
    },
    "@-moz-keyframes ready_tag-hang": {
      "0%, 20%, 53%, to": {
        "-moz-animation-timing-function": "cubic-bezier(0.215, 0.61, 0.355, 1)",
        "-moz-transform": "rotate(0)",
      },
      "40%": {
        "-moz-animation-timing-function":
          "cubic-bezier(0.755, 0.05, 0.855, 0.06)",
        "-moz-transform": "rotate(-15deg)",
      },
      "70%": {
        "-moz-animation-timing-function":
          "cubic-bezier(0.755, 0.05, 0.855, 0.06)",
        "-moz-transform": "rotate(7deg)",
      },
      "80%": {
        "-moz-transition-timing-function":
          "cubic-bezier(0.215, 0.61, 0.355, 1)",
        "-moz-transform": "rotate(0)",
      },
      "90%": {
        "-moz-transform": "rotate(-4deg)",
      },
    },

    "@-webkit-keyframes ready_tag-hang": {
      "0%, 20%, 53%, to": {
        "-webkit-animation-timing-function":
          "cubic-bezier(0.215, 0.61, 0.355, 1)",
        "animation-timing-function": "cubic-bezier(0.215, 0.61, 0.355, 1)",
        "-webkit-transform": "rotate(0)",
        transform: "rotate(0)",
      },
      "40%": {
        "-webkit-animation-timing-function":
          "cubic-bezier(0.755, 0.05, 0.855, 0.06)",
        "animation-timing-function": "cubic-bezier(0.755, 0.05, 0.855, 0.06)",
        "-webkit-transform": "rotate(-15deg)",
        transform: "rotate(-15deg)",
      },
      "70%": {
        "-webkit-animation-timing-function":
          "cubic-bezier(0.755, 0.05, 0.855, 0.06)",
        "animation-timing-function": "cubic-bezier(0.755, 0.05, 0.855, 0.06)",
        "-webkit-transform": "rotate(7deg)",
        transform: "rotate(7deg)",
      },
      "80%": {
        "-webkit-transition-timing-function":
          "cubic-bezier(0.215, 0.61, 0.355, 1)",
        "transition-timing-function": "cubic-bezier(0.215, 0.61, 0.355, 1)",
        "-webkit-transform": "rotate(0)",
        transform: "rotate(0)",
      },
      "90%": {
        "-webkit-transform": "rotate(-4deg)",
        transform: "rotate(-4deg)",
      },
    },

    "@keyframes ready_tag-hang": {
      "0%, 20%, 53%, to": {
        "animation-timing-function": "cubic-bezier(0.215, 0.61, 0.355, 1)",
        transform: "rotate(0)",
      },
      "40%": {
        "animation-timing-function": "cubic-bezier(0.755, 0.05, 0.855, 0.06)",
        transform: "rotate(-15deg)",
      },
      "70%": {
        "animation-timing-function": "cubic-bezier(0.755, 0.05, 0.855, 0.06)",
        transform: "rotate(7deg)",
      },
      "80%": {
        "transition-timing-function": "cubic-bezier(0.215, 0.61, 0.355, 1)",
        transform: "rotate(0)",
      },
      "90%": {
        transform: "rotate(-4deg)",
      },
    },
    "@-moz-keyframes ready_roll-skew": {
      "0%, 20%, 53%, to": {
        "-moz-animation-timing-function": "cubic-bezier(0.215, 0.61, 0.355, 1)",
        "-moz-transform": "skew(0)",
      },
      "40%": {
        "-moz-animation-timing-function":
          "cubic-bezier(0.755, 0.05, 0.855, 0.06)",
        "-moz-transform": "skew(15deg)",
      },
      "70%": {
        "-moz-animation-timing-function":
          "cubic-bezier(0.755, 0.05, 0.855, 0.06)",
        "-moz-transform": "skew(-7deg)",
      },
      "80%": {
        "-moz-transition-timing-function":
          "cubic-bezier(0.215, 0.61, 0.355, 1)",
        "-moz-transform": "skew(0)",
      },
      "90%": {
        "-moz-transform": "skew(4deg)",
      },
    },

    "@-webkit-keyframes ready_roll-skew": {
      "0%, 20%, 53%, to": {
        "-webkit-animation-timing-function":
          "cubic-bezier(0.215, 0.61, 0.355, 1)",
        "animation-timing-function": "cubic-bezier(0.215, 0.61, 0.355, 1)",
        "-webkit-transform": "skew(0)",
        transform: "skew(0)",
      },
      "40%": {
        "-webkit-animation-timing-function":
          "cubic-bezier(0.755, 0.05, 0.855, 0.06)",
        "animation-timing-function": "cubic-bezier(0.755, 0.05, 0.855, 0.06)",
        "-webkit-transform": "skew(15deg)",
        transform: "skew(15deg)",
      },
      "70%": {
        "-webkit-animation-timing-function":
          "cubic-bezier(0.755, 0.05, 0.855, 0.06)",
        "animation-timing-function": "cubic-bezier(0.755, 0.05, 0.855, 0.06)",
        "-webkit-transform": "skew(-7deg)",
        transform: "skew(-7deg)",
      },
      "80%": {
        "-webkit-transition-timing-function":
          "cubic-bezier(0.215, 0.61, 0.355, 1)",
        "transition-timing-function": "cubic-bezier(0.215, 0.61, 0.355, 1)",
        "-webkit-transform": "skew(0)",
        transform: "skew(0)",
      },
      "90%": {
        "-webkit-transform": "skew(4deg)",
        transform: "skew(4deg)",
      },
    },

    "@keyframes ready_roll-skew": {
      "0%, 20%, 53%, to": {
        "animation-timing-function": "cubic-bezier(0.215, 0.61, 0.355, 1)",
        transform: "skew(0)",
      },
      "40%": {
        "animation-timing-function": "cubic-bezier(0.755, 0.05, 0.855, 0.06)",
        transform: "skew(15deg)",
      },
      "70%": {
        "animation-timing-function": "cubic-bezier(0.755, 0.05, 0.855, 0.06)",
        transform: "skew(-7deg)",
      },
      "80%": {
        "transition-timing-function": "cubic-bezier(0.215, 0.61, 0.355, 1)",
        transform: "skew(0)",
      },
      "90%": {
        transform: "skew(4deg)",
      },
    },
  },
  root: {
    maxWidth: "100%",
    margin: "-32px auto",
    transform: "scale(0.85)",

    "& g, path": {
      transformBox: "fill-box",
      transformOrigin: "center",
    },

    "& .illustration_main": {
      fill: theme.palette.illustration.main,
    },
    "& .illustration_light": {
      fill: theme.palette.illustration.light,
    },
    "& .illustration_shade": {
      fill: theme.palette.illustration.shade,
    },
    "& .illustration_shade-dark": {
      fill: theme.palette.illustration.shade_dark,
    },

    "& .illustration_clouds": {
      fill: theme.palette.illustration.clouds,
      transformBox: "unset",
    },

    "& .illustration_paper": {
      fill: "#fff",
    },

    "& .ready_shine": {
      fill: "url(#ready_shine-gradient)",
      "transform-origin": "50% 50%",
      "-webkit-animation": "$ready_shine-spin 10s linear infinite",
      "-moz-animation": "$ready_shine-spin 10s linear infinite",
      animation: "$ready_shine-spin 10s linear infinite",
    },

    "& .ready_bag": {
      "transform-origin": "50% 100%",
      "-webkit-animation": "$ready_bag-bounce 2s ease-in-out infinite",
      "-moz-animation": "$ready_bag-bounce 2s ease-in-out infinite",
      animation: "$ready_bag-bounce 2s ease-in-out infinite",
    },

    "& .ready_tag": {
      "transform-origin": "center top",
      "-webkit-animation": "$ready_tag-hang 2s ease-in-out infinite",
      "-moz-animation": "$ready_tag-hang 2s ease-in-out infinite",
      animation: "$ready_tag-hang 2s ease-in-out infinite",
    },

    "& .ready_roll": {
      "-webkit-transform-origin": "0 32%",
      "-moz-transform-origin": "0 32%",
      "transform-origin": "0 32%",
      "-webkit-transform": "scale(0.1, 0.8)",
      "-moz-transform": "scale(0.1, 0.8)",
      transform: "scale(0.1, 0.8)",
      "-webkit-animation": "$ready_roll-skew 2s ease-in-out infinite",
      "-moz-animation": "$ready_roll-skew 2s ease-in-out infinite",
      animation: "$ready_roll-skew 2s ease-in-out infinite",
    },
  },
}));

import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { SHORT_TOUR } from "config/consts";
import Particles from "react-tsparticles";
import particlesConfig from "config/particles";

import {
  TourCart,
  TourReceiver,
  TourSwitchToReceiver,
  TourThankYou,
} from "components";

const ShortTour: React.FC = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const configJson = queryParams.get("config");
  const config = JSON.parse(configJson || "{}");

  return (
    <BrowserRouter>
      <div className="Tour-container">
        <Switch>
          <Route exact path="/tour">
            <Redirect to={`${SHORT_TOUR.CART}?${queryParams}`} />
          </Route>
          <Route path={SHORT_TOUR.CART} component={TourCart} />
          <Route path={SHORT_TOUR.THANK_YOU_PAGE} component={TourThankYou} />
          <Route
            path={SHORT_TOUR.SWITCH_TO_RECEIVER}
            component={TourSwitchToReceiver}
          />
          <Route path={SHORT_TOUR.RECEIVER}>
            <TourReceiver config={config} />
          </Route>
          <Route path="*">
            <div>Not Found</div>
          </Route>
        </Switch>
        {/* @ts-ignore */}
        <Particles id="cont-canvas" params={particlesConfig} />
      </div>
    </BrowserRouter>
  );
};

export default ShortTour;

import React from "react";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import useStyles from "./styles";
import classnames from "classnames";
import BuyerActive from "assets/actor-stepper/buyer-active.svg";
import ReceiverActive from "assets/actor-stepper/receiver-active.svg";
import StoreActive from "assets/actor-stepper/store-active.svg";
import ReceiverInactive from "assets/actor-stepper/receiver-inactive.svg";
import StoreInactive from "assets/actor-stepper/store-inactive.svg";
import BuyerCompleted from "assets/actor-stepper/buyer-completed.svg";
import ReceiverCompleted from "assets/actor-stepper/receiver-completed.svg";
import StoreCompleted from "assets/actor-stepper/store-completed.svg";

export interface IActorStep {
  actor: "buyer" | "receiver" | "store";
  state: "inactive" | "active" | "completed";
}

const Step: React.FC<IActorStep> = ({ actor, state }) => {
  const classes = useStyles();
  const { t } = useTranslation("ActorStepper");
  const labels = {
    buyer: t("Buyer"),
    receiver: t("Receiver"),
    store: t("Store"),
  };
  const images = {
    buyer: {
      inactive: BuyerActive,
      active: BuyerActive,
      completed: BuyerCompleted,
    },
    receiver: {
      inactive: ReceiverInactive,
      active: ReceiverActive,
      completed: ReceiverCompleted,
    },
    store: {
      inactive: StoreInactive,
      active: StoreActive,
      completed: StoreCompleted,
    },
  };
  return (
    <div
      className={classnames(classes.container, {
        [classes.completed]: state === "completed",
      })}
    >
      <img src={images[actor][state]} alt="step-icon" />
      <Typography color="primary" align="center" className={classes.label}>
        {state === "active" ? labels[actor] : ""}
      </Typography>
    </div>
  );
};

export default Step;

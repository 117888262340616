import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  bagIcon: {
    height: "28vh",
  },
  greetingAddedText: {
    fontSize: "1.25rem",
    color: "#25C796",
    paddingBottom: theme.spacing(2),
  },
  checkIcon: {
    marginBottom: "-0.70rem",
  },
}));

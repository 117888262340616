import { Box, Grid, Typography } from "@material-ui/core";
import { PageContainer, QrCard } from "components";
import { useStyles } from "./styles";
import { Trans, useTranslation } from "react-i18next";
import { useQueryParams } from "utils/hooks";

const Receiver = ({ config }: any) => {
  const { t, i18n } = useTranslation("ReceiverView");
  const tagLanguage = i18n.language.split("-")[0];
  const classes = useStyles();
  const queryParams = useQueryParams();

  const code = queryParams.get("code");
  return (
    <PageContainer>
      <Grid item xs={7} className={classes.container}>
        <Typography
          variant="h4"
          color="primary"
          align="center"
          className={classes.title}
        >
          {t("short.title")}
        </Typography>
        <Box mx="auto">
          <QrCard
            language={tagLanguage}
            code={code}
            config={config}
            size={400}
          />
        </Box>
        <Typography variant="body1" align="center" className={classes.caption}>
          <Trans
            t={t}
            i18nKey="short.caption"
            components={{ strong: <strong className={classes.primaryText} /> }}
          />
          <a
            style={{ textDecoration: "none" }}
            target="_blank"
            href="https://storydots.app/demobooking"
          >
            <Trans
              t={t}
              i18nKey="short.link"
              components={{
                strong: <strong className={classes.primaryText} />,
              }}
            />
          </a>
        </Typography>
      </Grid>
    </PageContainer>
  );
};

export default Receiver;

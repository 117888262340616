import axios from "axios";
import environment from "config/environment";

interface IBrandData {
  logo_url?: string;
}

const getBrandData = async (apiKey: string): Promise<any> => {
  let brandData: IBrandData = {};
  try {
    const { data } = await axios.get<IBrandData>(
      `${environment.REACT_APP_API_URL}/getBrand`,
      {
        headers: { "x-api-key": apiKey },
      },
    );
    brandData = { ...data };
  } catch (e) {}
  return brandData;
};

export default getBrandData;

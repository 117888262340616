import { useContext } from "react";
import { OnboardingContext } from "../../../context/OnboardingContext";

interface Props {
  wizardImage?: string;
}

function WizardImage({ wizardImage }: Props) {
  const { styles } = useContext(OnboardingContext);
  const onboardingStyles = styles;
  const {
    whiteBG,
    pAbsolute,
    p3,
    dFlex,
    alignCenter,
    justifyCenter,
    radius50,
    paperShadow,
  } = onboardingStyles();

  if (!wizardImage) return <></>;

  return (
    <div
      className={[
        pAbsolute,
        whiteBG,
        p3,
        dFlex,
        alignCenter,
        justifyCenter,
        radius50,
        paperShadow,
      ].join(" ")}
      style={{
        width: "2.604vw",
        height: "2.604vw",
        top: "-0.4167vw",
        left: "calc(50% + 1.875vw)",
      }}
    >
      <img
        src={wizardImage}
        alt="wizard action"
        style={{ width: "1.5625vw" }}
      />
    </div>
  );
}

export default WizardImage;

import React from "react";

export interface ISeparatorProps {
  //In pixels
  offsetY?: number;
  spacing?: number;
  minWidth?: number;
}
const Separator: React.FC<ISeparatorProps> = ({
  minWidth = "23px",
  offsetY = 0,
  spacing = 6,
}) => {
  return (
    <div
      style={{ height: "100%", minWidth: minWidth, margin: `0 ${spacing}px` }}
    >
      <div
        style={{
          flexGrow: 1,
          height: `calc(50% + ${offsetY}px)`,
          borderBottom: "1px solid #BDBDBD",
        }}
      ></div>
    </div>
  );
};

export default Separator;

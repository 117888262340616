import React from "react";
import { OnboardingContextProvider } from "../../components/Templates/Onboarding/context/OnboardingContext";
import Onboarding from "../../components/Templates/Onboarding";

function OnboardingList() {
  return (
    <OnboardingContextProvider>
      <Onboarding />
    </OnboardingContextProvider>
  );
}

export default OnboardingList;

import React, { ReactNode } from "react";
import { Typography } from "@material-ui/core";
import { useStyles } from "./styles";

export interface IActorCard {
  iconSrc: string;
  title: ReactNode;
  description: ReactNode;
}

const ActorCard: React.FC<IActorCard> = ({
  iconSrc,
  title,
  description,
  ...props
}) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <img src={iconSrc} alt="icon" className={classes.img} />
      <Typography variant="h5" color="primary" gutterBottom>
        <strong>{title}</strong>
      </Typography>
      <Typography variant="subtitle1">{description}</Typography>
    </div>
  );
};

export default ActorCard;

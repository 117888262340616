import thumbsUp from "./thumbs-up.png";
import cart from "./cart-color-icon.png";
import present from "./present-color-icon.png";
import gmail from "./gmail-color-icon.png";
import printer from "./printer-icon.png";
import success from "./success.png";

const wizardIcons = {
  thumbsUp,
  cart,
  present,
  gmail,
  printer,
  success,
};

export default wizardIcons;

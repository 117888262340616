import { makeStyles } from "@material-ui/core";
import { constants } from "config/theme";

export const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: constants.BORDER_RADIOUS_SMALL,
    padding: theme.spacing(3, 3),
    minWidth: "570px",
  },
  row: {
    padding: "12px 12px",
  },
  alignedImage: {
    // float: "right",
    marginRight: "3vw",
  },
  qrImage: {
    marginLeft: "auto",
  },
}));

import { useContext } from "react";
import { useTransition, animated } from "react-spring";
import { OnboardingContext } from "./context/OnboardingContext";
import { useGetOnboardingSlides } from "./helpers";
import {
  OnboardingStepper,
  SkipOnboardingButton,
  SkipOnboardingModal,
} from "./components/Molecules";

function Onboarding() {
  const { slideN, styles } = useContext(OnboardingContext);
  const onboardingStyles = styles;
  const {
    pAbsolute,
    pRelative,
    w100,
    h100,
    dFlex,
    alignCenter,
    justifyCenter,
    mtAuto,
    mbAuto,
  } = onboardingStyles();
  const [slideToShow] = slideN;
  const onboardingSlides = useGetOnboardingSlides();

  const transitions = useTransition(slideToShow, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 600 },
  });

  return (
    <div
      className={["hide-scrollbar", pAbsolute, dFlex].join(" ")}
      style={{
        width: "100vw",
        height: "100vh",
        right: "0",
        bottom: "0",
        left: "0",
        top: "0",
      }}
    >
      <SkipOnboardingModal />
      <div
        className={[
          pRelative,
          w100,
          dFlex,
          alignCenter,
          justifyCenter,
          mtAuto,
          mbAuto,
        ].join(" ")}
        style={{ minHeight: "49.48vw" }}
      >
        {transitions((springProps, item) => {
          return (
            <animated.div
              className={[
                pAbsolute,
                w100,
                h100,
                dFlex,
                alignCenter,
                justifyCenter,
              ].join(" ")}
              style={springProps}
            >
              {onboardingSlides[item]}
              {
                ![0, 13].includes(slideToShow) && (
                  <OnboardingStepper />
                ) /* FIXED SLIDE NUMBER */
              }
              <SkipOnboardingButton />
            </animated.div>
          );
        })}
      </div>
    </div>
  );
}

export default Onboarding;

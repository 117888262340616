import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "white",
    height: "8rem",
    marginBottom: "1rem",
  },
  sdLogo: {
    maxHeight: "3rem",
    [theme.breakpoints.down("xs")]: {
      height: "8vmin",
    },
    minHeight: "1.6rem",
    "&:hover": {
      cursor: "pointer",
    },
  },
  whiteLabelLogo: {
    maxHeight: "3rem",
    [theme.breakpoints.down("xs")]: {
      height: "3.15vmin",
    },
    minHeight: "0.6rem",
    maxWidth: "30vh",
    paddingLeft: 15,
  },
  menuButton: {
    width: 60,
    height: 60,
  },
  menuButtonIcon: {
    fontSize: "2rem",
  },
  stepper: {
    height: "100%",
    margin: "0 4vw",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
}));

export default useStyles;

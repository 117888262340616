const TRANSLATION_EN = {
  ActorStepper: {
    Buyer: "Buyer",
    Receiver: "Receiver",
    Store: "Store",
    Finished: "Demo completed!",
  },
  WelcomeView: {
    welcome: "Welcome",
    mainParagraph:
      "StoryDots integrates into online stores checkouts and allows brands to offer virtual greetings to their customers when purchasing gifts. We've created this small demo to show you how it works without asking you to make an actual purchase. Great, right!? We want to show you the StoryDots experience in this demo as close to the real thing as possible, so... ",
    gearUp: "Gear up!",
    secondaryParagraph:
      "<p>1) Make sure you open this demo on your <strong>Desktop computer</strong></p>" +
      "<p>2) <strong>Get your smartphone ready.</strong> If you don’t have a QR scanner app, you can open storydots.app/scan on your mobile browser.</p>",
    button: "Let’s get started!",
    disclaimer:
      "Any data collected within the demo experience will not be used for any purpouses outside of showcasing the product.",
  },
  ActorsView: {
    title: "Introduction",
    subtitle:
      "Think of this as an acting class. Let’s check the script and figure out who the characters are... <br> Well.. it actually looks like it’s just you today, so you are going to be playing all three roles, yay!",
    buyer: {
      title: "Buyer",
      description: "Has money. Wants to make a special gift.",
    },
    receiver: {
      title: "Receiver",
      description:
        "They’ve been a good human, so they are receiving a special present for their birthday.",
    },
    store: {
      title: "Store",
      description:
        "Has installed StoryDots, so there’s no cooler place to purchase a gift.",
    },
    button: "Go to Buyer Experience",
  },
  CartView: {
    title: "You are the Buyer",
    description:
      "<p>Your friend’s birthday is coming up.</p>" +
      "<p>What better for a coffee lover than a Nesspreso machine?</p>" +
      "<p>This online store offers you the option to include a surprise greeting for free...</p>" +
      "<p>Hmm, what will you do?</p>",
    CartCard: {
      title: "Your Cart",
      productTitle: "Expreso Coffee Machine",
      price: "US$ 189",
      summary: "Subtotal (no shipping): ",
    },
    CartSnippet: {
      text: "¿Are you buying a gift?",
      textAffirm: "¡You are buying a gift!",
      from: "From",
      to: "To",
      message: "Type here your message",
      email: "Email address",
      infoText: "You can complete your greeting after checkout",
      charsLeft: "characters left.",
      checkoutButton: "Checkout",
    },
    validations: {
      required: "Required",
      maxChars: "Must be at most 240 characters",
      email: "Invalid email",
    },
    short: {
      title: "This demo will allow you to discover Storydot's functionality",
      subtitle:
        "A client is buying a coffee machine as a gift and you're watching his cart powered by Storydots.",
      description: "Please select the gift option and continue the checkout.",
    },
  },
  GreetingAddedView: {
    greetingAdded: "Greeting successfully added!",
    info: "You'll be able to create it after checkout",
    checkoutButton: "Checkout",
  },
  CheckoutView: {
    title: "Let’s wrap this up!",
    description:
      "<p>As in any online store, when the buyer checks out, they are usually required to leave their name and email.</p><p>Use your real address here, as you are going to get an actual email after this.</p>",
    CheckoutForm: {
      name: "Name",
      email: "Email Address",
      apiKey: "API Key",
      button: "Buy Now",
    },
    Validations: {
      name: "Name is a required field",
      email: {
        required: "Email is a required field",
        format: "Email field must be a valid email",
      },
      apiKey: {
        required: "API Key is a required field",
        format: "Invalid API Key format",
      },
    },
  },
  ThankYouPageView: {
    title: "Gift Bought!",
    description:
      "After the buyer completes their payment, they’ll get an email from StoryDots inviting them to create their surprise greeting.",
    Card: {
      paragraph1:
        "It’s time to create your greeting, so grab your smartphone. You have two options:",
      paragraph2:
        "Create now with your smartphone camera by scanning this QR code. No scanner app? Open storydots.app/scan on your browser",
      paragraph3:
        "Check your email, you will find a link to create your greeting.",
      paragraph4:
        "Once you have created your greeting, continue to the next step.",
      or: "or",
      button: "Continue",
    },
  },
  SwitchToReceiverView: {
    title: "Meanwhile...",
    description:
      "<p>Did you think we were idle while you were recording your greeting?</p>" +
      "<p>As soon as the payment was completed, the store printed a StoryDots Tag and attached it to the gift you bought.</p>" +
      "<p>Your gift is almost there!</p>",
    button: "Continue to receiver experience",
    Stepper: {
      step1: "Payment",
      step2: "Shipping",
      step3: "Delivered",
    },
    short: {
      title: "The package has already been sent",
      description:
        "The store has received your order and prepared and amazing gifting experience!",
    },
  },
  SurpriseView: {
    title: "You are now the receiver, so... Surprise! ",
    description:
      "Someone knocks on your door, you get ready and go see who it is.<br/>" +
      "There is a package delivery for you. Seems to be a birthday gift, but what is this tag with a QR code?",
    button: "Go to Scan",
  },
  ReceiverView: {
    title: "Time to check out the surprise greeting",
    description:
      "<p>You get to play the part of the person who receives the gift, so grab your smartphone one last time and scan the QR code.</p>" +
      "<p>After sending your reply, come back to this screen and continue to the next step.</p>",
    button: "Continue",
    short: {
      title:
        "You've received your beautiful gift package and it has a QR tag, scan it and get surprised!",
      caption:
        "We hope you loved the experience - If you still have any questions,",
      link: " <strong>get in touch with us.</strong>",
    },
  },
  DashboardView: {
    title: "But wait, there’s more!",
    description:
      "<p>We are not only a greetings service. We also collect smart data for the stores to improve decision making and reduce ad costs.</p>" +
      "<p>We are providing you with credentials to log in to a real dashboard where the data from your greeting is shown.</p>" +
      "<p>See it by yourself clicking the blue button in the bottom right corner of the screen.</p>" +
      "<p>After that, you’ll be able to finish the demo.</p>",
    button: "Finish",
    goToDashboardButton: "Go to Dashboard",
    user: "Username",
    password: "Password",
  },
  NotBadView: {
    title: "Not bad, huh?",
    description:
      "We hope you enjoyed our demo and that you liked StoryDots. Because, what is there not to like, right?",
    button: "Last step",
  },
  FAQView: {
    title: "Your may wonder...",
    Accordion: {
      item1: {
        summary: "How do the stores receive and print StoryDots Tags?",
        description:
          "The stores access the admin panel where they can check if any purchase includes StoryDots and they will be able to print from there.",
      },
      item2: {
        summary: "What if a receiver scans a tag without a greeting?",
        description:
          "At StoryDots we don't leave unfinished experiences. If the person who bought the gift does not create their greeting, the recipient will see a default video like the one below.",
      },
      item3: {
        summary: "How does the white label work?",
        description:
          "Both the App and the QR tag are 100% customizables for brands.",
      },
    },
    FooterCard: {
      title: "Let's talk",
      paragraph:
        "Would you like to know about our smart packaging? Do you need to chat to clear doubts?",
      button: "Schedule a call",
    },
  },
  MobileInfoPage: {
    title: "Welcome!",
    description:
      "<p>We’re glad you’re here.</p>" +
      "<p><strong>This demo is optimized to run on desktop.</strong></p>" +
      "<p>Don’t worry, you’re going to need your smartphone</p>",
    secondaryButton: "Go to site",
    primaryButton: "Book a demo",
  },
  onboarding: {
    language: "EN",
    wizardContent: {
      nextBtn: "Next",
      beginBtn: "Start",
      loadingBtn: "Loading...",
      goBackBtn: "Back",
      welcome: {
        title: "Welcome!",
        messages: {
          msg1: "We want to know you better so we can help you.",
        },
      },
      hello: {
        title: "Hi! I'm Vicky",
        messages: {
          msg1: {
            0: "I'm going to walk you through this ",
            1: "5-minute tutorial ",
            2: "to understand StoryDots. ",
            3: "Keep your cell phone handy.",
          },
        },
      },
      howItWorks: {
        title: "How it works",
        messages: {
          msg1: "It's very important to know the experience that a customer has when using our application.",
        },
      },
      QRScan: {
        title: "Scan the QR with your cell phone",
        messages: {
          msg1: {
            0: "We are going to create your first virtual greeting where you are going to put yourself in the place of the ",
            1: "sender ",
            2: "as well as the ",
            3: "receiver",
          },
          msg2: "That's it! Let's see now how the integration to a store looks like.",
        },
      },
      storeIntegration: {
        title: "Integration",
        messages: {
          msg1: "We are integrated into the shopping cart of the stores, allowing the buyer to indicate there when it is a gift.",
        },
      },
      simulatePurchase: {
        title: "Let's simulate a gift purchase!",
        messages: {
          msg1: "You've chosen your product, let's see what happens when you indicate with StoryDots that it is a gift purchase.",
        },
      },
      greetingIsAddedToCart: {
        title: "The greeting is added to the cart",
        messages: {
          msg1: 'You will be able to see it as "Virtual Greeting".',
          msg2: "Additionally, to offer it for free or assign it a value.",
        },
      },
      quickGreetingCreation: {
        title: "Quick creation",
        messages: {
          msg1: "The sender can complete a quick text greeting here, that can be improved later.",
        },
      },
      orderEndedSuccessfully: {
        title: "The order is successfully paid",
        messages: {
          msg1: "Let's see the email sent to create the wirtual greeting.",
        },
      },
      emailSent: {
        title: "We send an email to the customer",
        messages: {
          msg1: "By logging in the sender will be able to create a virtual greeting in video, photo, voice or text like the one you have already made.",
          msg2: "Are you still there? There's only one step left!",
        },
      },
      SDArrivesAsQR: {
        title: "StoryDots arrives in a QR",
        messages: {
          msg1: "The store prints QR tags for orders that were identified as a gift.",
        },
      },
      printing: {
        title: "Printing",
        messages: {
          msg1: {
            0: "Gift orders are identified, as in this example, with a note attached.",
          },
        },
      },
      printingEnd: {
        title: "This QR tag contains the greeting",
        messages: {
          msg1: "It should be attached in a visible area of the gift to surprise the recipient.",
        },
      },
    },
    congratulations: {
      title: "We're finished!",
      subtitle: "Our stores earn these returns Will you join us?",
      cardTitle: {
        start: "Simulation of ",
        color: "extra performance.",
      },
      card1: "Number of sales",
      card2: "Extra from gifts",
      card3: "Extra sales with StoryDots",
      button: "End",
    },
    stepper: {
      0: {
        title: "Introduction",
        subtitle: "How it works",
        tooltip: "Go to introduction",
      },
      1: {
        title: "Integration",
        subtitle: "To shopping cart",
        tooltip: "Go to integration",
      },
      2: {
        title: "Printing",
        subtitle: "Of QR tags",
        tooltip: "Go to printing",
      },
    },
    skip: {
      tooltip: "Exit tutorial",
      question: "Do you want to leave the tutorial?",
      cancel: "Cancel",
      confirm: "Confirm",
    },
  },
};

export default TRANSLATION_EN;
